import { Card, Col, Form, Input, Row, Button, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";

const ConfirmationNumber = () => {
    const [loading, setLoading] = React.useState(false);
    const [form] = Form.useForm();

    const valdationFailed = () => {
        message.error("Please Check All Fields");
    };

    const onFormSubmit = (values) => {
        setLoading(true);
        let { bookingId, confirmationNumber } = values;

        const pattern = /^SYT-H\d+$/;
        if (!pattern.test(bookingId)) {
            message.error("Invalid Booking Reference Number");
            setLoading(false)
            return;
        } else {
            ApiClient.post('admin/update/confirmationNumber', {bookingId, confirmationNumber}).then((res) => {
                if(res.status == 200) {
                    message.success("Confirmation Number Updated Successfully");
                    form.resetFields();
                }
            }).catch(() => {
                message.error("Failed to update Confirmation Number");
            })
        }

        setLoading(false)
    };

    return (
        <div className="manage-markup-section">
            <Content className="container-fluid">
                <Row>
                    <Col span={24}>
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={onFormSubmit}
                            onFinishFailed={valdationFailed}
                        >
                            <div className="card-gap">
                                <div className="flight-depart-details">
                                    {/* <h4>Ticket Details</h4> */}
                                    <Card title="CONFIRMATION NUMBER" bordered={false}>
                                        <div className="">
                                            <Row gutter="16">
                                                <Col className="gutter-class" md={6} xs={24}>
                                                    <Form.Item label="BOOKING REFRENCE NUMBER" name="bookingId"
                                                        rules={[{ required: true }]}>
                                                        <Input placeholder="BOOKING REFRENCE NUMBER" />
                                                    </Form.Item>
                                                </Col>

                                                <Col className="gutter-class" md={6} xs={24}>
                                                    <Form.Item label="CONFIRMATION NUMBER" name="confirmationNumber" rules={[{ required: true }]}>
                                                        <Input placeholder="CONFIRMATION NUMBER" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                    <Card>
                                        <Row gutter={16}>
                                            <Col className="gutter-class" md={24} xs={24}>
                                                <Form.Item className="bookoffline-submit">
                                                    <Button loading={loading} htmlType="submit">Submit</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Content>
        </div>
    )
}

export default ConfirmationNumber;