import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Button, Col, Modal, Row, Rate, Space, Skeleton } from "antd";
import { useCurrencyContext } from "../../../../common/providers/CurrencyProvider";

import HotelRoomtable from "../../HotelDet/HotelRoomtable";
import CustomImg from "../../../../common/CustomImg";
import ImagesLightbox from "../../../../components/ImagesLightbox/ImagesLightbox";
import poolsvg from "../../../../assets/images/hotels/amenities/pool.svg";
import parkingsvg from "../../../../assets/images/hotels/amenities/parking.svg";
import restaurantsvg from "../../../../assets/images/hotels/amenities/restaurant.svg";
import gymsvg from "../../../../assets/images/hotels/amenities/gym.svg";
import queryString from "query-string";
import { EnvironmentOutlined, CheckOutlined } from "@ant-design/icons";
import nohotelimage from "../../../../assets/images/hotels/no_img.png";
import "../HotelsList/HotelsList.scss";
const HotelsList = ({
  keyId,
  availableHotel,
  hotelTraceId,
  isFromPackage,
  activeTab,
  setActiveTab,
  isHotelSearchLoad,
  showNetFare,
}) => {
  const hotelCardProps = availableHotel;
  const { activeCurrency, currencyValue } = useCurrencyContext();

  const [imagesModal, setImagesModal] = useState({
    visible: false,
    data: null,
  });
  const [isAmenitiesModal, setIsAmenitiesModal] = useState(false);

  const showAmenitiesModal = () => {
    setIsAmenitiesModal(true);
  };

  const handleOk = () => {
    setIsAmenitiesModal(false);
  };

  const handleCancel = () => {
    setIsAmenitiesModal(false);
  };

  const goToHotelDetails = (hotelObj) => {
    let queryObj = {
      hotelId: hotelObj.hotelCode,
      traceId: hotelTraceId,
    };
    const query = queryString.stringify(queryObj);
    return "/hotels/hotel-details?" + query;
  };

  // const openMapLocation = (hotelObjsList) => {
  //   setHotelsListObj(hotelObjsList);
  //   history.push("/hotel-location");
  // };

  const onHandleModal = (hotelObj) => {
    setImagesModal((prev) => ({ ...prev, data: hotelObj, visible: true }));
  };

  function handleImagesModalClose() {
    setImagesModal((prev) => ({ ...prev, data: null, visible: false }));
  }

  return (
    <div key={keyId} className="hotels_search_list">
      <div className="hotel-card">
        <div className="results-wrapper">
          <div className="grid-item hotel-image">
            {hotelCardProps.images.length === 0 ? (
              <div className="hotel_image_">
                <img
                  loading="lazy"
                  src={nohotelimage}
                  alt={hotelCardProps.hotelName}
                />
              </div>
            ) : (
              <div className="hotel_image_">
                <CustomImg
                  src={hotelCardProps.images[0]}
                  alt={hotelCardProps.hotelName}
                />
                <div
                  className="gallery-icon"
                  onClick={() => onHandleModal(hotelCardProps)}
                >
                  <i className="fa fa-search-plus" aria-hidden="true"></i>
                </div>
              </div>
            )}
          </div>
          <div className="grid-item hotel-title">
            <div className="hotel-title-wrapper">
              {isHotelSearchLoad ? (
                <span className="hotel-name">{hotelCardProps?.hotelName}</span>
              ) : (
                <Link to={goToHotelDetails(hotelCardProps)}>
                  <span className="hotel-name">
                    {hotelCardProps?.hotelName}
                  </span>
                </Link>
              )}
            </div>
            {hotelCardProps.starRating && (
              <div className="hotel-star">
                <Rate
                  className="starRating"
                  disabled
                  value={Number(hotelCardProps.starRating)}
                  allowHalf={true}
                />
              </div>
            )}
            <div className="hotel-address">
              <EnvironmentOutlined />
              <span className="hotel-address-title">
                {hotelCardProps.addresses.length > 0
                  ? hotelCardProps.addresses[0].address
                  : null}
              </span>
            </div>
            <div className="hotel-desc">
              <div
                dangerouslySetInnerHTML={{ __html: hotelCardProps.description }}
              ></div>
            </div>
            {hotelCardProps.tripAdvisorRating && (
              <div className="tripadvisor-rating">
                <div className="rating-wrapper">
                  <div className="rating-number">
                    <span>{hotelCardProps.tripAdvisorRating}</span>
                    <span className="sec">/5</span>
                  </div>
                  <div className="traveller-count">
                    <p>TripAdvisor travellers rating</p>
                    <div className="rating-count-value">
                      <span className="tripAd">
                        <i className="fa fa-tripadvisor" aria-hidden="true"></i>
                      </span>
                      <Rate
                        className="tripRating"
                        disabled
                        character={
                          <i className="fa fa-circle" aria-hidden="true"></i>
                        }
                        value={Number(hotelCardProps.tripAdvisorRating)}
                        allowHalf={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {hotelCardProps.hotelFacility ? (
            <div className="hotel-facilities">
              <div className="amenity-list">
                {hotelCardProps.hotelFacility.map((amenity, i) => {
                  if (amenity.toLowerCase().indexOf("wi-fi") > -1) {
                    return (
                      <p key={"fac" + i}>
                        <i className="fa fa-wifi" aria-hidden="true"></i>
                        {amenity}
                      </p>
                    );
                  }
                  if (amenity.toLowerCase().indexOf("swimming pool") > -1) {
                    return (
                      <p key={"fac" + i}>
                        <img src={poolsvg} alt="pool" /> {amenity}
                      </p>
                    );
                  }
                  if (amenity.toLowerCase().indexOf("gym") > -1) {
                    return (
                      <p key={"fac" + i}>
                        <img src={gymsvg} alt="gym" /> {amenity}
                      </p>
                    );
                  }
                  if (amenity.toLowerCase().indexOf("restaurant") > -1) {
                    return (
                      <p key={"fac" + i}>
                        <img src={restaurantsvg} alt="restaurant" />
                        {amenity}
                      </p>
                    );
                  }
                  if (amenity.toLowerCase().indexOf("bar ") > -1) {
                    return (
                      <p key={"fac" + i}>
                        <i className="fa fa-beer" aria-hidden="true"></i>
                        {amenity}
                      </p>
                    );
                  }
                  if (amenity.toLowerCase() === "parking") {
                    return (
                      <p key={"fac" + i}>
                        <img src={parkingsvg} alt="parking" />
                        {amenity}
                      </p>
                    );
                  }
                  if (amenity.toLowerCase().indexOf("kids play") > -1) {
                    return (
                      <p key={"fac" + i}>
                        <i className="fa fa-child" aria-hidden="true"></i>
                        {amenity}
                      </p>
                    );
                  }
                  if (amenity.toLowerCase().indexOf("cafe") > -1) {
                    return (
                      <p key={"fac" + i}>
                        <i className="fa fa-coffee" aria-hidden="true"></i>{" "}
                        {amenity}
                      </p>
                    );
                  }
                })}
              </div>

              {hotelCardProps.hotelFacility.length > 5 ? (
                <>
                  <Button className="showmore-am" onClick={showAmenitiesModal}>
                    Show More
                  </Button>
                  <Modal
                    wrapClassName="modalHeader amenitiesModal"
                    title="All Amenities"
                    visible={isAmenitiesModal}
                    onOk={handleOk}
                    width={800}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    <Row gutter={[8, 8]}>
                      {hotelCardProps.hotelFacility.map((amenity, i) => (
                        <Col key={"ameni" + i} md={8} sm={12} xs={12}>
                          <CheckOutlined style={{ marginRight: "5px" }} />{" "}
                          {amenity}
                        </Col>
                      ))}
                    </Row>
                  </Modal>
                </>
              ) : null}
            </div>
          ) : null}

          <div className="grid-item hotel-price-box">
            <div className="hotel-price-box-wrapper">
              {isHotelSearchLoad ? (
                <Space>
                  <Skeleton.Button
                    active={true}
                    size="default"
                    shape="default"
                    block={false}
                  />
                </Space>
              ) : (
                <>
                  <div className="hotel-price">
                    {showNetFare ? (
                      <>
                        <span className="text-line">
                          {activeCurrency}{" "}
                          <span>
                            {currencyValue(hotelCardProps.hotelPublishPrice)}
                          </span>
                        </span>

                        <p className="netfare">
                          {activeCurrency}{" "}
                          {currencyValue(hotelCardProps.hotelNetPrice)}{" "}
                        </p>
                        <p className="netfare">
                          Inc: {activeCurrency}{" "}
                          {currencyValue(hotelCardProps.commission)}{" "}
                        </p>
                      </>
                    ) : (
                      <span>
                        {activeCurrency}{" "}
                        <span>
                          {currencyValue(hotelCardProps.hotelPublishPrice)}
                        </span>
                      </span>
                    )}
                  </div>
                  <div className="pax-info">
                    {/* <p>
                      price for
                      <strong> 1 night</strong>
                    </p> */}
                  </div>
                  {isFromPackage ? (
                    <div className="hotel-choose-btn">
                      {activeTab !==
                      `hotel_${hotelCardProps.hotelCode}_${keyId}` ? (
                        <Button
                          block
                          className="select-btn activity-choose-btn"
                          onClick={() => {
                            setActiveTab(
                              `hotel_${hotelCardProps.hotelCode}_${keyId}`
                            );
                          }}
                        >
                          Select
                        </Button>
                      ) : (
                        <Button
                          block
                          className="dark-choose-btn activity-choose-btn"
                          onClick={() => {
                            setActiveTab(null);
                          }}
                        >
                          Close
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="hotel-choose-btn">
                      <Link to={goToHotelDetails(hotelCardProps)}>
                        <Button block className="web-choose-btn">
                          Select
                        </Button>
                      </Link>
                      <Link to={goToHotelDetails(hotelCardProps)}>
                        <Button className="mobile-choose-btn">
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {isFromPackage &&
        activeTab === `hotel_${hotelCardProps.hotelCode}_${keyId}` ? (
          <div className="hotel-list">
            {Object.keys(hotelCardProps.hotelCode).length === 0 ? (
              "Loading...."
            ) : activeTab ? (
              activeTab === `hotel_${hotelCardProps.hotelCode}_${keyId}` ? (
                <HotelRoomtable
                  hotelCardProps={hotelCardProps}
                  hotelTraceId={hotelTraceId}
                  isfrompackage={true}
                />
              ) : null
            ) : null}
          </div>
        ) : null}
      </div>
      <Modal
        wrapClassName="modalHeader hotelImgModal"
        visible={imagesModal.visible}
        centered
        width={500}
        title={
          imagesModal.visible ? (
            <div className="headerwrapper">
              <span>{imagesModal.data.hotelName} </span>{" "}
              {imagesModal.data.starRating && (
                <sup>
                  <Rate
                    className="starRating"
                    disabled
                    value={Number(imagesModal.data.starRating)}
                    allowHalf={true}
                  />
                </sup>
              )}
            </div>
          ) : null
        }
        onOk={handleImagesModalClose}
        onCancel={handleImagesModalClose}
        footer={null}
      >
        {imagesModal.visible ? (
          <ImagesLightbox hotelImages={imagesModal.data.images} />
        ) : null}
      </Modal>
    </div>
  );
};

export default HotelsList;
