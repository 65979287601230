import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  message,
  Radio,
  Select,
  Spin,
  Table,
  Row,
  Upload,
  Popconfirm,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

import { useAuthContext } from "../../common/providers/AuthProvider";
import APIClient from "../../helpers/ApiClient";
import {
  getDepositType,
  getStatus,
} from "../../helpers/Deposits/depositsHelpers";
import search from "../assets/vector-icons/search.png";
import excel from "../assets/vector-icons/excel.png";
import UpdatedComponent from "../ExportExcel";

import {
  CloseOutlined,
  PlusOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import "./ManageBalence.scss";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import VerifyPasswordModal from "../../common/VerifyPasswordModal/VerifyPasswordModal";

let dateFormat = "DD-MM-YYYY";
const { Option } = Select;
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/singleImage";

const ImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );
 
  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(true);
      return;
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName} label="Upload Image">
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={true}
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList
              ? fileList.length >= limitSize
                ? null
                : uploadLogoButton
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        destroyOnClose={true}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};
//= React.useState(false);

const ManageBalance = (props) => {
  const { user } = useAuthContext();
  const [type, setType] = useState(1);
  const [image, setImage] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Agentlist, setAgentlist] = useState([]);
  const [Userlist, setUsertlist] = useState([]);
  const [depositeType, setDepositeType] = useState("12");
  const [agentAmount, setAgentAmount] = useState(null);
  const [Banklist, setBanklist] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [ledger, setLedger] = useState(false);;
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [Details, setDetails] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [expirydate, setExpirydate] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isUser, setIsUser] = useState(5);
  const [AvailableBalance, setAvailableBalance] = useState(null)

  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [formType, setFormType] = useState(-1);

  const [currentRec, setCurrentRec] = useState({});
  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
    if(e.target.checked){
      setLedger(1);
    }else{
      setLedger(0);
    }
  //  setType(e.target.value);

  }
  useEffect(() => {
    getAgentList();
    getUserList();
    Bankdetails();
    getallDepositeRequest({
      options: 3,
      fromDate: "",
      toDate: "",
      TransactionId: "",
      ReferenceNumber: "",
      Status: 1,
    });
  }, []);
  const Bankdetails = () => {
    APIClient.get("admin/bankDetails")
      .then((res) => {
        if (res.status == 200) {
          setBanklist(res.data);
        }
      })
      .catch((error) => {});
  };
  const banklistformfilling = (value) => {
    APIClient.get(`admin/bankDetails/${value}`)
      .then((res) => {
        if (res.status == 200) {
          form.setFieldsValue({
            AccountNumber: res.data.AccountNumber,
          });
        }
      })
      .catch((error) => {});
  };

  const onFormSearch = (values) => {
    values.fromDate = values.fromDate ? values.fromDate : "";
    values.toDate = values.toDate ? values.toDate : "";

    getallDepositeRequest(values);
  };
  const getAgentList = () => {
    APIClient.get("admin/b2b/user")
      .then((res) => {
        if (res.status == 200) {
          setAgentlist(res.data);
        }
      })
      .catch((error) => {});
  };

  const getUserList = () => {
    APIClient.get("admin/b2c/getAllUserList")
      .then((res) => {
        if (res.status == 200) {
          setUsertlist(res.data);
        } else {
          message.error(res.messgae);
        }
      })
      .catch((error) => {
        console.error(error.message)
      });
  }


  const getallDepositeRequest = (req) => {
    setShowTable(true);
    setLoading(true);
    APIClient.get("admin/adminDepositRequest", req)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            let imgUrl = "";
            if (item.Image) {
              imgUrl = BASE + item.Image.substring(1);
            }
            return {
              ...item,
              SNo: index + 1,
              DepositeTypeName: getDepositType(item.DepositeType),
              RequestStatusName: getStatus(item.RequestStatus),
              imgUrl,
            };
          });

          setDetails(data);
          setTableData(data);
        } else {
          setDetails([]);
          setTableData([]);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updatedeposite = (req, id) => {
    APIClient.put(`admin/updatedeposit/${id}`, {}, req)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.RequestStatus == 2) {
            message.success("Request Accepted SuccessFully", 3);
          } else if (response.data.RequestStatus == 3) {
            message.success("Request Cancelled", 3);
          }
          setModalVisible(false);
          getallDepositeRequest();
        } else {
          if (response?.message) message.error(response.message, 3);
          else message.error("Failed", 3);
        }
        setExpirydate("");
      })
      .catch((error) => {});
  };

  const updateDepositStatus = (value, data) => {
    if (data.DepositeType === 5) {
      if (!expirydate) {
        message.error("Please Select Expiry Date", 3);
        return;
      }
    }

    let req = {
      DepositeType: data.DepositeType,
      UserID: data.UserID,
      Amount: data.Amount,
      DepositeDate: data.DepositeDate,
      AccountNumber: data.AccountNumber,
      TransactionId: data.TransactionId,
      PaymentType: data.PaymentType,
      BenificiaryBankId: data.BenificiaryBankId,
      remarks: data.remarks,
      Image: data.Image,
      RequestStatus: value,
      DeviceOS: 1,
      DeviceOSVersion: "string",
      ApplicationType: 1,
      DeviceToken: "string",
      FirBaseToken: "string",
      CreditAmount: data.CreditAmount,
      ModifiedBy: user.UserID,
      ExpiryDate: data.DepositeType === 5 ? expirydate : "",
    };
    updatedeposite(req, data.DepositeId);
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const disabledtoDate = (currentDate) => {
    return currentDate > moment();
  };

  const showModal = () => {
    setType(1);
    setDepositeType("12");
    form.resetFields();
    setAgentAmount(null);
    setModalVisible({
      visible: true,
    });
  };

  const disabledOriginDateforex = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const expiry = (value) => {
    if (value) {
      const datevalue = moment(value).format("YYYY-MM-DD");

      setExpirydate(datevalue);
    } else {
      setExpirydate("");
    }
  };

  const column = [
    { title: "Sr No.", dataIndex: "SNo" },
    { title: "Name", dataIndex: "UserName" },
    {
      title: "Deposit Status",
      dataIndex: "RequestStatusName",
      key: "RequestStatusName",
    },
    {
      title: "Deposite Date",
      dataIndex: "DepositeDate",
      key: "DepositeDate",
      render: (val) => (val ? moment(val).format("DD-MM-YYYY") : ""),
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      sorter: {
        compare: (a, b) => a.Amount - b.Amount,
        // multiple: 1
      },
    },
    {
      title: "Reference No",
      dataIndex: "ReferenceNumber",
      key: "ReferenceNumber",
    },
    {
      title: "Deposit Type",
      dataIndex: "DepositeTypeName",
      key: "DepositeTypeName",
      sorter: (a, b) => a.DepositeTypeName?.localeCompare(b.DepositeTypeName),
    },

    {
      title: "Account Number",
      dataIndex: "AccountNumber",
      key: "AccountNumber",
    },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "PaymentType",
    // },
    {
      title: "Transaction Id",
      dataIndex: "TransactionId",
      key: "TransactionId",
    },
    {
      title: "Image",
      dataIndex: "imgUrl",
      render: (imgUrl) =>
        imgUrl ? (
          <div style={{ maxHeight: "60px" }}>
            <Image style={{ width: "100%", maxHeight: "60px" }} src={imgUrl} />
          </div>
        ) : null,
    },
    {
      title: "Credit Amount",
      dataIndex: "CreditAmount",
      key: "CreditAmount",
      render: (val, rec) =>
        val ? (
          rec.RequestStatus === 3 ? (
            <p> {Number(val).toFixed(2)} </p>
          ) : (
            <p style={{ color: "#FF0000", fontWeight: "600" }}>
              {Number(val).toFixed(2)}
            </p>
          )
        ) : (
          ""
        ),
      sorter: (a, b) => a.CreditAmount - b.CreditAmount,
    },

    {
      title: "Expiry Date",
      render: (currentData) => {
        if (currentData.RequestStatus == 1 && currentData.DepositeType == 5) {
          return (
            <Col xs={24} md={6}>
              <Form.Item name="ExpiryDate">
                <DatePicker
                  disabledDate={disabledOriginDateforex}
                  maxDate={new Date()}
                  onChange={(e) => expiry(e)}
                  style={{ width: "130px", height: "30px" }}
                  placeholder="Select Expiry Date date"
                />
              </Form.Item>
            </Col>
          );
        } else {
          return currentData.ExpiryDate != "" ? (
            <p style={{ color: "#FF0000", fontWeight: "600" }}>
              {moment(currentData.ExpiryDate).format("DD-MM-YYYY")}
            </p>
          ) : (
            ""
          );
        }
      },
    },
    {
      title: "Remarks",
      dataIndex: "Remarks",
      key: "Remarks",
    },
    {
      title: "Action",

      render: (currentData) => {
        if (currentData.RequestStatus == 1 && currentData.DepositeType != 3) {
          return (
            <>
              <Popconfirm
                title="Do you want to Accept?"
                onConfirm={() => {
                  setVerifyModalVisible(true);
                  setFormType(2);
                  setCurrentRec(currentData);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Accept </Button>
              </Popconfirm>

              <Popconfirm
                title="Do you want to Reject?"
                onConfirm={() => {
                  setVerifyModalVisible(true);
                  setFormType(3);
                  setCurrentRec(currentData);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" danger className="ml-2">
                  Reject{" "}
                </Button>
              </Popconfirm>

              {/*    
            // <Select
            //   placeholder="Please select"
            //   onSelect={(val) => updateDepositStatus(val, currentData)}
            // >
            //   <Option value={2}>Accepted</Option>
            //   <Option value={3}>Rejected</Option>
            // </Select> */}
            </>
          );
        }
      },
    },
  ];

  function WalletAmount(e) {
    APIClient.get(`admin/GetAgentWalletDetails/${e}`)
      .then((res) => {
        if (res.status == 200) {
          setAgentAmount(res.data.Amount);
        } else {
          setAgentAmount(null);
        }
      })
      .catch((error) => {
        setAgentAmount(null);
      });
  }

  const UserWalletAmount = (e) => {
    if(!isNaN(e)) {
      const user = Userlist.filter(x => x.UserId === e);
      if(user.length > 0) {
        setAvailableBalance(user[0].Amount);
      } else {
        setAvailableBalance(null);
      }
    }
  }

  const submitValues = (values) => {
    let imgUrl = "";

    if (values.UploadImage && Object.keys(values.UploadImage).length > 0) {
      if (
        values.UploadImage.fileList.length > 0 &&
        values.UploadImage.fileList[0].response.status === 200
      ) {
        imgUrl = values.UploadImage.fileList[0].response.data.filepath;
      }
    }

    const data = {
      DepositeType: values.DepositeType,
      UserID: values.UserID,
      AddrRevoke: values.AddrRevoke,
      Amount: values.Amount,
      DepositeDate: moment(),
      AccountNumber: values.AccountNumber,
      TransactionId: values.TransactionId,
      PaymentType: 0,
      BenificiaryBankId: values.BankId,
      Remarks: values.Remarks,
      Image: imgUrl,
      CreditAmount: values.CreditAmount,
      isLedger:ledger,
      DeviceOS: 1,
      DeviceOSVersion: "string",
      ApplicationType: 1,
      DeviceToken: "string",
      FirBaseToken: "string",
      CreatedBy: user.UserID,
      ExpiryDate: values.ExpiryDate ?? "",
    };

    isUser == 5 ? AddrRevoke(data) : AddRevoke4User(data);
  };

  const AddRevoke4User = (req) => {
    APIClient.post("admin/user/depositRequest", req).then((res) => {
      if(res.status === 200) {
        message.success("Amount Added SuccessFully", 3);
        setModalVisible(false);
        getallDepositeRequest();
        form.resetFields();
      } else {
        message.error("Failed to Add Amount", 3);
      }
    }).catch((e) => console.error(e))
  }

  const AddrRevoke = (formMapData) => {
    APIClient.post("admin/AddrRevoke/DepositRequest", formMapData)
      .then((response) => {
        console.log("response", response);
        if (response.status == 200) {
          if (response.data.AddrRevoke == 1) {
            message.success("Amount Added SuccessFully", 3);

            setModalVisible(false);
            getallDepositeRequest();
            form.resetFields();
          } else {
            message.success("Amount Revoked Successfully", 3);
            setModalVisible(false);
            getallDepositeRequest();
          }
        } else if (response.status == 400) {
          message.error("Enter Required Fields", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;

    if (searchVal.length > 0) {
      const searchResults = Details.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });

      setTableData(searchResults);
    } else {
      setTableData(Details);
    }
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const handleExcel = () => {
    const excelData = tableData.map((item) => {
      return {
        S_No: item.SNo,
        DepositType: item.DepositeTypeName,
        DepositStatus: item.RequestStatusName,
        DepositeDate: item.DepositeDate
          ? moment(item.DepositeDate).format("DD-MM-YYYY")
          : "",
        RefNo: item.ReferenceNumber,
        CreditAmount: item.CreditAmount,
        AccountNo: item.AccountNumber ?? "",
        Amount: item.Amount,
        TransactionId: item.TransactionId,

        ExpiryDate: item.ExpiryDate,
        Remarks: item.remarks,
      };
    });
    props.exportExcel(excelData, "Balance Sheet");
  };

  const handleVerifyPassword = (values) => {
    APIClient.post("admin/verifyTransactionPassword", {
      UserID: user.UserID,
      Password: values,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          message.success(res.message);

          if (formType == 1) {
            form.submit();
          } else if (formType == 2) {
            updateDepositStatus(2, currentRec);
          } else if (formType == 3) {
            updateDepositStatus(3, currentRec);
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  return (
    <>
      <div className="promo ">
        <div className="manage-markup-section">
          <div className="container-fluid">
            <div className="card-bt-gap">
              <Card bordered={false}>
                <div className="card-add-heading">
                  <div className="rows-count">
                    <div>
                      <h5>
                        Manage Balance{" "}
                        <HelpInfoHelper screenName={"/admin/managebalance"} />
                      </h5>
                    </div>
                  </div>
                  <div className="action-images">
                    <Button key="add" type="primary" onClick={showModal}>
                      Add / Revoke
                    </Button>
                  </div>
                </div>
                <Form
                  form={form2}
                  initialValues={{
                    options: 3,
                    fromDate: "",
                    toDate: "",
                    TransactionId: "",
                    ReferenceNumber: "",
                    Status: 1,
                  }}
                  layout={"vertical"}
                  onFinish={onFormSearch}
                >
                  <Row gutter={[8, 8]}>
                    <Col className="gutter-row" md={6} xs={24}>
                      <Form.Item label="Time and Date" name="options">
                        <Select
                          placeholder="Please select"
                          onChange={handleTimeAndDate}
                        >
                          <Option value={1}>Last One Hour</Option>
                          <Option value={2}>Yesterday</Option>
                          <Option value={3}>Last Week</Option>

                          <Option value={5}>Custom Dates</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {isDateDisplay ? (
                      <>
                        <Col md={6} sm={12} xs={24}>
                          <Form.Item label="From Date" name="fromDate">
                            <DatePicker
                              format="DD-MM-YYYY"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>

                        <Col md={6} sm={12} xs={24}>
                          <Form.Item label="To Date" name="toDate">
                            <DatePicker
                              format="DD-MM-YYYY"
                              style={{ width: "100%" }}
                              disabledDate={disabledtoDate}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null}

                    <Col md={6} xs={24}>
                      <Form.Item
                        label="Transaction ID"
                        name="TransactionId"
                        // rules={[
                        //   {
                        //     required: true,
                        //   },
                        // ]}
                      >
                        <Input placeholder="Enter transaction Number" />
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item
                        label="Reference Number "
                        name="ReferenceNumber"
                        // rules={[
                        //   {
                        //     min: 12,
                        //     pattern: "^[a-z_A-Z0-9]*$",
                        //     max: 12,
                        //     message: "Must be in 12  Alphanumeric ",
                        //   },
                        // ]}
                      >
                        <Input placeholder="Enter Your Reference Number" />
                      </Form.Item>
                    </Col>

                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label="Status"
                        name="Status"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Select placeholder="Please Select">
                          <Option value={2}>Accepted</Option>
                          <Option value={1}>Pending </Option>
                          <Option value={3}>Rejected</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="reports-btns">
                    <Button
                      type="primary"
                      className="login-form-button"
                      onClick={() => form2.submit()}
                    >
                      Search
                    </Button>
                    <Button
                      type="danger"
                      className="login-form-button"
                      onClick={() => {
                        form2.resetFields();
                      }}
                    >
                      Reset
                    </Button>
                  </Row>
                </Form>
              </Card>
            </div>
            {showTable && (
              <div className="card-bt-gap">
                <Card bordered={false}>
                  {Details.length > 0 ? (
                    <>
                      <div className="card-add-heading">
                        <div className="rows-count">
                          <div>
                            <h6>{Details.length} Records found !</h6>
                          </div>
                        </div>
                        <div className="action-images">
                          {showSearchBox && (
                            <Input
                              placeholder="Search"
                              onChange={(e) => searchTableData(e)}
                              suffix={closeSearchInput}
                              style={{ padding: "0px 12px" }}
                            />
                          )}
                          &nbsp;&nbsp;
                          <img
                            src={search}
                            alt="search"
                            onClick={(e) => searchData()}
                          />
                          <img src={excel} alt="excel" onClick={handleExcel} />
                        </div>
                      </div>
                      <Table
                        className="table-scroll-none"
                        style={{ color: "red" }}
                        columns={column}
                        dataSource={tableData}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />{" "}
                    </>
                  ) : loading ? (
                    <Spin
                      size="large"
                      tip="Loading..."
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <b>No Results Found!</b>
                  )}
                </Card>
              </div>
            )}
          </div>
        </div>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Add / Revoke Balance</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header manage-modal-sheet"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <Button
                key="add"
                type="primary"
                onClick={() => {
                  form
                    .validateFields()
                    .then(() => {
                      setVerifyModalVisible(true);
                      setFormType(1);
                    })
                    .catch((e) => {});
                }}
              >
                Submit
              </Button>
              <Button
                type="danger"
                onClick={(e) => {
                  setModalVisible(false);
                }}
              >
                Cancel
              </Button>
            </div>,
          ]}
        >
          <Form
            form={form}
            layout={"vertical"}
            onFinish={submitValues}
            initialValues={{
              AddrRevoke: 1,
              DepositeType: "12",
              isUser: "5"
            }}
          >
            <Row gutter={8}>
            <Col md={6} xs={24}>
                <Form.Item
                  label="IsUser"
                  name="isUser"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Select
                    showSearch
                    placeholder="Select a Type"
                    onChange={(value) => setIsUser(value)}
                    options={[
                      {
                        value: '2',
                        label: 'User',
                      },
                      {
                        value: '5',
                        label: 'Agent',
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  label="Choose Type"
                  name="AddrRevoke"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Radio.Group onChange={(e) => setType(e.target.value)}>
                    <Radio value={1}>Add</Radio>
                    <Radio value={2}>Revoke</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {isUser == 5 ? (
            <React.Fragment>
              <Row gutter={8}>
                <Col md={6} xs={24}>
                  <Form.Item
                    label="Select Partner"
                    name="UserID"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select
                    showSearch
                      placeholder="Select Agent"
                      onChange={(e) => {
                        WalletAmount(e);
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {Agentlist.map((data, index) => (
                        <Option key={index} value={data.UserID}>
                          {data.userDetails.AgencyName}
                        </Option>
                      ))}
                    </Select>
                  
                  </Form.Item>

                  {agentAmount != null ? (
                    <p style={{ textAlign: "right", marginRight: "19px" }}>
                      Partner Amount: {agentAmount}
                    </p>
                  ) : null}
                </Col>

                {type == 1 ? (
                  <>
                    <Col md={6} xs={24}>
                      <Form.Item
                        label="DepositeType"
                        name="DepositeType"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Select
                          placeholder="Select Deposit"
                          onSelect={(val) => setDepositeType(val)}
                        >
                          <Select.Option value="12"> Virtual cash</Select.Option>
                          <Select.Option value="13">Bank Transfer</Select.Option>
                          <Select.Option value="2">
                            NEFT( (National Electronic Funds Transfe)
                          </Select.Option>
                          <Select.Option value="4">
                            IMPS(Immediate Payment Service)
                          </Select.Option>
                          <Select.Option value="5">Credit</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={26} md={6}>
                      <Form.Item label="Deposit Date " name="DepositeDate">
                        <DatePicker
                          style={{ width: "100%" }}
                          defaultValue={moment()}
                          format={dateFormat}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : null}
                {depositeType === "5" && type === 1 ? (
                  <>
                    <Col md={6} xs={24}>
                      <Form.Item
                        label="Credit Amount"
                        name="CreditAmount"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input placeholder="Credit" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                      <Form.Item
                        label="Expiry Date"
                        name="ExpiryDate"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <DatePicker
                          disabledDate={disabledOriginDate}
                          style={{ width: "100%" }}
                          placeholder="Select From date"
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Amount"
                      name="Amount"
                      rules={[
                        { required: true, message: "Required" },
                        {
                          min: 3,
                          pattern: "^[1-9][0-9]*$",
                          message: "Must be Greater than 100",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Amount" type="number" />
                    </Form.Item>
                  </Col>
                )}

                {["13", "2"].includes(depositeType) ? (
                  <>
                    {" "}
                    <Col md={6} xs={24}>
                      <Form.Item
                        label="Benificiary Bank Name"
                        rules={[{ required: true, message: "Required" }]}
                        name="BankId"
                      >
                        <Select
                          placeholder="Select Bank Name"
                          onChange={banklistformfilling}
                        >
                          {Banklist.map((data, index) => (
                            <Option key={index} value={data.ID}>
                              {data.BankName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                      <Form.Item
                        label="Account Number "
                        name="AccountNumber"
                        rules={[
                          { required: true, message: "Required" },
                          {
                            min: 11,
                            pattern: "^[0-9]*$",
                            max: 17,
                            message: "Must be in between 11 to 17 digits",
                          },
                        ]}
                      >
                        <Input
                          disabled={true}
                          placeholder="Enter Account Number"
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : null}

                <Col md={6} xs={24}>
                  <Form.Item
                    label="Transaction No "
                    name="TransactionId"
                    // rules={[
                    //   {
                    //     min: 12,
                    //     pattern: "^[a-zA-Z0-9]*$",
                    //     max: 18,
                    //     message: "Must be in between 12 to 18 Alphanumeric",
                    //   },
                    //   {
                    //     required: true,
                    //   },
                    // ]}
                  >
                    <Input placeholder="Enter Transaction Number" />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    label="IsLedger "
                    name="IsLedger"
                  >
                  <Checkbox onChange={onChange}></Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Remarks"
                    rules={[{ required: true, message: "Required" }]}
                    name="Remarks"
                  >
                    <TextArea style={{ width: "100%" }} rows={4} />
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <ImageUpload
                    category="agentdeposits"
                    fileList={image}
                    setFileList={setImage}
                    formName="UploadImage"
                    limit={true}
                    limitSize={1}
                  />
                </Col>
              </Row>
            </React.Fragment>
            ) : isUser == 2 && (<React.Fragment>
            <Row gutter={8}>
              <Col md={6} xs={24}>
                <Form.Item
                  label="Select User"
                  name="UserID"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Select
                    placeholder="Select User"
                    onChange={UserWalletAmount}
                  >
                    {Userlist.map((data, index) => (
                      <Option key={index} value={data.UserId}>
                        {data.Name}
                      </Option>
                    ))}
                  </Select>
                
                </Form.Item>

                {AvailableBalance != null ? (
                  <p style={{ textAlign: "right", marginRight: "19px" }}>
                    Available Balance: {AvailableBalance}
                  </p>
                ) : null}
              </Col>

              {type == 1 ? (
                <>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="DepositeType"
                      name="DepositeType"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Select
                        placeholder="Select Deposit"
                        onSelect={(val) => setDepositeType(val)}
                      >
                        <Select.Option value="12"> Virtual cash</Select.Option>
                        <Select.Option value="13">Bank Transfer</Select.Option>
                        <Select.Option value="2">
                          NEFT( (National Electronic Funds Transfe)
                        </Select.Option>
                        <Select.Option value="4">
                          IMPS(Immediate Payment Service)
                        </Select.Option>
                        <Select.Option value="5">Credit</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={26} md={6}>
                    <Form.Item label="Deposit Date " name="DepositeDate">
                      <DatePicker
                        style={{ width: "100%" }}
                        defaultValue={moment()}
                        format={dateFormat}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null}
              {depositeType === "5" && type === 1 ? (
                <>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Credit Amount"
                      name="CreditAmount"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input placeholder="Credit" />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Expiry Date"
                      name="ExpiryDate"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <DatePicker
                        disabledDate={disabledOriginDate}
                        style={{ width: "100%" }}
                        placeholder="Select From date"
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <Col md={6} xs={24}>
                  <Form.Item
                    label="Amount"
                    name="Amount"
                    rules={[
                      { required: true, message: "Required" },
                      {
                        min: 3,
                        pattern: "^[1-9][0-9]*$",
                        message: "Must be Greater than 100",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Amount" type="number" />
                  </Form.Item>
                </Col>
              )}

              {["13", "2"].includes(depositeType) ? (
                <>
                  {" "}
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Benificiary Bank Name"
                      rules={[{ required: true, message: "Required" }]}
                      name="BankId"
                    >
                      <Select
                        placeholder="Select Bank Name"
                        onChange={banklistformfilling}
                      >
                        {Banklist.map((data, index) => (
                          <Option key={index} value={data.ID}>
                            {data.BankName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Account Number "
                      name="AccountNumber"
                      rules={[
                        { required: true, message: "Required" },
                        {
                          min: 11,
                          pattern: "^[0-9]*$",
                          max: 17,
                          message: "Must be in between 11 to 17 digits",
                        },
                      ]}
                    >
                      <Input
                        disabled={true}
                        placeholder="Enter Account Number"
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null}

              <Col md={6} xs={24}>
                <Form.Item
                  label="Transaction No "
                  name="TransactionId"
                >
                  <Input placeholder="Enter Transaction Number" />
                </Form.Item>
              </Col>
              {/* <Col md={6} xs={24}>
                <Form.Item
                  label="IsLedger "
                  name="IsLedger"
                >
                <Checkbox onChange={onChange}></Checkbox>
                </Form.Item>
              </Col> */}
            </Row>
            <Row gutter={8}>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Remarks"
                  rules={[{ required: true, message: "Required" }]}
                  name="Remarks"
                >
                  <TextArea style={{ width: "100%" }} rows={4} />
                </Form.Item>
              </Col>
              {/* <Col md={12} xs={24}>
                <ImageUpload
                  category="agentdeposits"
                  fileList={image}
                  setFileList={setImage}
                  formName="UploadImage"
                  limit={true}
                  limitSize={1}
                />
              </Col> */}
            </Row>
          </React.Fragment>)}
          </Form>
        </Modal>

        {verifyModalVisible && (
          <VerifyPasswordModal
            visible={verifyModalVisible}
            setVerifyModalVisible={setVerifyModalVisible}
            handleVerifyPassword={handleVerifyPassword}
          />
        )}
      </div>
    </>
  );
};

export default UpdatedComponent(ManageBalance);
