import React, { useEffect, useState } from "react";
import { Button, Card, Col, Checkbox, message, Row, Skeleton } from "antd";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useActivitiesContext } from "../../../common/providers/Activities/ActivitiesProvider";
import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";

import "./activitiescart.scss";

const ActivitiesCart = () => {
  let history = useHistory();
  //Start Code Add By Karthik On 27/April/2023 by nitin sir 
  const { activeCurrency, currencyValue } = useCurrencyContext();
  // End Blog Code Add By Karthik On 27/April/2023 by

  const { user } = useAuthContext();
  const {
    state: { activitiesCart, activitiesLocalCart },
    setActivitiesCart,
    setActivitiesLocalCart,
    setActivitiesCheckOutData,
  } = useActivitiesContext();

  const [cartData, setCartData] = useState({
    searchRequest: {},
    TourDetails: [],
  });
  const [isLoading, setLoading] = useState(true);

  let selectedTour = [];

  const getActivitiesByUserId = (userId) => {
    ApiClient.get(`activities/tours/getactivitiesbyuserId/${userId}`)
      .then((result) => result)
      .then((resp) => {
        if (resp.statusCode == 200 && resp?.data) {
          setCartData(resp.data);
          setActivitiesCart(resp.data)
        } else {
          setCartData({
            searchRequest: {},
            TourDetails: [],
          });
          setActivitiesCart({
            searchRequest: {},
            TourDetails: [],
          });
        }
        setLoading(false);
      });
  };

  // const getactivitiesbycartId = () => {
  //   setLoading(true);
  //   let param = queryString.parse(document.location.search);

  //   if (param.id) {
  //     ApiClient.get(`activities/tours/getactivitiesbycartId/${param.id}`)
  //       .then((result) => result)
  //       .then((resp) => {
  //         if (resp.statusCode == 200 && resp?.data) {
  //           setCartData({ cartId: param.id, ...resp.data });
  //         } else {
  //           setCartData({
  //             cartId: "",
  //             searchRequest: {},
  //             TourDetails: [],
  //           });
  //         }
  //         setLoading(false);
  //       });
  //   } else {
  //     setCartData({
  //     cartId: "",
  //       searchRequest: {},
  //       TourDetails: [],
  //     });
  //     setLoading(false);
  //   }
  // };

  // const deleteToursByCartId = (cartId, optionId) => {
  //   ApiClient.delete(
  //     `activities/tours/removetours?cartId=${cartId}&TourOptionId=${optionId}`
  //   )
  //     .then((result) => result)
  //     .then((resp) => {
  //       if (resp.statusCode == 200) {
  //         <Alert message="Tours Deletd" type="success" />;
  //         getactivitiesbycartId();
  //         setLoading(false);
  //       }
  //     });
  // };

  const deleteToursByUserId = (optionId, userId) => {
    ApiClient.delete(
      `activities/tours/deleteactivitiesbyuserId?userId=${userId}&TourOptionId=${optionId}`
    )
      .then((result) => result)
      .then((resp) => {
        if (resp.statusCode == 200) {
          message.success("Tours Deleted Successfully", 2);
          getActivitiesByUserId(userId);
          setLoading(false);
        }
      });
  };

  const handleDeleteTour = (optionId) => {
    // Add Comment For Cart Modification
    // if (user) {
    //   deleteToursByUserId(optionId, user.UserID);
    // } else {
      let filterList = cartData.TourDetails.filter(
        (item) => item.optionId !== optionId
      );
      setCartData((prev) => ({
        ...prev,
        TourDetails: filterList,
      }));
      setActivitiesLocalCart((prev) => ({
        ...prev,
        TourDetails: filterList,
      }));
    // }
  };

  const selectTourFromcart = (e, tours, index) => {
    if (e.target.checked == true) {
      selectedTour.push(tours);
    } else {
      selectedTour.splice(index, 1);
    }
  };
  const goto = () => {
    if (selectedTour.length === 0) {
      message.error("Please Select Tours", 3);
    } else {
      setActivitiesCheckOutData({
        searchRequest: cartData?.searchRequest,
        TourDetails: selectedTour,
      });
      history.push("/activities/checkout");
    }
  };

  // useEffect(() => {
  //   getactivitiesbycartId();
  // }, []);

  /**
   * Add Comment By Karthik For Cart Modification 
   *
  useEffect(() => {
    if (user) {
      if (user.UserID) {
        getActivitiesByUserId(user.UserID);
      }
    } else {
      if (activitiesCart && Object.keys(activitiesCart).length > 0) {
        setCartData(activitiesCart);
      }
      setLoading(false);
    }
  }, [user]);
  */
  // Add Code By Karthik For Cart Modification
  useEffect(() => {
    if (activitiesLocalCart && Object.keys(activitiesLocalCart).length > 0) {
      setCartData(activitiesLocalCart);
    }
    setLoading(false);
  }, [activitiesCart]);
  // End Code By Karthik For Cart Modification

  const getPaxCount = (tour) => {
    return `Adult(s) ${tour?.adult ? tour?.adult : 0} ${
      tour?.child ? ", Child(s) " + tour?.child : " "
    } ${tour?.infant ? ", Infant(s) " + tour?.infant : " "}`;
  };

  const handleAddMore = () => {
    if (cartData?.searchRequest) {
      let query = {
        country: cartData?.searchRequest?.country,
        city: cartData?.searchRequest?.city,
        travelDate: cartData?.searchRequest?.travelDate,
      };
      let queryParams = queryString.stringify(query);
      history.push("/activities/results?" + queryParams);
    }
  };

  return (
    <div className="promo providerapi activities-cart-wrapper">
      <div className="manage-markup-section">
        <div className="card-add-heading">
          <h4 className="shopping-heading">Activities cart</h4>
        </div>

        {isLoading ? (
          <Card bordered={false} className="activity-cart-card">
            <Skeleton active />
          </Card>
        ) : cartData.TourDetails.length > 0 ? (
          <>
            <Card bordered={false} className="activity-cart-card">
              <p className="select-title">Please Select Activities</p>
            </Card>
            {cartData.TourDetails.map((tour, index) => {
              return (
                <Card bordered={false} className="activity-cart-card">
                  <Row gutter={[16, 16]} className="card-value-img">
                    <Col md={4} sm={12} xs={24}>
                      <div className="activity-img-wrapper">
                        <img src={tour?.tourImage} alt={tour?.tourName} />
                      </div>
                    </Col>

                    <Col md={20} sm={12} xs={24}>
                      <Row>
                        <Col md={16} sm={24} xs={24}>
                          <div className="d-flex align-items-center mb-2">
                            <Checkbox
                              onChange={(e) =>
                                selectTourFromcart(e, tour, index)
                              }
                            ></Checkbox>
                            <p className="title">{tour?.tourName}</p>
                          </div>

                          <p className="subtitle">{tour?.optionName}</p>
                          <p className="subtitle">{tour?.transferName}</p>
                          <p className="subtitle">{tour?.tourDate}</p>
                          {tour.transferName !== "Private Transfer" && tour.apikey !== "EXT" && (
                            <p className="subtitle">{tour?.duration}</p>
                          )}
                          <p className="subtitle">{tour?.startTime}</p>

                          <p className="subtitle">{getPaxCount(tour)}</p>
                        </Col>

                        <Col md={8} sm={24} xs={24}>
                          <div className="price-wrapper">
                            {/* Code Start Add activeCurrency Function For Currency Conversion issue Add by Karthik 27/April/2023 */}
                            <p className="subtitle">{activeCurrency} {currencyValue(tour.serviceTotal)}</p>
                            {/* Code Blog End  */}
                            <Button
                              className="delete-btn"
                              onClick={() => handleDeleteTour(tour.optionId)}
                            >
                              Delete
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              );
            })}

            <Card bordered={false} className="activity-cart-card">
              <div className="d-flex align-items-center">
                <Button className="all-near-places-12" onClick={() => goto()}>
                  Pay Now
                </Button>
                {/* <Button
                  className="all-near-places-12 ml-2"
                  onClick={() => handleAddMore()}
                >
                  Add More
                </Button> */}
              </div>
            </Card>
          </>
        ) : (
          <h6>Your Activities Cart is empty</h6>
        )}
      </div>
    </div>
  );
};
export default ActivitiesCart;
