import React, { useState, useEffect } from "react";
// import PaxSelectionForm from "./PaxSelectionForm";
import {
  Button,
  Collapse,
  Col,
  Checkbox,
  DatePicker,
  message,
  Modal,
  Row,
  Form,
  Select,
  Skeleton,
  Tooltip,
  Spin,
} from "antd";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useActivitiesContext } from "../../../common/providers/Activities/ActivitiesProvider";
import Table from "react-bootstrap/Table";

import moment from "moment";
import ApiClient from "../../../helpers/ApiClient";
import { getDateRange } from "../../../helpers/activitiesHelper";
import "./activitiestourdetail.scss";
import { useHistory } from "react-router-dom";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
const { Option } = Select;

const ActivitiesSelectpkg = ({
  tourDetail,
  isfromPackage,
  searchReq = false,
  setCartData=()=>{},
  getActivitiesByUserId = (userId) => { }
}) => {
  const { user } = useAuthContext();

  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    state: { activitiesCart, activitiesLocalCart, selectedBuildActivities },
    setActivitiesCart,
    setActivitiesLocalCart,
    setSelectedBuildActivities,
  } = useActivitiesContext();

  let history = useHistory();

  const oriDateFormat = "YYYY-MM-DD";
  const dateFormat = "DD-MM-YYYY";

  const [tourForm] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const [timeSlotModelVisible, setTimeSlotModelVisible] = useState(false);
  const [timeSlotLoading, setTimeSlotLoading] = useState(true);
  const [timeSlotModelData, setTimeSlotModelData] = useState({});
  const [hourtourList, setHourTourList] = useState([]);
  const [IsTrio, setIsTrio] = useState(false);
  const [FormData, setFormData] = useState({});


  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const [tourTimeSlot, setTourTimeSlot] = useState([]);
  const [isOptionDataLoding, setIsOptionDataLoading] = useState(true);
  const [tableTotalPrice, setTableTotalPrice] = useState([]);
  const [activitycommission, setActivitycommission] = useState(0);
  const [tourList, setTourList] = useState([]);
  const [maximumPax, setMaximumPax] = useState([]);
  const [TotalPax, setTotalPax] = useState([]);
  const [maxPax, setMaxPax] = useState(false);
  let TravelDate = isfromPackage
    ? moment(searchReq.checkInDate)
    :  moment(tourDetail?.searchRequest?.travelDate, "YYYY-MM-DD");

  const [tourOptionsDataList, setTourOptionsDataList] = useState([]);
  const [RateBookingModalData, setRateBookingModalData] = useState({});

  const getPaxCount = (tour) => {
    return `Adult(s) ${tour?.adult ? tour?.adult : 0} ${tour?.child ? ", Child(s) " + tour?.child : " "
      } ${tour?.infant ? ", Infant(s) " + tour?.infant : " "}`;
  };

  const getTourOptionData = async (uniqueIds, uniqueTourList, totalTablePrice) => {
    let optionDataList = [];
    const res = {
      traceId: tourDetail.traceId,
      tourOptionId: 0,
      apikey: tourDetail.apikey,
    };
    try {
      if (
        res.traceId != null &&
        res.tourOptionId != null &&
        uniqueIds != null
      ) {
        for (let i = 0; i < uniqueIds.length; i++) {
          res.tourOptionId = uniqueIds[i];
          await ApiClient.post("activities/tours/options/data", res)
            .then((result) => result)
            .then((resp) => {
              if (resp.statusCode == 200) {
                optionDataList.push({
                  ...resp.data,
                  transferNames: uniqueTourList[i],
                  traceId: resp.traceId,
                });
              }else{
                const filteredPrices = totalTablePrice.filter(item => item.tourOptionId !== res.tourOptionId);
                setTableTotalPrice(filteredPrices);
              }
            })
            .catch((err) => {
              return err;
            });
        }
        const minimumPaxArray = [];
        optionDataList.forEach((optionData, index) => {
          minimumPaxArray.push({ index, minimumPax: optionData.transferNames[0].minimumPax });
          setMaximumPax(minimumPaxArray)
        });
        setTourOptionsDataList(optionDataList);
        setIsOptionDataLoading(false);
      }
    } catch (error) { }
  };

  //#region  api call for tourOption
  const getTourOption = async () => {
    const ReqObj = {
      traceId: tourDetail.traceId,
      tourId: tourDetail.tourId,
      apikey: tourDetail.apikey,
      adultCount: 1,
      childCount: 0,
      infantCount: 0,
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
    };

    await ApiClient.get("activities/tours/getliveTourList")
      .then((result) => {
        setTourList(result);
      })

    if (ReqObj.traceId != null && ReqObj.tourId != null) {
      await ApiClient.post("activities/tours/options", ReqObj)
        .then((result) => result)
        .then((resp) => {
          if (resp.statusCode == 200) {
            let uniqueTourList = [];
            let totalTablePrice = [];
            let uniqueIds = [
              ...new Set(resp.data.map((data) => data.tourOptionId)),
            ];
            uniqueIds.forEach((tours) => {
              let uniqueTourOption = resp.data.filter(
                (x, i) => x.tourOptionId == tours
              );

              uniqueTourOption = uniqueTourOption.map((option) => {
                let tourDate = moment(TravelDate);

                if (moment(TravelDate).diff(moment(), "days") <= 0) {
                  // let todayTime = moment().format("hh:mm:ss");
                  let startTime = moment(option.startTime, "hh:mm:ss");
                  let tours = tourList.filter(x => x.TourID == tourDetail.tourId)
                  if (startTime.diff(moment(), "hours") < 1 && tours.length >= 1) {
                    tourDate = moment(tourDate, "YYYY-MM-DD").add(1, "days");
                  }
                }

                return {
                  ...option,
                  adultCount: 1,
                  childCount: 0,
                  infantCount: 0,
                  isChecked: false,
                  isSelected: false,
                  tourDate: tourDate,
                };
              });

              uniqueTourList.push(uniqueTourOption);
              totalTablePrice.push({finalAmount:
                Number(uniqueTourOption[0].finalAmount).toFixed(2),
                tourOptionId:uniqueTourOption[0].tourOptionId}
              );
            });

            setTableTotalPrice(totalTablePrice);

            getTourOptionData(uniqueIds, uniqueTourList,totalTablePrice);
          } else {
            setIsOptionDataLoading(false);
          }
        })
        .catch((error) => { });
    }
  };


  const getHourlyToursData = async () => {
    await ApiClient.get("activities/tours/gethourlytour")
      .then((result) => {
        setHourTourList(result);
      })
  }

  useEffect(() => {
    getHourlyToursData();
    getTourOption();
  }, []);

  //#endregion

  const disabledOriginDate = (currentDate, operationDays, tourDate) => {
    if (operationDays) {
      var filteredKeys = Object.keys(operationDays).filter(
        (key) => operationDays[key] === 1
      );

      if (
        !(
          filteredKeys.indexOf(
            moment(currentDate).format("dddd").toLowerCase()
          ) > -1
        )
      ) {
        return true;
      }
    }
    if (isfromPackage == true) {
      if (currentDate < moment(tourDate).startOf("day")) {
        return true;
      }
      return (
        moment(searchReq.checkOutDate).endOf("day") < currentDate ||
        currentDate < moment(searchReq.checkInDate).startOf("day")
      );
    } else {
      return currentDate < moment(tourDate).startOf("day");
    }
  };

  const getTotalPrice = async (transferOptions, index, tourOption, traceId, isTranferSelected) => {
    try {
      let formData = tourForm.getFieldsValue();
      if (formData?.TourDetails) {
        let { transferId, adult, child, infant, tourDate } = formData.TourDetails[index];

        if (transferOptions[0].tourName.includes('TRIO')) {
          formData.TourDetails?.map((tour, i) => {
            tour.adult = adult
            tour.child = child
            tour.infant = infant
            tour.isSelected = true;
          })
        }

        if(isTranferSelected == false) {
          if (transferOptions[0]?.minimumPax < (adult + child + infant) && transferOptions[0]?.apikey == "EXT") {
            message.error(`Only ${transferOptions[0]?.minimumPax} Pax Can be Selected`);
            setMaxPax(true);
          } else {
            message.success(`Your Selected Pax ${adult + child + infant}`);
            setMaxPax(false);
            let TotalPax = maximumPax[index].minimumPax - (adult + child + infant);
            console.log(`Total Pax: ${TotalPax}`);
            setTotalPax(TotalPax);
          }
      }

        let findTransferOption = transferOptions.filter(
          (item) => item.transferId === transferId
        );

        // let total =
        //   adult * findTransferOption[0].adultPrice +
        //   child * findTransferOption[0].childPrice +
        //   infant * findTransferOption[0].infantPrice;
        // let total = findTransferOption[0].adultPrice;

        // let tablePriceCopy = [...tableTotalPrice];
        // tablePriceCopy[index] = Number(total).toFixed(2);

        let calculateObj = {
          traceId: traceId,
          tourId: tourOption.tourId,
          tourOptionId: tourOption.tourOptionId,
          transferId: transferId,
          adultCount: adult,
          childCount: child,
          infantCount: infant,
          tourDate: moment(tourDate).format("YYYY-MM-DD"),
          userId: user?.UserID ?? 1,
          roleType: user?.Role?.RoleId ?? 4,
          membership: user?.Membership ?? 1,
          apikey: findTransferOption[0].apikey,
        };

        setPriceLoading(true);
        await ApiClient.post(
          "activities/tours/calculatedoptionsprice",
          calculateObj
        )
          .then((result) => result)
          .then((resp) => {
            if (resp.statusCode == 200) {
              let tablePriceCopy = [...tableTotalPrice];

              /// New code :- 12-4-  round off
              // let tablePriceCopy = [...tableTotalPrice].length > 1 ? Math.ceil([...tableTotalPrice]) : [...tableTotalPrice];
              // tablePriceCopy[index] = Number(resp.data.finalAmount).toFixed(2);
              tablePriceCopy[index].finalAmount = Number(Math.ceil(resp.data.finalAmount)) // .toFixed(2);44
              let commissionValue = Number(Math.ceil(resp.data.commission))
              setActivitycommission(commissionValue);
              // end end code  
              setTableTotalPrice(tablePriceCopy);
            }
            setPriceLoading(false);
          })
          .catch((err) => {
            return err;
            setPriceLoading(false);
          });
      }
    } catch (error) { }
  };

  // const getDynamicPrice = (tour) => {
  //   if (Object.keys(selectedTimeSlot).length > 0) {
  //     if (selectedTimeSlot.isDynamicPrice) {
  //       // let total =
  //       //   tour.adult * selectedTimeSlot.adultPrice +
  //       //   tour.child * selectedTimeSlot.childPrice +
  //       //   tour.infant * tour.infantPrice;
  //       let total = selectedTimeSlot.adultPrice;
  //       return Number(total).toFixed(2);
  //     }
  //   }
  //   return tableTotalPrice[tour.tourIndex];
  // };

  const setBookingPolicyAndFareBreakup = (tourDetails, index) => {
    try {
      let formData = tourForm.getFieldsValue();
      let { transferId, adult, child, infant, optionName } =
        formData.TourDetails[index];

      let findTransferOption = tourDetails.transferNames.filter(
        (item) => item.transferId === transferId
      );

      let touroption = tourDetails.touroption;

      let total =
        adult * findTransferOption[0].adultPrice +
        child * findTransferOption[0].childPrice +
        infant * findTransferOption[0].infantPrice;

      let modalData = {
        adult,
        child,
        infant,
        optionName,
        transferName: findTransferOption[0].transferName,
        cancellationPolicy: touroption?.cancellationPolicy,
        cancellationPolicyDescription:
          touroption?.cancellationPolicyDescription,
        childPolicyDescription: touroption?.childPolicyDescription,
        adultPrice: Number(findTransferOption[0].adultPrice).toFixed(2),
        childPrice: Number(findTransferOption[0].childPrice).toFixed(2),
        infantPrice: Number(findTransferOption[0].infantPrice).toFixed(2),
        total: Number(total).toFixed(2),
      };

      setRateBookingModalData(modalData);
      setVisible(true);
    } catch (error) { }
  };

  const onSubmitSelectedActivity = (value) => {
    try {
      let formData = value.TourDetails;
      let selectedTourListWithSlot = formData.filter(
        (item) => item.isSelected === true && item.isSlot === true
      );

      if (selectedTourListWithSlot.length > 0) {
        getTimeSlot(
          tourOptionsDataList[selectedTourListWithSlot[0].tourIndex]
            ?.transferNames,
          selectedTourListWithSlot[0].tourIndex,
          tourOptionsDataList[selectedTourListWithSlot[0].tourIndex].touroption,
          tourOptionsDataList[selectedTourListWithSlot[0].tourIndex].traceId,
          tableTotalPrice[selectedTourListWithSlot[0].tourIndex].finalAmount
        );

        return;
      }

      let selectedTourList = formData.filter(
        (item) => item.isSelected === true
      );

      if (selectedTourList.length < 1) {
        message.error("Please select Tours", 4);
        return;
      }
      storeSelectedActivities(selectedTourList, false);
    } catch (error) {
      console.log(error);
    }
  };

  const storeSelectedActivities = async (selectedTourList, isFromTimeSlot) => {
    try {
      const tourAvlDate = getDateRange(
        searchReq.checkInDate,
        searchReq.checkOutDate
      );

      let tourItenaryDate = [...selectedBuildActivities];

      let tourListArray = selectedTourList.map((item) => {
        // let i = tourAvlDate.indexOf(moment(item.tourDate).format("DD-MM-YYYY"));

        let data = {};
        if (isFromTimeSlot) {
          data = { ...item };
        } else {
          let tourdetails = tourOptionsDataList[item.tourIndex];
          let findTransferOption = tourdetails.transferNames.filter(
            (opt) => opt.transferId === item.transferId
          );

          let total =
            item.adult * findTransferOption[0].adultPrice +
            item.child * findTransferOption[0].childPrice +
            item.infant * findTransferOption[0].infantPrice;

          let calculateObj = {
            traceId: tourDetail.traceId,
            tourId: findTransferOption[0].tourId,
            tourOptionId: findTransferOption[0].tourOptionId,
            transferId: findTransferOption[0].transferId,
            adultCount: item.adult,
            childCount: item.child,
            infantCount: item.infant,
            tourDate: moment(findTransferOption[0].tourDate).format(
              "YYYY-MM-DD"
            ),
            userId: user?.UserID ?? 1,
            roleType: user?.Role?.RoleId ?? 4,
            membership: user?.Membership ?? 1,
            apikey: findTransferOption[0].apikey,
          };

          data = {
            ...item,
            userId: user?.UserID ?? 1,
            roleType: user?.Role?.RoleId ?? 4,
            traceId: tourDetail.traceId,
            apikey: findTransferOption[0].apikey,
            tourId: findTransferOption[0].tourId,
            tourName: tourDetail?.tourName,
            cityTourType: tourDetail?.cityTourType,
            optionId: findTransferOption[0].tourOptionId,
            optionName: tourdetails.touroption.optionName,
            tourDate: moment(item.tourDate).format(oriDateFormat),
            timeSlotId: 0,
            startTime: findTransferOption[0].startTime,
            transferName: findTransferOption[0].transferName,
            adultRate: findTransferOption[0].adultPrice,
            childRate: findTransferOption[0].childPrice,
            infantRate: findTransferOption[0].infantPrice,
            agentMarkup: findTransferOption[0].agentMarkup,
            commission: findTransferOption[0].commission,
            adminCommission: findTransferOption[0].adminCommission,
            markup: findTransferOption[0].markup,
            serviceTotal: Number(total).toFixed(2),
            //cancallationDate hide  
            // cancellationDate: moment(item.tourDate)
            //   .subtract(1, "day")
            //   .format(oriDateFormat),
            cancellationDate: null,
            tourImage: tourDetail?.imagePath,
            duration: tourdetails.touroption.duration,
            tourShortDescription: tourDetail.tourShortDescription,
            calculateObj: calculateObj,
          };
        }
        return data;
      });

      if (selectedBuildActivities.length > 0) {
        let flatterArray = selectedBuildActivities.flat();

        if (flatterArray && flatterArray?.length > 0) {
          let duplicateTours = [];
          let newTours = [];
          tourListArray.forEach((item) => {
            if (
              flatterArray.filter(
                (selectedItem) =>
                  item.tourId === selectedItem.tourId &&
                  item.tourDate === selectedItem.tourDate &&
                  item.transferId === selectedItem.transferId &&
                  item.optionId === selectedItem.optionId
              ).length > 0
            ) {
              duplicateTours.push(item);
            } else {
              newTours.push(item);
            }
          });
          if (newTours.length > 0) {
            tourListArray = newTours;
          } else {
            message.error("This tour already exists in your cart.", 3);
            return;
          }
        }
      }

      let selectedTourListWithPrice = [];

      let errorList = [];
      for (let index = 0; index < tourListArray.length; index++) {
        let item = tourListArray[index];
        item.calculateObj.tourDate = item.tourDate;

        await ApiClient.post(
          "activities/tours/calculatedoptionsprice",
          item.calculateObj
        )
          .then((result) => result)
          .then((resp) => {
            if (resp.statusCode == 200) {
              let Price = 0;
              Price = Number(resp.data.finalAmount).toFixed(2);

              let resObj = item;
              delete resObj.calculateObj;
              resObj = {
                ...resObj,
                serviceTotal: Number(Price),
                // serviceTotal: Number(Math.ceil(Price)),
              };

              selectedTourListWithPrice.push(resObj);

              let i = tourAvlDate.indexOf(
                moment(item.tourDate).format("DD-MM-YYYY")
              );
              if (
                tourItenaryDate.hasOwnProperty(i) &&
                tourItenaryDate[i]?.length > 0
              ) {
                tourItenaryDate[i].push(resObj);
              } else {
                tourItenaryDate[i] = [resObj];
              }
            }
          })
          .catch((err) => {
            errorList.push(item);
          });
      }

      setSelectedBuildActivities(tourItenaryDate);
     
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * get time slot for tours
   */
  const getTimeSlot = (transferNames, index, touroption, traceId, price, commission) => {
    try {
      setTimeSlotModelData({});
      let transferId = 0;
      let totalpax = 0;
      let formData = tourForm.getFieldsValue();
      if (formData?.TourDetails?.length > 0) {
        tourForm.setFieldsValue({
          TourDetails: formData.TourDetails.map((item, key) =>
            key === index
              ? {
                ...item,
                isSelected: true,
              }
              : {
                ...item,
                isSelected: false,
              }
          ),
        });
        transferId = formData?.TourDetails[index].transferId;
        totalpax = formData?.TourDetails[index].adult + formData?.TourDetails[index].child;
        let findTransferOption = transferNames.filter(
          (opt) => opt.transferId === transferId
        );

        setTimeSlotModelData({
          ...formData?.TourDetails[index],
          infantPrice: findTransferOption[0]?.infantPrice,
          price: price,
          commission: commission
        });
      }

      let ReqObj = {
        traceId: tourDetail.traceId,
        tourOptionId: transferNames[0].tourOptionId,
        transferId: transferId,
        apikey: transferNames[0].apikey,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
        tourId: transferNames[0].tourId,
        totalpax: totalpax
      };
      FetchTimeSlot(ReqObj);
    } catch (error) { }
  };

  const FetchTimeSlot = async (ReqObj) => {
    setTimeSlotLoading(true);
    setTimeSlotModelVisible(true);
    setSelectedTimeSlot({});
    setTourTimeSlot([]);
    await ApiClient.post("activities/tours/options/timeslot", ReqObj)
      .then((result) => result)
      .then((resp) => {
        if (resp.statusCode == 200 && resp?.data) {
          setTourTimeSlot(resp.data);
        } else {
          setTourTimeSlot([]);
        }
        setTimeSlotLoading(false);
      })
      .catch((error) => {
        setTourTimeSlot([]);
      });
  };

  const handleTimeSlotCheckout = () => {
    try {

      if (Object.keys(selectedTimeSlot).length > 0) {
        let formData = tourForm.getFieldsValue();

        let selectedTourListWithSlot = formData.TourDetails.filter(
          (item) => item.isSelected === true && item.isSlot === true
        );

        if (selectedTourListWithSlot.length <= 0) {
          return;
        }

        let selectedTourList = [selectedTourListWithSlot[0]];

        let dynamicAdultPrice = selectedTimeSlot.adultPrice;
        let dynamicChildPrice = selectedTimeSlot.childPrice;
        let dynamicAgentMarkupPrice = selectedTimeSlot.agentMarkup;
        let dynamicCommissionPrice = selectedTimeSlot.commission;
        let dynamicAdminCommissionPrice = selectedTimeSlot.adminCommission;
        let dynamicMarkupPrice = selectedTimeSlot.markup;

        selectedTourList = selectedTourList.map((item) => {
          let tourdetails = tourOptionsDataList[item.tourIndex];
          let findTransferOption = tourdetails.transferNames.filter(
            (opt) => opt.transferId === item.transferId
          );

          let adultPrice = selectedTimeSlot.isDynamicPrice
            ? dynamicAdultPrice
            : findTransferOption[0].adultPrice;
          let childPrice = selectedTimeSlot.isDynamicPrice
            ? dynamicChildPrice
            : findTransferOption[0].childPrice;
          // let total =  /// it was not woking with slot time , on cart it is addtiong wrong calculation 
          //   item.adult * adultPrice +
          //   item.child * childPrice +
          //   item.infant * findTransferOption[0].infantPrice;
          let total = timeSlotModelData.price
          let agentMarkup = selectedTimeSlot.isDynamicPrice
            ? dynamicAgentMarkupPrice
            : findTransferOption[0].agentMarkup;

          let commission = activitycommission;
          // selectedTimeSlot.isDynamicPrice
          //   ? dynamicCommissionPrice
          //   : findTransferOption[0].commission;

          let adminCommission = selectedTimeSlot.isDynamicPrice
            ? dynamicAdminCommissionPrice
            : findTransferOption[0].adminCommission;

          let markup = selectedTimeSlot.isDynamicPrice
            ? dynamicMarkupPrice
            : findTransferOption[0].markup;

          let calculateObj = {
            traceId: tourDetail.traceId,
            tourId: findTransferOption[0].tourId,
            tourOptionId: findTransferOption[0].tourOptionId,
            transferId: findTransferOption[0].transferId,
            adultCount: item.adult,
            childCount: item.child,
            infantCount: item.infant,
            tourDate: moment(findTransferOption[0].tourDate).format(
              "YYYY-MM-DD"
            ),
            userId: user?.UserID ?? 1,
            roleType: user?.Role?.RoleId ?? 4,
            membership: user?.Membership ?? 1,
            apikey: findTransferOption[0].apikey,
          };

          return {
            ...item,
            userId: user?.UserID ?? 1,
            roleType: user?.Role?.RoleId ?? 4,
            traceId: tourDetail.traceId,
            apikey: findTransferOption[0].apikey,
            tourId: findTransferOption[0].tourId,
            tourName: tourDetail?.tourName,
            cityTourType: tourDetail?.cityTourType,
            optionId: findTransferOption[0].tourOptionId,
            optionName: tourdetails.touroption.optionName,
            tourDate: moment(item.tourDate).format(oriDateFormat),
            timeSlotId: selectedTimeSlot.timeSlotId,
            startTime: selectedTimeSlot.timeSlot,
            transferName: findTransferOption[0].transferName,
            adultRate: adultPrice,
            childRate: childPrice,
            infantRate: findTransferOption[0].infantPrice,
            agentMarkup,
            commission,
            adminCommission,
            markup,
            serviceTotal: Number(total).toFixed(2),
            //cancallationDate hide  
            // cancellationDate: moment(item.tourDate)
            //   .subtract(1, "day")
            //   .format(oriDateFormat),
            cancellationDate: null,
            tourImage: tourDetail?.imagePath,
            duration: tourdetails.touroption.duration,
            tourShortDescription: tourDetail.tourShortDescription,
            calculateObj: calculateObj,
          };
        });

        if (isfromPackage) {
          storeSelectedActivities(selectedTourList, true);
          setTimeSlotModelVisible(false);
          setSelectedTimeSlot({});
          setTourTimeSlot([]);
        } else {
          activitiesAddToCart({
            searchRequest: searchReq,
            TourDetails: selectedTourList,
          });
          setTimeSlotModelVisible(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addActivityInCart = async (value) => {
    try {
      let formData = value.TourDetails;

      let selectedTourListWithSlot = formData.filter(
        (item) => item.isSelected === true && item.isSlot === true
      );

      if (selectedTourListWithSlot.length > 0) {
        getTimeSlot(
          tourOptionsDataList[selectedTourListWithSlot[0].tourIndex]
            ?.transferNames,
          selectedTourListWithSlot[0].tourIndex,
          tourOptionsDataList[selectedTourListWithSlot[0].tourIndex].touroption,
          tourOptionsDataList[selectedTourListWithSlot[0].tourIndex].traceId,
          tableTotalPrice[selectedTourListWithSlot[0].tourIndex].finalAmount
        );

        return;
      }

      let selectedTourList = formData.filter(
        (item) => item.isSelected === true
      );

      if (selectedTourList.length < 1) {
        message.error("Please select Tours", 4);
        return;
      }

      selectedTourList = selectedTourList.map((item) => {
        let tourdetails = tourOptionsDataList[item.tourIndex];
        let findTransferOption = tourdetails.transferNames.filter(
          (opt) => opt.transferId === item.transferId
        );

        let total =
          item.adult * findTransferOption[0].adultPrice +
          item.child * findTransferOption[0].childPrice +
          item.infant * findTransferOption[0].infantPrice;
        // let total = findTransferOption[0].adultPrice;

        let calculateObj = {
          traceId: tourDetail.traceId,
          tourId: findTransferOption[0].tourId,
          tourOptionId: findTransferOption[0].tourOptionId,
          transferId: findTransferOption[0].transferId,
          adultCount: item.adult,
          childCount: item.child,
          infantCount: item.infant,
          tourDate: moment(findTransferOption[0].tourDate).format("YYYY-MM-DD"),
          userId: user?.UserID ?? 1,
          roleType: user?.Role?.RoleId ?? 4,
          membership: user?.Membership ?? 1,
          apikey: findTransferOption[0].apikey,
        };
        let date = new Date(findTransferOption[0].startTime);
        const hours = String(date.getUTCHours()).padStart(2, "0");
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
        const startTime = `${hours}:${minutes}`;

        return {
          ...item,
          userId: user?.UserID ?? 1,
          roleType: user?.Role?.RoleId ?? 4,
          traceId: tourDetail.traceId,
          apikey: findTransferOption[0].apikey,
          tourId: findTransferOption[0].tourId,
          tourName: tourDetail?.tourName,
          cityTourType: tourDetail?.cityTourType,
          optionId: findTransferOption[0].tourOptionId,
          optionName: tourdetails.touroption.optionName,
          tourDate: moment(item.tourDate).format(oriDateFormat),
          timeSlotId: 0,
          startTime: findTransferOption[0].apikey == "EXT" ? startTime : findTransferOption[0].startTime,
          transferName: findTransferOption[0].transferName,
          adultRate: findTransferOption[0].adultPrice,
          childRate: findTransferOption[0].childPrice,
          infantRate: findTransferOption[0].infantPrice,
          agentMarkup: findTransferOption[0].agentMarkup,
          commission: findTransferOption[0].commission,
          adminCommission: findTransferOption[0].adminCommission,
          markup: findTransferOption[0].markup,
          serviceTotal: Number(total).toFixed(2),
          //cancallationDate hide  
          // cancellationDate: moment(item.tourDate)
          //   .subtract(1, "day")
          //   .format(oriDateFormat),
          cancellationDate: null,
          tourImage: tourDetail?.imagePath,
          duration: tourdetails.touroption.duration,
          tourShortDescription: tourDetail.tourShortDescription,
          calculateObj: calculateObj,
        };
      });

      let selectedTourListWithPrice = [];

      let errorList = [];
      for (let index = 0; index < selectedTourList.length; index++) {
        let item = selectedTourList[index];
        item.calculateObj.tourDate = item.tourDate;
        
        await ApiClient.post(
          "activities/tours/calculatedoptionsprice",
          item.calculateObj
        )
          .then((result) => result)
          .then((resp) => {
            if (resp.statusCode == 200) {
              let Price = 0;
              Price = Number(resp.data.finalAmount).toFixed(2);

              let resObj = item;

              delete resObj.calculateObj;
              resObj = {
                ...resObj,
                serviceTotal: Number(Price),
              }
              let commissions = Number(Math.ceil(resp.data.commission));
              resObj.commission = commissions;
              selectedTourListWithPrice.push(resObj);
            }
          })
          .catch((err) => {
            errorList.push(item);
          });
      }

      activitiesAddToCart({
        searchRequest: searchReq,
        TourDetails: selectedTourListWithPrice,
      });
    } catch (error) {
      console.log("addactivityCatch", error);
    }
  };

  // const activitiesAddToCart = (req) => {
  //   if (maxPax != true) {

  //     if (user && user?.UserID) {
  //       ApiClient.post("activities/tours/addtocart", req)
  //         .then((result) => result)
  //         .then((resp) => {
  //           if (resp.statusCode == 200) {
  //             message.success("Cart Add Successfully")
              
  //             getActivitiesByUserId(user?.UserID)
  //             // history.push("/activities/cart");

  //           } else if (resp.statusCode == 409) {
  //             if (resp?.message) {
  //               message.error(resp.message);
  //             }
  //           }
  //         })
  //         .catch((err) => { });
  //     } else {
  //       if (activitiesCart && Object.keys(activitiesCart).length > 0) {
  //         let filterReq = {
  //           searchRequest: req.searchRequest,
  //           TourDetails: [],
  //         };
  //         req?.TourDetails.forEach((newTour, index) => {
  //           let matchingTour = activitiesCart?.TourDetails.filter(
  //             (selectedTour) =>
  //               selectedTour.optionId == newTour.optionId &&
  //               selectedTour.transferId == newTour.transferId &&
  //               selectedTour.tourDate == newTour.tourDate
  //           );

  //           if (matchingTour.length === 0) {
  //             filterReq.TourDetails.push(req.TourDetails[index]);
  //           }
  //         });

  //         if (filterReq.TourDetails.length > 0) {
  //           setActivitiesCart((prev) => ({
  //             ...prev,
  //             searchRequest: filterReq.searchRequest,
  //             TourDetails: [...prev.TourDetails, ...filterReq.TourDetails],
  //           }));
  //           if (activitiesCart && Object.keys(activitiesCart).length > 0) {
  //             setCartData(activitiesCart);
  //             message.success("Cart Add Successfully")
           
  //           }
  //           // history.push("/activities/cart");
  //         } else {
  //           message.error("Already added to cart", 3);
  //         }
  //       } else {
  //         setActivitiesCart(req);
  //         if (activitiesCart && Object.keys(activitiesCart).length > 0) {
  //           setCartData(activitiesCart);
  //         }
  //         //  history.push("/activities/cart");
  //       }
  //     }
  //   } else {
  //     message.error(`Your Total Selected Pax Out Of Range`);
  //   }
  // }

  const activitiesAddToCart = (req) => {
    if(maxPax != true) {
      if (activitiesLocalCart && Object.keys(activitiesLocalCart).length > 0) {
        let filterReq = {
          searchRequest: req.searchRequest,
          TourDetails: [],
        };
        req?.TourDetails.forEach((newTour, index) => {
          let matchingTour = activitiesLocalCart?.TourDetails.filter(
            (selectedTour) =>
              selectedTour.optionId == newTour.optionId &&
              selectedTour.transferId == newTour.transferId &&
              selectedTour.tourDate == newTour.tourDate
          );

          if (matchingTour.length === 0) {
            filterReq.TourDetails.push(req.TourDetails[index]);
          }
        });

        if (filterReq.TourDetails.length > 0) {
          setActivitiesLocalCart((prev) => ({
            ...prev,
            // searchRequest: filterReq.searchRequest,
            TourDetails: [...prev.TourDetails, ...filterReq.TourDetails],
          }));
          if (activitiesLocalCart && Object.keys(activitiesLocalCart).length > 0) {
            setCartData(activitiesLocalCart);
            message.success("Cart Add Successfully")
         
          }
        } else {
          message.error("Already added to cart", 3);
        }
      } else {
        // Add a expiry Property
        // Object.defineProperty(req, "expiry", {value: new Date().getTime() + 45 * 60 * 1000})
        setActivitiesLocalCart({
          ...req,
          expiry: new Date().getTime() + 45 * 60 * 1000
        });
        if (activitiesLocalCart && Object.keys(activitiesLocalCart).length > 0) {
          setCartData(activitiesLocalCart);
        }
      }
    }
  }

  const checkPackage = () => {
    if (tourDetail?.cityTourType?.includes("TRIO")) {
      setIsTrio(true);
    }
  }



  useEffect(() => {
    checkPackage();
  }, []);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  return (
    <Row className="new-sec-1key des-row-key">
      <Col md={24} xs={24}>
        {isfromPackage ? null : (
          <h3 className="deatils-key" id="rates-tour">
            Tour Rates
          </h3>
        )}
        {isOptionDataLoding == false ? (
          <Form
            className="activitiestourform"
            layout="vertical"
            name="tourRatesForm"
            form={tourForm}
            onFinish={(val) => {
              if (isfromPackage) {
                onSubmitSelectedActivity(val);
              } else {
                addActivityInCart(val);
              }
            }}
            initialValues={{
              TourDetails: tourOptionsDataList.map((item, index) => ({
                tourIndex: index,
                isSelected: false,
                isSlot: item.transferNames[0]?.isSlot,
                adult: 1,
                child: 0,
                infant: 0,
                optionName: item?.touroption?.optionName,
                tourDate: item?.transferNames[0]?.tourDate,
                transferId: item.transferNames[0]?.transferId,
              })),
            }}
          >
            <Table
              responsive="lg"
              className="groupfrom "
              striped
              bordered
              hover
            >
              <thead>
                <tr>
                  <th>Select Your Option</th>
                  {tourOptionsDataList[0]?.transferNames[0]?.transferName === "Quantity" ? null :
                    <th>Transfer Option</th>
                  }
                  <th>Tour Date</th>

                  {/* <th>{(hourtourList?.filter((x) => x.TourId == tourOptionsDataList[0]?.touroption?.tourId).length > 0 ? 'Hour' : 'Adult') || (tourOptionsDataList[0].transferNames[0].transferName === "Quantity" ? null : 'QTY')}</th> */}
                  {(() => {
                    if (hourtourList?.filter((x) => x.TourId == tourOptionsDataList[0]?.touroption?.tourId).length > 0)
                      return <th>Hour</th>
                    else if (tourOptionsDataList[0]?.transferNames[0]?.transferName === "Quantity")
                      return <th>Quantity</th>
                    else
                      return <th>Adult</th>
                  })()}
                  <>
                    {/* {hourtourList?.filter((x) => x.TourId == tourOptionsDataList[0]?.touroption?.tourId).length > 0 ? null : ( */}
                    {(() => {
                      if (hourtourList?.filter((x) => x.TourId == tourOptionsDataList[0]?.touroption?.tourId).length > 0 || tourOptionsDataList[0]?.transferNames[0]?.transferName === "Quantity")
                        return null;
                      else {
                        return (
                          <>
                            <th>
                              Child
                              <span style={{ fontSize: "10px" }}>
                                ( {tourOptionsDataList[0]?.touroption?.childAge} )
                              </span>
                            </th>
                            <th>
                              Infant
                              <span style={{ fontSize: "10px" }}>
                                ( {tourOptionsDataList[0]?.touroption?.infantAge} )
                              </span>
                            </th>
                          </>
                        )
                      }
                    })()}
                    {/* <>
                      <th>
                        Child
                        <span style={{ fontSize: "10px" }}>
                          ( {tourOptionsDataList[0]?.touroption?.childAge} )
                        </span>
                      </th>
                      <th>
                        Infant
                        <span style={{ fontSize: "10px" }}>
                          ( {tourOptionsDataList[0]?.touroption?.infantAge} )
                        </span>
                      </th>
                    </> */}
                    {/* )} */}
                  </>

                  {/* : null} */}
                  {!IsTrio && (<th>Price</th>)}
                </tr>
              </thead>
              <tbody>
                <Form.List name="TourDetails">
                  {(fields) => (
                    <>
                      {fields.map((field, index) => (
                        <tr key={"tourRateForm-row" + index}>
                          <td className="option_Name crossmark-option">
                            <Row gutter={16}>
                              <Col md={22} xs={2}>
                                <Form.Item
                                  {...field}
                                  name={[index, "isSelected"]}
                                  valuePropName="checked"
                                  className="font-weight-bold"
                                >
                                  <Checkbox defaultChecked={true}>
                                    {
                                      tourOptionsDataList[index]?.touroption
                                        ?.optionName
                                    }
                                  </Checkbox>
                                </Form.Item>
                              </Col>

                              <Col md={2} xs={24}>
                                <Tooltip
                                  placement="topLeft"
                                  overlayClassName="activityInfoTooltip"
                                  title={
                                    <span>
                                      {
                                        tourOptionsDataList[index]?.touroption
                                          ?.optionDescription
                                      }
                                    </span>
                                  }
                                >
                                  <InfoCircleOutlined />
                                </Tooltip>
                              </Col>
                              <p className="slot-graph-modal">
                                {tourOptionsDataList[index].transferNames[0]
                                  ?.isSlot == true ? (
                                  <Button
                                    className="select-time-fun"
                                    variant="link"
                                    onClick={() =>
                                      getTimeSlot(
                                        tourOptionsDataList[index]
                                          .transferNames,
                                        index,
                                        tourOptionsDataList[index].touroption,
                                        tourOptionsDataList[index].traceId,
                                        tableTotalPrice[index].finalAmount,
                                        activitycommission
                                      )
                                    }
                                  >
                                    Select Time Slot
                                  </Button>
                                ) : null}
                              </p>
                            </Row>
                          </td>
                          {tourOptionsDataList[0]?.transferNames[0]?.transferName === "Quantity" ? null :
                            <td className="transfer_option">
                              <Form.Item {...field} name={[index, "transferId"]}>
                                <Select
                                  style={{ width: "100%" }}
                                  defaultActiveFirstOption={true}
                                  defaultValue={
                                    tourOptionsDataList[index].transferNames[0]
                                      ?.transferId
                                  }
                                  onSelect={() =>
                                    getTotalPrice(
                                      tourOptionsDataList[index].transferNames,
                                      index,
                                      tourOptionsDataList[index].touroption,
                                      tourOptionsDataList[index].traceId,
                                      true,
                                    )
                                  }
                                >
                                  {tourOptionsDataList[index].transferNames.map(
                                    (op, i) => (
                                      <Option
                                        key={"tranferOption" + i}
                                        value={op.transferId}
                                      >
                                        {op.transferName}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </td>
                          }
                          <td className="selectDate">
                            <Form.Item
                              {...field}
                              name={[index, "tourDate"]}
                            // rules={[
                            //   {
                            //     required: tourForm.getFieldValue(
                            //       "TourDetails"
                            //     )[index].isSelected
                            //       ? tourForm.getFieldValue("TourDetails")[
                            //           index
                            //         ].tourDate
                            //         ? false
                            //         : true
                            //       : false,
                            //     message: "Required",
                            //   },
                            // ]}
                            >
                              <DatePicker
                                allowClear={false}
                                defaultValue={TravelDate}
                                format={dateFormat}
                                disabledDate={(currentDate) =>
                                  disabledOriginDate(
                                    currentDate,
                                    tourOptionsDataList[index]?.operationdays,
                                    tourOptionsDataList[index]?.transferNames[0]
                                      ?.tourDate
                                  )
                                }
                                disabledWeekDays={[0, 3]}
                                onChange={() =>
                                  getTotalPrice(
                                    tourOptionsDataList[index].transferNames,
                                    index,
                                    tourOptionsDataList[index].touroption,
                                    tourOptionsDataList[index].traceId,
                                    true
                                  )
                                }
                              />
                            </Form.Item>
                          </td>
                          {/* {console.log(maximumPax[index])} */}
                          <td className="build-pkg-td">
                            <Form.Item {...field} name={[index, "adult"]}>
                              <Select
                                style={{ width: "100%" }}
                                defaultActiveFirstOption={true}
                                defaultValue={1}
                                onSelect={() => {
                                  getTotalPrice(
                                    tourOptionsDataList[index].transferNames,
                                    index,
                                    tourOptionsDataList[index].touroption,
                                    tourOptionsDataList[index].traceId,
                                    false
                                  );
                                }}
                              >
                                {[...Array(tourOptionsDataList[index].transferNames[0].apikey != "EXT" ? 500 : tourOptionsDataList[index]?.transferNames[0].minimumPax)].map((_, i) => (
                                  <Option
                                    key={"adultCountKey" + i}
                                    value={i + 1}
                                  >
                                    {i + 1}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </td>
                          {/* File Not Merge At Time Of Push On Server --27/April/2023  */}
                          {/* {hourtourList?.filter((x) => x.TourId == tourOptionsDataList[0]?.touroption?.tourId).length > 0 ? null : */}
                          {(() => {
                            if (hourtourList?.filter((x) => x.TourId == tourOptionsDataList[0]?.touroption?.tourId).length > 0 || tourOptionsDataList[0]?.transferNames[0]?.transferName === "Quantity")
                              return null;
                            else {
                              return (
                                <>
                                  <td className="build-pkg-td">
                                    <Form.Item {...field} name={[index, "child"]}>
                                      <Select
                                        style={{ width: "100%" }}
                                        defaultActiveFirstOption={true}
                                        defaultValue={0}
                                        onSelect={() =>
                                          getTotalPrice(
                                            tourOptionsDataList[index].transferNames,
                                            index,
                                            tourOptionsDataList[index].touroption,
                                            tourOptionsDataList[index].traceId,
                                            false
                                          )
                                        }
                                      >
                                        {[...Array(tourOptionsDataList[index].transferNames[0].apikey != "EXT" ? 500 : TotalPax + 1)].map((_, i) => (
                                          <Option key={"childCountKey" + i} value={i}>
                                            {i}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                  <td className="build-pkg-td">
                                    <Form.Item {...field} name={[index, "infant"]}>
                                      <Select
                                        style={{ width: "100%" }}
                                        defaultActiveFirstOption={true}
                                        defaultValue={0}
                                        onSelect={() =>
                                          getTotalPrice(
                                            tourOptionsDataList[index].transferNames,
                                            index,
                                            tourOptionsDataList[index].touroption,
                                            tourOptionsDataList[index].traceId,
                                            false
                                          )
                                        }
                                      >
                                        {[...Array(tourOptionsDataList[index].transferNames[0].apikey != "EXT" ? 500 : TotalPax + 1)].map((_, count) => (
                                          <Option
                                            key={"infantCountKey" + count}
                                            value={count}
                                          >
                                            {count}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </td>
                                </>
                              )
                            }
                          })()}
                          {/* <>
                            <td className="build-pkg-td">
                              <Form.Item {...field} name={[index, "child"]}>
                                <Select
                                  style={{ width: "100%" }}
                                  defaultActiveFirstOption={true}
                                  defaultValue={1}
                                  onSelect={() =>
                                    getTotalPrice(
                                      tourOptionsDataList[index].transferNames,
                                      index,
                                      tourOptionsDataList[index].touroption,
                                      tourOptionsDataList[index].traceId
                                    )
                                  }
                                >
                                  {[...Array(501)].map((_, i) => (
                                    <Option key={"childCountKey" + i} value={i}>
                                      {i}
                                    </Option>
                                  ))}
                                  {/* {[0, 1, 2, 3, 4, 5].map((count) => (
                                  <Option
                                    key={"childCountKey" + count}
                                    value={count}
                                  >
                                    {count}
                                  </Option>
                                ))} 
                                </Select>
                              </Form.Item>
                            </td>
                            <td className="build-pkg-td">
                              <Form.Item {...field} name={[index, "infant"]}>
                                <Select
                                  style={{ width: "100%" }}
                                  defaultActiveFirstOption={true}
                                  defaultValue={0}
                                  onSelect={() =>
                                    getTotalPrice(
                                      tourOptionsDataList[index].transferNames,
                                      index,
                                      tourOptionsDataList[index].touroption,
                                      tourOptionsDataList[index].traceId
                                    )
                                  }
                                >
                                  {[0, 1].map((count) => (
                                    <Option
                                      key={"infantCountKey" + count}
                                      value={count}
                                    >
                                      {count}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </td>
                          </> */}
                          {/* } */}
                          {/* File Not Merge At Time Of Push On Server --27/April/2023 */}
                          {!IsTrio && (
                            <td className="build-pkg-td-last">
                              <Row gutter={8}>
                                <Col md={18} xs={18}>
                                  {/* <span>  {activeCurrency} {currencyValue( tableTotalPrice[index])}</span> */}
                                  <span>  {activeCurrency} {currencyValue(tableTotalPrice[index].finalAmount)}</span>
                                </Col>
                                <Col md={6} xs={6}>
                                  <InfoCircleOutlined
                                    onClick={() =>
                                      setBookingPolicyAndFareBreakup(
                                        tourOptionsDataList[index],
                                        index
                                      )
                                    }
                                  />
                                </Col>
                              </Row>
                            </td>
                          )}
                        </tr>
                      ))}
                    </>
                  )}
                </Form.List>
              </tbody>
            </Table>

            <Modal
              title="Rate Breakup & Booking Policy"
              centered
              visible={visible}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
              width={1000}
            >
              <Collapse defaultActiveKey={["1"]}>
                <Panel header="Your Price Quote Details" key="1">
                  <Table
                    responsive="xl"
                    className="groupfrom "
                    striped
                    bordered
                    hover
                  >
                    <thead>
                      <tr>
                        Your Guarantee lowest price for this option is based on
                        the following:
                      </tr>
                      <tr>
                        <th>Tour Option</th>
                        <th>Transfer Option</th>
                        <th>Adult Price</th>
                        <th>Child Price</th>
                        <th>Infant Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{RateBookingModalData?.optionName}</td>
                        <td>{RateBookingModalData?.transferName}</td>
                        <td>
                          {RateBookingModalData?.adultPrice} X{" "}
                          {RateBookingModalData?.adult}
                        </td>
                        <td>
                          {RateBookingModalData?.childPrice} X{" "}
                          {RateBookingModalData?.child}
                        </td>
                        <td>
                          {RateBookingModalData?.infantPrice} X{" "}
                          {RateBookingModalData?.infant}
                        </td>
                        <td> ₹ {RateBookingModalData?.total}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Panel>
                <Panel header="Booking Policy" key="2">
                  <h5>Cancellation Policy</h5>
                  <p>{RateBookingModalData.cancellationPolicy}</p>
                  <pre>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          RateBookingModalData?.cancellationPolicyDescription,
                      }}
                    ></div>
                  </pre>
                  <h5>Child Policy</h5>
                  <pre>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: RateBookingModalData?.childPolicyDescription,
                      }}
                    ></div>
                  </pre>
                </Panel>
              </Collapse>
            </Modal>

            <div className="emailflyer">
              {priceLoading ? (
                <div>
                  <Button className="flyerbtn-1 item-activity" disabled>
                    <Spin indicator={antIcon} />
                  </Button>
                </div>
              ) : isfromPackage ? (
                <div>
                  <Button
                    className="flyerbtn-1 item-activity"
                    data-toggle="modal"
                    data-target="#myBuildActivitiesModal"
                    onClick={() => tourForm.submit()}
                  >
                    Add Activities To Package
                  </Button>
                </div>
              ) : (
                <div>
                  <Button 
                  className="flyerbtn-1 item-activity"
                  data-toggle="modal"
                  data-target="#showMyCart"
                   
                    onClick={() => tourForm.submit()}
                  >
                    Add To cart
                  </Button>
                </div>
              )}
            </div>
          </Form>
        ) : (
          <Skeleton active />
        )}
      </Col>

      <Modal
        title="Time Slot"
        centered
        visible={timeSlotModelVisible}
        onOk={() => setTimeSlotModelVisible(false)}
        onCancel={() => setTimeSlotModelVisible(false)}
        width={900}
        className="slot-modal-nav"
      >
        <div className="timeSlotModal-wrapper">
          <div className="tourModal-wrapper">
            <h5>{tourDetail?.cityTourType}</h5>
            <h6>{timeSlotModelData?.optionName}</h6>
            <h6>
              Tour Date:{" "}
              {timeSlotModelData?.tourDate
                ? moment(timeSlotModelData?.tourDate, oriDateFormat).format(
                  dateFormat
                )
                : ""}
            </h6>
            <h6>{getPaxCount(timeSlotModelData)}</h6>
          </div>

          {timeSlotLoading ? (
            <Skeleton active className="p-2" />
          ) : tourTimeSlot.length > 0 ? (
            <>
              <Row className="select-slot-time">
                <Col md={24} xs={24}>
                  <h4>Select Time Options</h4>
                </Col>
              </Row>

              <Row className="select-slot-slod-out">
                <Col md={8} xs={24}>
                  <span>
                    <i className="fa fa-circle av-1" aria-hidden="true"></i>{" "}
                    Available
                  </span>
                </Col>
                <Col md={8} xs={24}>
                  <span>
                    <i className="fa fa-circle av-2" aria-hidden="true"></i>{" "}
                    Sold Out
                  </span>
                </Col>
                <Col md={8} xs={24}>
                  <span>
                    <i className="fa fa-circle av-3" aria-hidden="true"></i>{" "}
                    Limited Availability
                  </span>
                </Col>
              </Row>

              <Row className="select-slot-date-map">
                {tourTimeSlot.map((timeData, index) => {
                  return timeData.available === 0 ? (
                    <Col md={4} xs={24} key={"timeSlot" + index}>
                      <div className="one-slot-book">
                        <p className="time-slot-unavailable">
                          {timeData.timeSlot} ({timeData.available})
                        </p>
                        {(!tourTimeSlot[0]?.hasOwnProperty('apikey') || tourTimeSlot[0]?.apikey !== "EXT") && timeData.isDynamicPrice && (
                          <div className="priceWrapper">
                            <p className="data-adult">
                              Adult : Rs   {activeCurrency} {currencyValue(timeData.adultPrice)}
                            </p>
                            <p>Child : Rs   {activeCurrency} {currencyValue(timeData.childPrice)}</p>
                          </div>
                        )}
                      </div>
                    </Col>
                  ) : (
                    <Col md={4} xs={24}>
                      <div
                        className={`one-slot-book ${selectedTimeSlot?.timeSlotId === timeData.timeSlotId
                          ? "selected"
                          : ""
                          }`}
                      >
                        <p
                          className={`${timeData.available <= 10
                            ? "time-slot-limited"
                            : "time-slot-available"
                            }`}
                          onClick={() => {
                            setSelectedTimeSlot(timeData);
                            // setSelectedTimeSlot({
                            //   adultPrice: 400,
                            //   available: 99,
                            //   childPrice: 200,
                            //   isDynamicPrice: true,
                            //   timeSlot: "11:00",
                            //   timeSlotId: "1206",
                            // });
                          }}
                        >
                          {timeData.timeSlot} ({timeData.available})
                        </p>
                        {(!tourTimeSlot[0]?.hasOwnProperty('apikey') || tourTimeSlot[0]?.apikey !== "EXT") &&  timeData.isDynamicPrice && (
                          <div className="priceWrapper">
                            <p className="data-adult">
                              Adult : Rs{" "}
                              {activeCurrency} {currencyValue(Number(timeData.adultPrice).toFixed(2))}
                            </p>
                            <p>
                              Child : Rs{" "}
                              {activeCurrency} {currencyValue(Number(timeData.childPrice).toFixed(2))}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>

              <Row className="total-slot-bbok">
                <Col md={12} xs={24}>
                  <p>
                    Total:{" "}
                    <span className="slot-span">
                      {/* ₹ {getDynamicPrice(timeSlotModelData)}₹{" "} */}
                      {activeCurrency} {currencyValue(timeSlotModelData.price)}
                    </span>
                  </p>
                </Col>
                <Col
                  md={12}
                  xs={24}
                  data-toggle={isfromPackage ? "modal" : ""}
                  data-target={isfromPackage ? "#myBuildActivitiesModal" : ""}
                  className="slot-check-out"
                >
                  <Button data-toggle="modal" data-target="#showMyCart" type="button"  className="slot-btn flyerbtn-1 item-activity" onClick={handleTimeSlotCheckout}>
                    PROCEED TO CHECKOUT
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <p className="font-weight-bold text-center p-2">
              Sorry No Slot(s) Available
            </p>
          )}
        </div>
      </Modal>
    </Row>
  );
};

export default ActivitiesSelectpkg;