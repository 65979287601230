import React from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import { useSelector } from "react-redux";
import SeoMetaData from "../../common/Seo/SeoMetaData";

const Privacypolicy = () => {
  return (
    <React.Fragment>
        <SeoMetaData path={window.location.pathname} />
      <CmsPages cmsId={6} pageTitle="Privacy Policy" />;
    </React.Fragment>
  )
};

export default Privacypolicy;
