import React from "react";
// import './RoomDetails.scss'
import { Card, Tooltip, Carousel, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
// import "./RoomDetails.scss"

const RoomDetailsCard = ({ RoomDetails }) => {

    // access Currency method
    const { activeCurrency, currencyValue } = useCurrencyContext();

    const cancellationTableStyle = {
        border: "1px solid #fff"
    }

    // Define month names
    const Month = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // validate all date 
    const dateFormating = (date) => {
        let day, monthNum, year;
        if (date.includes('-')) {
            [day, monthNum, year] = date?.split('-');
        } else if (date.includes('/')) {
            [monthNum, day, year] = date?.split('/');
        } else {
            console.error('Invalid date format: ' + date);
        }
        const [currentYear, time] = year?.split(" ");
        const monthName = Month[parseInt(monthNum) - 1];
        const formattedDate = `${day}/${monthName}/${currentYear}`;
        return formattedDate;
    }

    // Cancelation Policy
    const cancellationPolicyTable = (ratePlan) => {
        return (
            <table className="cancellationDetails text-center w-100">
                <thead>
                    <tr>
                        <th style={cancellationTableStyle}>Cancelled on or After</th>
                        <th style={cancellationTableStyle}>Cancelled on or Before</th>
                        <th style={cancellationTableStyle}>Cancellation Charges</th>
                    </tr>
                </thead>
                <tbody>
                    {ratePlan.cancellationPolicy.length > 0 && ratePlan.cancellationPolicy.map((cancellationDate, i) => {
                        return (
                            <tr className={`cancellationDate${i}`} style={cancellationTableStyle}>
                                <td>{dateFormating(cancellationDate?.fromDate)}</td>
                                <td>{dateFormating(cancellationDate?.toDate)}</td>
                                <td>{parseInt(cancellationDate.penaltyAmount) <= 100 ? `${cancellationDate.penaltyAmount}%` : `${currencyValue(cancellationDate.penaltyAmount)} ${activeCurrency}`}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    };

    const getAllInclusions = (inclusions) => {
        let inclusion = inclusions.split(",");
        if (inclusion.length > 2) {
            return (
                <Tooltip 
                className="pe-auto"
                    title={inclusion.map((i, key) => (
                        <p key={key} className="mb-0">
                            {i}
                        </p>
                    ))}
                >
                    <a className="ml-auto text-info pe-auto">Show More</a>
                </Tooltip>
            );
        }
    }

    return (
        <Card>
            {RoomDetails.rooms && RoomDetails.rooms.length > 0 ? (
                RoomDetails.rooms.map((room, i) => (
                    <React.Fragment key={i}>
                        <h6 className="text-danger"><b>Room {i + 1}</b></h6>
                        <div className="room-main-section border d-lg-flex w-100 justify-content-start border-danger p-3 my-3" key={i}>
                            {RoomDetails.hasOwnProperty('images') && RoomDetails?.images.length > 0 ? (
                                <Carousel autoplay arrows={false} autoplaySpeed={2000} dots={false} className="room-image my-1 w-md-100" style={{ maxWidth: "150px" }}>
                                    {RoomDetails.images.map((image, i) => (
                                        <img src={image} alt="" height={125} width={100} />
                                    ))}
                                </Carousel>
                            ) :
                                <div className="not-found">
                                    <img src="https://resource.rentcafe.com/image/upload/q_auto,f_auto,c_limit,w_576,h_260/s3/2/50552/image%20not%20available%20-%20all%20properties%20-%20set%201.png" alt="" height={100} width={100} />
                                </div>
                            }
                            <div className="room-wrap d-block w-100 mx-0 px-3">
                                <div className="room-details m-0 d-block">
                                    <div className="room-title">
                                        <p className="roomName font-weight-bold">{room.roomName}</p>
                                    </div>
                                    <div className="cancelation-policy text-danger">
                                        {/* <Tooltip title="prompt text"> */}
                                        <p className="roomName font-weight-bold">Last Cancellation Date : {moment(room.ratePlans[0].lastCancellationDate).format('DD/MM/YYYY')}</p>
                                        {/* </Tooltip> */}
                                        {console.log(room)}
                                    </div>
                                </div>

                                {room.ratePlans.map((ratePlans, i) => (
                                    <React.Fragment>
                                        {/* Inclusions */}
                                        {ratePlans.amenities && ratePlans.amenities.length > 0 && (
                                            <div className="inclusion m-0 w-100 d-flex">
                                                <p className="roomName font-weight-bold">Inclusions :
                                                    {ratePlans.amenities.length > 0 ? ratePlans.amenities[0].split(",").map((x, key) => key < 2 ? (
                                                        <span>&nbsp; <i className="fa fa-check color-blue" aria-hidden="true"></i> {x}</span>
                                                    ) : null) : <span>Not Available</span>}
                                                </p>
                                                {ratePlans.amenities.length > 0 && getAllInclusions(ratePlans.amenities[0])}
                                            </div>
                                        )}

                                        <div className="cancelation-foolter d-flex justify-content-between">
                                            {/* Cancellation Policy */}
                                            <div className="inclusion m-0">
                                                {/* {room.ratePlans.map((ratePlan, index) => ( */}
                                                <p className="roomName font-weight-bold m-0">
                                                    Cancellation Policy <span><Tooltip title={cancellationPolicyTable(ratePlans)}> <InfoCircleOutlined /></Tooltip></span>
                                                </p>
                                                {/* ))} */}
                                            </div>
                                            {/* Room Prices */}
                                            <div className="room-price ml-auto">
                                                <p className="roomName font-weight-bold">{activeCurrency} {currencyValue(room.ratePlans[0].price.total)}</p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}

                            </div>
                        </div>
                    </React.Fragment>
                ))
            ) : null}
        </Card >
    )
}

export default RoomDetailsCard;