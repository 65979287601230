import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton, Modal, Button, Form, Card } from "antd";

import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useActivitiesContext } from "../../../common/providers/Activities/ActivitiesProvider";

import ApiClient from "../../../helpers/ApiClient";
import Activiesfilters from "../Activitiesfilters/ActivitiesFilter";

import ActivitySort from "../ActivitySort/ActivitySort";
import AcivitiesSearchForm from "../Activitiessearch/AcivitiesSearchForm";
import CustomNoResultFound from "../../../components/ErrorPages/CustomNoResultFound";
import NoResultFound from "../../../components/ErrorPages/NoResultFound";
import queryString from "query-string";
import FilterSkeleton from "../../../components/FilterSkeleton/FilterSkeleton";
import SkeletonLayout from "../../../components/Skeleton/Skeleton";

import "../Activitiesresults/Activitiesresults.scss";
import ActivitiesContainer from "./ActivitiesContainer";

const initialState = {
  data: [],
  traceId: "",
  currency: "",
  searchReq: null,
  activitySearchObject: null,
};

const Activiesresults = ({ activitiesParam, isFromPackage = false }) => {
  const { user } = useAuthContext();
  const {
    state: { searchReqBuildActivities },

    setActivitiesCheckOutData,
  } = useActivitiesContext();

  const [isLoading, setIsLoading] = useState(true);
  const [toursData, setToursData] = useState([]);
  const [mainToursList, setMainToursList] = useState(initialState);
  const [showNetFare, setShowNetFare] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);
  const [PopularTourlist, setPopularTourlist] = useState({});

  const showModalflight = () => {
    setModalVisible({
      visible: true,
    });
  };
  const showModalsort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };

  /*
  API call to get TourList
  */
  const searchAllTours = () => {
    let param = queryString.parse(document.location.search);
    let activitySearchObject = null;
    setIsLoading(true);
    if (activitiesParam != undefined && activitiesParam != null) {
      activitySearchObject = {
        countryId: activitiesParam.CountryId,
        cityId: activitiesParam.CityId,
        travelDate: activitiesParam.TravelDate,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
        city: "",
        country: "",
      };
    } else {
      activitySearchObject = {
        countryId: param?.country?.split("-")[1],
        cityId: param?.city?.split("-")[1],
        travelDate: param?.travelDate,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
        city: param?.city,
        country: param?.country,
      };
    }

    if (
      activitySearchObject.countryId !== null &&
      activitySearchObject.cityId != null
    ) {
      setActivitiesCheckOutData({});
      ApiClient.post("activities/tours/list", activitySearchObject)
        .then((results) => results)
        .then((resp) => {
          if (resp.statusCode == 200) {
            setMainToursList((prev) => ({
              ...prev,
              data: resp.data,
              traceId: resp.traceId,
              currency: resp.currency,
              searchReq: searchReqBuildActivities,
              activitySearchObject: activitySearchObject,
            }));
          } else {
            setMainToursList(initialState);
          }

          setIsLoading(false);
        })
        .catch(() => {
          setMainToursList(initialState);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    searchAllTours();
    getPopularTours();
  }, [activitiesParam]);

  const getPopularTours = () => {
    ApiClient.get('activities/tours/popularTourslist').then((res) => {
      if(res.statusCode == 200) {
        setPopularTourlist(res.data);
      }else{
        console.log('Error fetching popular tour list', res?.message);
      }
    }).catch((e) => console.log(e.message))
  }

  return (
    <>
      <div className="promo providerapi ">
        <div className="modify-combined-search-container activitycnf">
          {isFromPackage ? null : (
            <div className="admin-container bar-admin-width width-activity-v3">
              <div className="accordion mobile-accordi" id="accordionExample">
                <div className="card hotels-search-card-value">
                  <div
                    className="card-head hide-icon-heading-two"
                    id="headingTwo"
                  >
                    <h2
                      className="mb-0 modiiconbar"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                       aria-expanded="true"
                     // aria-expanded="false"   // Code update 
                      aria-controls="collapseOne"
                    >
                      Modify Search
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <div className="main_div sub_div">
                        <AcivitiesSearchForm
                          modify={true}
                          onModify={searchAllTours}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="hotels_results_page activites-bar-panel">
        <div className="hotels-page-wrapper">
          <div className="travel-img">
            <div className="admin-container bar-admin-width activity-cms">
              {isLoading ? (
                <section>
                  <div className="hotel-skeleton-layout">
                    <div className="hotel-skeleton-layout-container">
                      <Row gutter={16}>
                        {isFromPackage ? (
                          <Col md={24} xs={24} className="result-body-skeleton">
                            <Card className="card-skeleton">
                              <Skeleton active={true} paragraph={{ rows: 0 }} />
                            </Card>

                            {[...Array(6)].map((_, key) => (
                              <SkeletonLayout key={key + "SkeletonLayout"} />
                            ))}
                          </Col>
                        ) : (
                          <>
                            <Col md={6} xs={0} className="filter-skeleton">
                              <FilterSkeleton />
                            </Col>
                            <Col
                              md={18}
                              xs={24}
                              className="result-body-skeleton"
                            >
                              <Card className="card-skeleton">
                                <Skeleton
                                  active={true}
                                  paragraph={{ rows: 0 }}
                                />
                              </Card>

                              {[...Array(6)].map((_, key) => (
                                <SkeletonLayout key={key + "SkeletonLayout"} />
                              ))}
                            </Col>
                          </>
                        )}
                      </Row>
                    </div>
                  </div>
                </section>
              ) : (
                <div className="hotels-box">
                  {mainToursList?.data.length > 0 ? (
                    <section className="hotels_list">
                      <div className="hotels-list-wrapper">
                        <div className="list-container">
                          {isFromPackage ? null : (
                            <div className="filters-box">
                              <Activiesfilters
                                count={mainToursList?.data.length}
                                data={mainToursList?.data}
                                setToursData={setToursData}
                              />
                            </div>
                          )}

                          <div className="hotels-box">
                            <ActivitySort
                              toursData={mainToursList?.data}
                              setToursData={setToursData}
                              showNetFare={showNetFare}
                              setShowNetFare={setShowNetFare}
                              PopularTourlist={PopularTourlist}
                            />
                            {toursData.length > 0 ? (
                              <ActivitiesContainer
                                toursData={toursData}
                                mainToursList={mainToursList}
                                showNetFare={showNetFare}
                                isFromPackage={isFromPackage}
                              />
                            ) : (
                              <CustomNoResultFound
                                title={"No Activities Available"}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  ) : (
                    <NoResultFound />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Row className="fiters-value-hotel-1">
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalflight}>
            {" "}
            <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters
          </h5>
        </Col>
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalsort}>
            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
            &nbsp;Sort by
          </h5>
        </Col>
      </Row>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary">Close</Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            {mainToursList?.data.length > 0 && (
              <Activiesfilters
                count={mainToursList?.data.length}
                data={mainToursList?.data}
                setToursData={setToursData}
              />
            )}
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary">Confirm</Button>
            {/*  <Button key="submit" type="danger" >Reset</Button> */}
          </div>,
        ]}
      >
        <Form>
          <Row>
            <Col md={24} xs={24}>
              {mainToursList?.data.length > 0 && (
                <ActivitySort
                  toursData={mainToursList?.data}
                  setToursData={setToursData}
                  showNetFare={showNetFare}
                  setShowNetFare={setShowNetFare}
                />
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Activiesresults;
