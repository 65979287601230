import React, { useContext, useState, useEffect } from "react";
import { Button, Card, Col, Collapse, Input, Radio, Row, Form } from "antd";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";

import PayMentSelection from "../../../common/PaymentSelection/PaymentSelection"

import moment from "moment";
import ApiClient from "../../../helpers/ApiClient";

import { CloseOutlined } from "@ant-design/icons";

import {
  getAllActivitiesPrice,
  getActivitiesPartPaymentDetails,
} from "./activitiesHelper";

const { Panel } = Collapse;
const oriDateFormat = "YYYY-MM-DD";
const dateFormat = "DD-MM-YYYY";

const ActivitiesGrandTotalCard = ({
  activitiesCheckOutData,
  isPartPayment,
  SeletedPayOption
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();

  const [value, setValue] = useState();

  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    state: {
      otherData: { ConvFee, promoData, redeemAmount, transactionFee },
      partPayment,
    },
    AddConvFee,
    validatePromoCode,
    RemovePromo,
    validateRedeemCoupon,
    RemoveRedeemCoupon,
  } = useContext(GlobalStatesContext);

  const [form] = Form.useForm();

  const [promoCode, setPromoCode] = useState([]);
  const [redeemData, setRedeemData] = useState({});

  const {
    totalAmount,
    grandTotal,
    convAmount,
    discount,
    commision,
    redeemTotal,
    tranxFree,
    TourInfo
  } = getAllActivitiesPrice(
    activitiesCheckOutData,
    ConvFee,
    promoData,
    redeemAmount,
    SeletedPayOption == true ? transactionFee : 0,
  );
  const { partPaymentAmount, dueAmount } = getActivitiesPartPaymentDetails(
    grandTotal,
    partPayment
  );

  useEffect(() => {
    if (!agent) {
      AddConvFee(4);
      getpromo();
    }
  }, [agent]);

  const getpromo = () => {
    ApiClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, oriDateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {
            let busPromo = data.filter(
              (promo) =>
                promo.ServiceType === 4 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(busPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);
      });
  };

  const handleApply = (code) => {
    form.setFieldsValue({
      promo: code,
    });

    form.submit();
  };

  const getPaxCount = (tour) => {
    return `Adult(s) ${tour?.adult ? tour?.adult : 0} ${tour?.child ? ", Child(s) " + tour?.child : " "
      } ${tour?.infant ? ", Infant(s) " + tour?.infant : " "}`;
  };
  const getRedeemCoupon = (userData) => {
    setRedeemData({});
    let obj = {
      userId: userData?.UserID,
      roleType: userData?.Role?.RoleId,
      membershipId: userData?.Membership,
    };
    ApiClient.post("admin/userAmtWithReedemPer", obj)
      .then((res) => {
        if (res.status === 200) {
          setRedeemData(res.data);
        }
      })
      .catch((error) => {
        setRedeemData({});
      });
  };

  useEffect(() => {
    if (user) {
      getRedeemCoupon(user);
    }
  }, [user]);
  return (
    <>
      {activitiesCheckOutData.length > 0
        ? activitiesCheckOutData.map((tour) => {
          return (
            <Card className="card-total-option checkout-activity-card">
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                expandIconPosition="right"
              >
                <Panel header={tour?.tourName} key="1">
                  <ul className="info-item-flexbox">
                    <li>
                      <p className="date-item">Option Name:</p>
                    </li>
                    <li>
                      <p className="date-item-1">{tour?.optionName}</p>
                    </li>
                    <li>
                      <p className="date-item">Transfer Option:</p>
                    </li>
                    <li>
                      <p className="date-item-1">{tour?.transferName}</p>
                    </li>

                    <li>
                      <p className="date-item">Date :</p>
                    </li>
                    <li>
                      <p className="date-item-1">
                        {moment(tour?.tourDate, oriDateFormat).format(
                          dateFormat
                        )}
                      </p>
                    </li>
                    <li>
                      <p className="date-item">Time :</p>
                    </li>
                    <li>
                      <p className="date-item-1">{tour?.startTime}</p>
                    </li>
                    <li>
                      <p className="date-item">Pax :</p>
                    </li>
                    <li>
                      <p className="date-item-1">{getPaxCount(tour)}</p>
                    </li>
                    {/* <li>
                      <p className="date-item">Adult {`(${tour.adult} x ${currencyValue(tour.adultRate)})`}</p>
                    </li>
                    <li>
                      <p className="date-item-1">{activeCurrency} {currencyValue(tour.adultRate * tour.adult)}</p>
                    </li>
                    <li>
                      <p className="date-item">Child {`(${tour.child} x ${currencyValue(tour.childRate)})`}</p>
                    </li>
                    <li>  
                      <p className="date-item-1">{activeCurrency} {currencyValue(tour.childRate * tour.child)}</p>
                    </li> */}
                    {/* remove cancel date as per client requirment 12-03 -2023 <li>
                        <p className="date-item offdata">
                          Last Date to Cancel :
                        </p>
                      </li>
                      <li>
                        <p className="date-item-1 offdata">
                          {moment(tour.cancellationDate, oriDateFormat).format(
                            dateFormat
                          )}
                        </p>
                      </li> */}
                    <li>
                      <p className="date-item offdata">Amount :</p>
                    </li>
                    <li>
                      <p className="date-item-1 offdata">
                        {activeCurrency} {currencyValue(tour?.serviceTotal)}
                      </p>
                    </li>
                  </ul>
                </Panel>
              </Collapse>
            </Card>
          );
        })
        : null}
      <Card className="card-total-option checkout-activity-card">
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIconPosition="right"
        >
          <Panel header="Final Payment  " key="1">
            <ul className="info-item-flexbox">
              {!agent && user?.Role?.RoleLevel !== 3 && (
                <>
                  <li>
                    <p className="date-item">Convenience Fee </p>
                    {/* (Code Start) Code Changes by Karthik --27/April/2023 Condition according to Nitin Sir */}
                    <span>{activitiesCheckOutData.filter((tour) => tour?.roleType === 4 || tour?.roleType === 2).length > 0 ? "(non-refundable)" : ""}</span>
                    {/* (Code End) */}
                  </li>
                  <li>
                    <p className="date-item-1">
                      {activeCurrency} {currencyValue(convAmount)}
                    </p>
                  </li>
                </>
              )}

              <li><p className="date-item">Transaction Fee</p></li>
              <li>
              <p className="date-item-1">{activeCurrency} {currencyValue(tranxFree)}</p></li>
              <li>
                <p className="date-item">Total Amount</p>
              </li>
              <li>
                <p className="date-item-1">
                  {activeCurrency} {currencyValue(totalAmount)}
                </p>
              </li>

              {/* Code add by karthik refences by akash sir 1-feb-24 */}
              {/* <li>
                <p className="date-item">{"Adults Price"} </p>
              </li>
              <li>
                <p className="date-item-1">
                  {activeCurrency} {currencyValue(TourInfo.adultPrice)}
                </p>
              </li>
              <li>
                <p className="date-item">{"Childs Price"} </p>
              </li>
              <li>
                <p className="date-item-1">
                  {activeCurrency} {currencyValue(TourInfo.childPrice)}
                </p>
              </li> */}
              {/* Code add by karthik refences by akash sir 1-feb-24 */}

              {/* <li>
                <p className="date-item">Total VAT</p>
              </li>
              <li>
                <p className="date-item-1">
                  {activeCurrency} {currencyValue(0)}
                </p>
              </li> */}

              {promoData.status && (
                <>
                  <li>
                    <p className="date-item">Discount</p>
                  </li>
                  <li>
                    <p className="date-item-1">
                      - {activeCurrency} {currencyValue(discount)}
                    </p>
                  </li>
                </>
              )}

              {partPayment.isPartPayment && isPartPayment === 2 ? (
                <>
                  <li>
                    <p className="date-item offdata">Due Amount</p>
                  </li>
                  <li>
                    <p className="date-item-1 offdata">
                      {activeCurrency} {currencyValue(dueAmount)}
                    </p>
                  </li>

                  <li>
                    <p className="date-item offdata-1">Total Payment</p>
                  </li>
                  <li>
                    <p className="date-item-1 offdata-1">
                      {activeCurrency} {currencyValue(partPaymentAmount)}
                    </p>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <p className="date-item offdata-1 mt-1">Final Payment</p>
                  </li>
                  <li>
                    <p className="date-item-1 offdata-1 mt-1">
                      {activeCurrency} {currencyValue(grandTotal)}
                    </p>
                  </li>
                </>
              )}
              {agent ? (
                <>
                  <li>
                    <p className="date-item mt-1">Commission Earned</p>
                  </li>
                  <li>
                    <p className="date-item-1 mt-1">
                      {activeCurrency} {currencyValue(commision)}
                    </p>
                  </li>
                </>
              ) : null}
            </ul>
          </Panel>
        </Collapse>
      </Card>
      {!agent && user?.Role?.RoleLevel !== 3 ? (
        <div className="buspromo_wrapper">
          {promoData.status == false ? (
            <Card className="checkout-activity-card">
              <p className="name">Apply Promo</p>

              <Form
                layout="vertical"
                form={form}
                onFinish={(d) => {
                  // if (partPayment.isPartPayment && isPartPayment === 2) {
                  //   validatePromoCode({
                  //     ServiceType: 4,
                  //     Amount: Number(partPaymentAmount),
                  //     PromoCode: d.promo,
                  //     UserId: user?.UserID ?? 1,
                  //   });
                  // } else {
                  validatePromoCode({
                    ServiceType: 4,
                    Amount: Number(grandTotal),
                    PromoCode: d.promo,
                    UserId: user?.UserID ?? 1,
                  });
                  // }
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col md={16} sm={12} xs={12}>
                    <Form.Item
                      name="promo"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input
                        className="inputbg"
                        placeholder="Enter Your Promo code"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={12}>
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          form.submit();
                        }}
                      >
                        Apply
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              {promoCode.length ? (
                promoCode.map((item, key) => {
                  return (
                    <>
                      <div className="promo-cp-coupons mt-0" key={key}>
                        <div className="inline-cp-promo">
                          <Form>
                            <Form.Item>
                              <Radio
                                onClick={() => handleApply(item.Code)}
                              ></Radio>
                            </Form.Item>
                          </Form>
                          <p className="promo-key-cp">{item.Code}</p>
                          {item.DiscountType === 1 ? (
                            <p className="save-cp-offer">
                              Save {item.Discount + " %"}
                            </p>
                          ) : (
                            <p className="save-cp-offer">
                              Save {activeCurrency}&nbsp;
                              {currencyValue(item.Discount)}
                            </p>
                          )}
                        </div>

                        <div className="promo-percentage-cp">
                          <p>{item.Description}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <>
                  <div className="promo-cp-coupons">
                    <div className="inline-cp-promo">
                      <p className="promo-key-cp">No Promo Code Available</p>
                    </div>
                  </div>
                </>
              )}
            </Card>
          ) : (
            <Card className="flight-cards-details checkout-activity-card">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      RemovePromo();
                      form.resetFields();
                    }}
                  />{" "}
                </p>
              </div>
              <div className="promo-input">
                <p>
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}
      {user && redeemData?.couponAmt > 0 ? (
        redeemAmount.status === true ? (
          <Card className="flight-cards-details checkout-activity-card">
            <div className="d-flex justify-content-between align-items-center">
              <p className="name mb-0">
                Redeemed From Coupon Wallet: {redeemTotal}
              </p>
              <CloseOutlined
                onClick={() => {
                  RemoveRedeemCoupon();
                }}
              />
            </div>
          </Card>
        ) : (
          <Card className="flight-cards-details checkout-activity-card">
            <p className="font-weight-bold mb-0">
              Coupon Wallet: {/* {activeCurrency} */}
              {"("}
              {redeemData?.couponAmt} {")"}
            </p>
            {/* <p className="mb-2">
              You can use {redeemData?.reedemPercentage}% of the Total Amount             
            </p> */}

            <div className="btn_wrapper">
              {" "}
              <Button
                type="primary"
                className="book-btn"
                onClick={() => {
                  validateRedeemCoupon({
                    userId: user?.UserID ?? 0,
                    roleType: user?.Role?.RoleId ?? 0,
                    membershipId: user?.Membership ?? 0,
                    couponAmt: redeemData?.couponAmt ?? 0,
                  });
                }}
              >
                Redeem Coupon
              </Button>
            </div>
          </Card>
        )
      ) : null}
      {SeletedPayOption != true ? null : <PayMentSelection setValue={setValue} value={value} />}
    </>
  );
};

export default ActivitiesGrandTotalCard;
