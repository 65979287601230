import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
} from "react-router-dom";
import { Button, message, notification } from "antd";

import { useAuthContext } from "./common/providers/AuthProvider";
import {
  ActivitiesProvider,
  HotelProvider,
  FlightProvider,
} from "./common/providers";

import Administration from "./admin/Administration";
import Footer from "./common/footer/Footer";
import Nav from "./common/navbar/Nav";
import UserNavigations from "./common/navbar/UserNavigations";
import { Provider } from 'react-redux'
import "./App.scss";
import CommanStore from "./ReduxContainer/CommanStore";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const AcceptBtn = () => {
  return (
    <Button
      className="cookies-btn"
      onClick={() => notification.close("cookies")}
    >
      Accept
    </Button>
  );
};

const openNotificationSitInfo = () => {
  // if (localStorage.getItem("accept_cookies")) {
  //   return;
  // }
  notification.open({
    message: "",
    key: "Notifications",
    icon: null,
    duration: null,
    closeIcon:<AcceptBtn />,
    // onClose: () => localStorage.setItem("accept_cookies", 1),
    placement: "bottomRight",
    description:
      "Due to a technical upgrade, we are in the process of optimizing the speed and technical functionality of our website. Our dedicated technical team is diligently working on this task to ensure a seamless user experience. In the event of any technical issues, our responsive backend team is available round the clock to provide assistance and support. We apologize for any inconvenience caused during this upgrade and appreciate your patience.",
    className: "Site-notification",
  });
};


const openNotification = () => {
  if (localStorage.getItem("accept_cookies")) {
    return;
  }
  notification.open({
    message: "",
    key: "cookies",
    icon: null,
    duration: null,
    closeIcon: <AcceptBtn />,
    onClose: () => localStorage.setItem("accept_cookies", 1),
    placement: "bottomRight",
    description:
      "By click on accept button, you will accept that we may collect and use some of your personal data and set cookies to improve your experience and customize advertising. In order to get more information, you can read our Privacy policy and Cookie policy which explains the procedure to you in detail.",
    className: "cookies-notification",
  });
};

const App = () => {
  const {
    tryLocalSignin,
    isLoading,
    isLogin: { role },
    accessList,
    accessToken,
    resetIsLogin,
  } = useAuthContext();
  useEffect(() => {
    tryLocalSignin();
    // openNotificationSitInfo();
    setTimeout(() => {
      openNotification();
    }, 5000);

    message.config({
      duration: 10,
      maxCount: 1,
    });
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <Router>
      <ScrollToTop>
        {role ? (
          <Administration
            accessList={accessList}
            accessToken={accessToken}
            resetIsLogin={resetIsLogin}
          />
        ) : (
          <>
            <ActivitiesProvider>
              <FlightProvider>
                <HotelProvider>
                  <Nav />
                  <Provider store={CommanStore}>
                    <UserNavigations />
                  </Provider>
                  <Footer />
                </HotelProvider>
              </FlightProvider>
            </ActivitiesProvider>
          </>
        )}
      </ScrollToTop>
    </Router>
  );
};

export default App;
