import React, { useContext, useState, useEffect, useReducer } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Radio,
  Row,
  Select,
  Skeleton,
  Spin,
} from "antd";

import "./Quotationcheckout.scss";

import ApiClient from "../../../helpers/ApiClient";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { GlobalStatesContext } from "../../../common/providers";
import VerifyOtpModal from "../../../common/OtpModal/VerifyOtpModal";
import { useActivitiesContext } from "../../../common/providers/Activities/ActivitiesProvider";
// import PaymentGateway from "../../../helpers/PaymentGateway";
import { getPassengerData } from "../../../helpers/PassegerData";
import CountryList from "../../../common/CountryList";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import PackageGrandTotalCard from "./PackageGrandTotalCard";
import { getBuildPackagePrice } from "./buildpackageHelper";
import PayGateway from "../../../helpers/PayGateway";
import PanVerification from "../../Hotels/HotelCheckout/PanVerification";
const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";
const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}

const Quotationcheckout = () => {
  const {
    state: {
      otherData: { ConvFee, promoData, redeemAmount, transactionFee },
      partPayment,
      PartPayCapable,
      tourLiveList
    },
    getPartPayment,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);

  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const {
    state: { quotation },
  } = useActivitiesContext();

  const [contact_form] = Form.useForm();
  const [guestDetailsForm] = Form.useForm();
  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
  const [isPartPayment, setIsPartPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [pgDisplay, setPgDisplay] = useState(false);
  // const [pgData, setPgData] = useState({});
  const [hotelAllDetails, setHotelAllDetails] = useState([]);
  const [contactData, setContactData] = useState({});
  const [paxData, setPaxData] = useState({});
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [SeletedPayOption, setSeletedPayOption] = useState(false);
  const [panVerificationNumber, setPanVerificationNumber] = useState('');
  let hotelPaxRoom = {};

  const { grandTotal, partPaymentAmount, partdueAmount } = getBuildPackagePrice(
    quotation,
    hotelAllDetails,
    ConvFee,
    promoData,
    partPayment,
    redeemAmount,
    SeletedPayOption == true ? transactionFee : 0,
    tourLiveList
  );

  const getHotelPrice = (hotelDetailsObj) => {
    try {
      let totalRoomBaseFare = 0;
      let totalRoomTax = 0;
      let totalOtherCharges = 0;
      let totalExtraGuestCharges = 0;
      let noOfNights = 0;
      let noOfRooms = 0;
      let adminCommission = 0;
      let agentMarkup = 0;
      let markup = 0;
      let totalCommission = 0;

      if (hotelDetailsObj && Object.keys(hotelDetailsObj).length > 0) {
        let checkin = new Date(hotelDetailsObj.hotelSearchData.checkInDate);
        let checkout = new Date(hotelDetailsObj.hotelSearchData.checkOutDate);
        let diffTime = checkout - checkin;
        let diffDays = Math.ceil(diffTime / (1000 * 24 * 60 * 60));

        noOfNights = Number(diffDays);
      }

      if (hotelDetailsObj?.hotelPriceData?.rooms?.length > 0) {
        noOfRooms = hotelDetailsObj?.hotelPriceData.rooms.length;
        hotelDetailsObj?.hotelPriceData.rooms.forEach((roomDetail) => {
          if (roomDetail?.ratePlans?.length > 0) {
            const priceObj = roomDetail?.ratePlans[0].price;

            totalRoomBaseFare += Number(priceObj.base);
            totalRoomTax += Number(priceObj.tax);
            totalOtherCharges += Number(priceObj.otherCharges);
            totalExtraGuestCharges += Number(priceObj.extraGuestCharges);
            agentMarkup += Number(priceObj.agentMarkup);
            adminCommission += Number(priceObj.adminCommission);
            markup += Number(priceObj.markup);
            totalCommission += Number(priceObj.commission);
          }
        });
      }

      // let grandBaseFare = Number(totalRoomBaseFare) * noOfNights;
      // let grandTax = Number(totalRoomTax) * noOfNights;
      // let grandOtherCharges = Number(totalOtherCharges) * noOfNights;
      // let grandExtraGuestCharges = Number(totalExtraGuestCharges) * noOfNights;
      let grandBaseFare = Number(totalRoomBaseFare);
      let grandTax = Number(totalRoomTax);
      let grandOtherCharges = Number(totalOtherCharges);
      let grandExtraGuestCharges = Number(totalExtraGuestCharges);

      let totalTax = Number(grandTax) + Number(grandOtherCharges);
      let total =
        Number(grandBaseFare) +
        Number(totalTax) +
        Number(grandExtraGuestCharges);
      let displayTotal = total;

      if (totalCommission) total -= totalCommission;

      return {
        totalTax,
        displayTotal,
        total,
        agentMarkup,
        adminCommission,
        commission: totalCommission,
        markup,
      };
    } catch (error) {}
  };

  let groupBy = (array, key) => {
    let flatArray = array.reduce((prev, curr) => {
      return prev.concat(curr);
    });

    let groupArr = flatArray.reduce((result, obj) => {
      (result[obj[key]] = result[obj[key]] || []).push(obj);
      return result;
    }, {});

    return groupArr;
  };

  const  userReg = async (name, email, mobile) => {
    let regObj = {
      Name: name,
      Email: email,
      DialingCode: "+91",
      Mobile: mobile,
      Password: "",
      Role: 4,
      DeviceToken: "string",
      DeviceType: "Web",
      FirBaseToken: "string",
    };
    
    ApiClient.post("admin/user/register", regObj)
      .then(() => {})
      .catch();
  };

  const handlePackageBooking = (contactData, pgType, hotelPaxData) => {
    if (Object.keys(quotation?.PackageDetails).length > 0) {
      let countryDetails = CountryList.filter(
        (item) => item.name === contactData.nationality
      );

      let reqData = {
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        currency: "AED",
        packageRefNo: quotation?.PackageDetails.PackageRefNo,
        activitiesPackageDetails: [],
        hotelsPackageDetails: [],
        partPayment: {
          isPartPayment: false,
          serviceTotal: Number(grandTotal),
          partPaymentAmount: 0,
          dueAmount: 0,
          serviceType: 4,
        },
        leadPassenger: {
          prefix: contactData.prefix,
          firstName: contactData.firstName,
          lastName: contactData.lastName,
          email: contactData.email,
          mobile: contactData.mobile,
          nationality: contactData.nationality,
          message: contactData.message,
          leadPassenger: 1,
          paxType: "adult",
          pan_no: panVerificationNumber,
        },
        // promoData: {
        //   status: false,
        //   Discount: 0,
        //   tripType: "",
        // },
        pgType: pgType ? pgType : pgDetails.pgType,
        paymentMethod: SeletedPayOption == true ? transactionFee?.methodType: '',
        promoCode: promoData.Code ?? "",
        convienenceId: ConvFee.id ?? 0,
        isCouponReedem: redeemAmount.status ?? false,
      };

      if (user) {
        if (user.UserID) {
          if (contactData.paymentOption === 2) {
            let partAmount = Number(partPaymentAmount);
            let due = Number(partdueAmount);
            reqData.partPayment = {
              isPartPayment: true,
              serviceTotal: Number(grandTotal),
              partPaymentAmount: Number(partAmount),
              dueAmount: Number(due),
              serviceType: 4,
            };
          }
        }
      }

      if (quotation?.TourDetails?.length > 0) {
        let data = groupBy(quotation?.TourDetails, "tourId");

        reqData.activitiesPackageDetails = Object.keys(data).map((id) => {
          if (data[id] && data[id]?.length > 0) {
            return {
              TraceId: data[id][0]?.traceId,

              TourDetails: data[id].map((item, i) => ({
                apikey: item.apikey,
                tourId: item.tourId,
                tourName: item.tourName,
                optionId: item.optionId,
                optionName: item.optionName,
                adult: item.adult,
                child: item.child,
                infant: item.infant,
                tourDate: item.tourDate,
                timeSlotId: item.timeSlotId,
                startTime: item.startTime,
                transferId: item.transferId,
                transferName: item.transferName,
                pickup:
                  contactData[`pickup-${item.tourId}-${item.optionId}-${i}`],
                adultRate: item.adultRate,
                childRate: item.childRate,
                infantRate: item.infantRate,
                serviceTotal: item.serviceTotal,
                cancellationDate: item.cancellationDate,
                tourImage: item.tourImage,
                duration: item.duration,
                tourShortDescription: item.tourShortDescription,
                // commission: item.commission ?? 0,
                markup: item.markup ?? 0,
                agentMarkup: item.agentMarkup ?? 0,
                // adminCommission: item.adminCommission ?? 0,
                traceId: item.traceId,
              })),
              totalPrice: data[id].reduce(
                (a, b) => a + Number(b.serviceTotal),
                0
              ),
              Passengers: {
                prefix: contactData.prefix,
                firstName: contactData.firstName,
                lastName: contactData.lastName,
                email: contactData.email,
                mobile: contactData.mobile,
                nationality: contactData.nationality,
                message: contactData.message,
                leadPassenger: 1,
                paxType: "adult",
              },
            };
          }
        });
      }

      if (hotelAllDetails.length > 0) {
        reqData.hotelsPackageDetails = hotelAllDetails.map((hotel, index) => {
          if (hotel?.hotelPriceRes) {
            return {
              traceId: hotel.traceId,
              guests: hotel?.hotelPriceRes?.roomGuestInfo.map(
                (roomsObj, roomIndex) =>
                  roomsObj.paxInfoList.map((pax, paxIndex) => ({
                    leadGuest: paxIndex == 0 ? true : false,
                    guestType: pax.guestType,
                    guestInRoom: pax.guestInRoom,
                    title:
                      hotelPaxData[`title-${index}-${roomIndex}-${paxIndex}`],
                    firstName:
                      hotelPaxData[
                        `firstname-${index}-${roomIndex}-${paxIndex}`
                      ],
                    lastName:
                      hotelPaxData[
                        `lastname-${index}-${roomIndex}-${paxIndex}`
                      ],
                    age: hotelPaxData[`age-${index}-${roomIndex}-${paxIndex}`],
                    pan: hotelPaxData[`pan-${index}-${roomIndex}-${paxIndex}`]
                      ? hotelPaxData[`pan-${index}-${roomIndex}-${paxIndex}`]
                      : "",
                    passportDOE: hotelPaxData[
                      `passportDOE-${index}-${roomIndex}-${paxIndex}`
                    ]
                      ? hotelPaxData[
                          `passportDOE-${index}-${roomIndex}-${paxIndex}`
                        ]
                      : "",
                    passportDOI: hotelPaxData[
                      `passportDOI-${index}-${roomIndex}-${paxIndex}`
                    ]
                      ? hotelPaxData[
                          `passportDOI-${index}-${roomIndex}-${paxIndex}`
                        ]
                      : "",
                    passportNo: hotelPaxData[
                      `passportNo-${index}-${roomIndex}-${paxIndex}`
                    ]
                      ? hotelPaxData[
                          `passportNo-${index}-${roomIndex}-${paxIndex}`
                        ]
                      : "",
                  }))
              )[0],
              addressInfo: {
                addressLine1: "",
                addressLine2: "",
                cellCountryCode: countryDetails[0].dial_code,
                countryCode: countryDetails[0].dial_code,
                areaCode: countryDetails[0].dial_code,
                phoneNo: contactData.mobile,
                email: contactData.email,
                city: "",
                state: "",
                country: countryDetails[0].code,
                zipCode: "",
              },
              totalPrice: Number(hotel?.hotelPriceRes?.total.toFixed(2)),
              // Commission: 0,
              AdminMarkup: 0,
              AgentMarkup: 0,
              Conveniencefee: 0,
              // AdminCommission: 0,
              LastCancellationDate: hotel?.hotelPriceRes?.lastCancellationDate,
              LastVoucherDate: hotel?.hotelPriceRes?.lastVoucherDate,
            };
          }
        });
      }

      reqData.totalPrice =
        reqData.partPayment.isPartPayment === true
          ? reqData.partPayment.partPaymentAmount
          : reqData.partPayment.serviceTotal;

      if (!user) {
        userReg(contactData.firstName, contactData.email, contactData.mobile);
      }

      blockPackage(reqData);
    }
  };

  const blockPackage = (reqData) => {
    setIsLoading(true);
    setLoadingSpin(true);
    ApiClient.post("buildPackage/packageBlock", reqData)
      .then((result) => result)
      .then((resp) => {
        setIsLoading(false);
        setLoadingSpin(false);
        if (resp.status == 200) {
          if (resp.data.available == true) {
            window.location.href = resp.data.payment_link;
            // processPayment(
            //   resp.data.bookingRefNo,
            //   contactData,
            //   reqData.partPayment
            // );
          } else {
            message.error("Tour is unavailable", 10);
          }
        } else if (resp.status == 400 && resp?.data?.length > 0) {
          message.error(resp?.message);
        } else if (resp.status == 400) {
          if (resp?.error?.length > 0 && quotation?.TourDetails?.length > 0) {
            let tourList = [];

            if (quotation?.TourDetails?.length > 0) {
              quotation?.TourDetails?.forEach((tour) => {
                if (tour && tour?.length > 0) {
                  tour.forEach((item) => {
                    tourList.push(item);
                  });
                }
              });
            }
            let filterOption = tourList?.filter(
              (item) => item.optionId === resp?.error[0].optionId
            );

            message.error(
              `${filterOption[0]?.optionName}.
                            ${resp?.error[0].message}
                          `,
              15
            );
            return;
          }
        } else {
          message.error("Tour is unavailable");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setLoadingSpin(false);
      });
  };

  // const processPayment = async (oid, passanger, partPayment) => {
  //   const amount =
  //     partPayment.isPartPayment === true
  //       ? partPayment.partPaymentAmount
  //       : partPayment.serviceTotal;
  //   let data = {
  //     OrderId: oid,
  //     OrderAmount: parseFloat(amount),
  //     OrderCurrency: "INR",
  //     OrderNote: "Package Booking",
  //     CustomerName: `${passanger?.firstName} ${passanger?.lastName}`,
  //     CustomerEmail: passanger?.email,
  //     CustomerPhone: passanger?.mobile,
  //     TDS: 0,
  //     GST: 0,
  //     Commission: 0, // for agent only

  //     AgentMarkup: 0,
  //   };
  //   // set PgData and Display

  //   setPgData(data);
  //   setPgDisplay(true);
  // };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  const loadpassengerData = () => {
    if (user && user?.UserID) {
      getPassengerData(user.UserID).then((data) => {
        if (data.status) {
          contact_form.setFieldsValue({
            firstName: data.Name,
            mobile: data.Mobile,
            email: data.Email,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (user) {
      if (user.UserID) {
        getPartPayment(2, {
          BusinessType: user?.Role?.RoleId,
          ServiceType: 5,
          quotation: quotation
        });
      }
    } else {
      getPartPayment(1);
    }
  }, [user]);

  useEffect(() => {
    if (Object.keys(quotation).length > 0) {
      if (quotation.HotelDetails.length > 0) {
        getAllHotelsDetails(quotation.HotelDetails);
      }
    }
    loadpassengerData();
    RemovePromoConvFee();
  }, []);

  const getAllHotelsDetails = async (hotelDetails) => {
    setHotelAllDetails([]);

    let allSelectedHotels = [...hotelDetails];

    const traceId = hotelDetails[0].traceId;

    for (let i = 0; i < allSelectedHotels.length; i++) {
      setLoading(true);
      let roomPlan = [];
      allSelectedHotels[i].Rooms.forEach((Rp) => {
        roomPlan.push({
          roomID: Rp.RoomId,
          rateID: Rp.ratePlanId,
        });
      });

      await ApiClient.post("hotels-v2/hotelprice", {
        traceId: traceId,
        roomsId: allSelectedHotels[i].roomsId,
        roomPlan: roomPlan,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
      })
        .then((res) => res)
        .then((res) => {
          if (res.status === 200) {
            const { traceId, repriceId, hotels, request } = res.data;

            if (
              Object.keys(hotels).length > 0 &&
              Object.keys(request).length > 0 &&
              hotels?.rooms.length > 0
            ) {
              let hotelPriceData = { ...hotels, traceId, repriceId };
              let hotelSearchData = request;
              const roomInfoArr = [];

              request.roomGuests.map((room, roomIndex) => {
                const paxListArr = [];

                [...Array(room.noOfAdults)].map(() => {
                  let paxObj = {
                    firstName: "",
                    lastName: "",
                    title: "Mr.",
                    guestType: "Adult",
                    guestInRoom: roomIndex + 1,
                  };
                  paxObj = HotelGuestReqFields(
                    paxObj,
                    hotels.guestRequiredFields
                  );
                  paxListArr.push(paxObj);
                });
                [...Array(room.noOfChilds)].map((_, index) => {
                  let paxObj = {
                    firstName: "",
                    lastName: "",
                    title: "Mstr",
                    guestType: "Child",
                    guestInRoom: roomIndex + 1,
                    age: parseInt(room.childAge[index]),
                  };
                  paxObj = HotelGuestReqFields(
                    paxObj,
                    hotels.guestRequiredFields
                  );
                  paxListArr.push(paxObj);
                });

                roomInfoArr.push({ paxInfoList: paxListArr });
              });

              hotelPaxRoom = {
                ...hotelPaxRoom,
                [allSelectedHotels[i].roomsId]: roomInfoArr,
              };
              let pricedetails = getHotelPrice({
                hotelPriceData,
                hotelSearchData,
              });

              let lastCancellationDate = "";
              let lastVoucherDate = "";
              let cancellationDates = [];
              let voucherDate = [];

              if (hotels?.rooms?.length > 0) {
                cancellationDates = hotels?.rooms?.map((roomDates) => {
                  return roomDates.ratePlans[0]?.lastCancellationDate;
                });

                voucherDate = hotels?.rooms?.map((roomDates) => {
                  return roomDates.ratePlans[0]?.lastVoucherDate;
                });
              }

              if (cancellationDates?.length > 1) {
                lastCancellationDate = cancellationDates.reduce(
                  (prev, cur, i) => {
                    if (prev && cur) {
                      return moment(prev).isSameOrBefore(cur) ? prev : cur;
                    } else if (prev) {
                      return prev;
                    } else {
                      return cur;
                    }
                  }
                );
              } else if (cancellationDates?.length === 1) {
                lastCancellationDate = cancellationDates[0]
                  ? cancellationDates[0]
                  : "";
              }

              if (voucherDate?.length > 1) {
                lastVoucherDate = voucherDate.reduce((prev, cur) => {
                  if (prev && cur) {
                    return moment(prev).isSameOrBefore(cur) ? prev : cur;
                  } else if (prev) {
                    return prev;
                  } else return cur;
                });
              } else if (voucherDate?.length === 1) {
                lastVoucherDate = voucherDate[0] ? voucherDate[0] : "";
              }

              allSelectedHotels[i]["hotelPriceRes"] = {
                hotelPriceData,
                hotelSearchData,
                roomGuestInfo: roomInfoArr,
                ...pricedetails,
                lastCancellationDate,
                lastVoucherDate,
              };
            }
          }
        })
        .catch(() => {});
    }

    setHotelAllDetails(allSelectedHotels);

    setLoading(false);
  };

  const HotelGuestReqFields = (paxObj, guestRequiredFields) => {
    guestRequiredFields.map((paxReqFieldsObj) => {
      Object.keys(paxReqFieldsObj).map((paxReqKeys) => {
        if (paxReqFieldsObj[paxReqKeys] === true) {
          paxObj[paxReqKeys] = "";
        }
      });
    });
    return paxObj;
  };

  const getAdultChildCount = (paxInfo) => {
    if (paxInfo.length > 0) {
      let count = paxInfo.reduce((p, c) => {
        let type = c.guestType;
        if (!p.hasOwnProperty(type)) {
          p[type] = 0;
        }
        p[type]++;
        return p;
      }, {});

      return `Adult(s) ${count.Adult ? count.Adult : " "} ${
        count.Child ? ", Children " + count.Child : " "
      }`;
    }
  };

  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyserviceOtp", {
      Mobile: contactData?.mobile,
      DialingCode: "+91",
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);

          handlePackageBooking(contactData, pgDetails.pgType, paxData);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = (contactData) => {
    ApiClient.post("admin/sendserviceOtp", {
      Mobile: !user ? contactData?.mobile : user.Mobile,
      DialingCode: "+91",
      refUrl: '/buildpackage/checkout/',
      UserId: user && user.UserID
    })
      .then((res) => {
        if (res?.status === 200) {
          setContactData(contactData);
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const ValidateBookingLimit = (contactData) => {
    setContactData((prev) => ({ ...prev, ...contactData }));
    const { grandTotal } = getBuildPackagePrice(
      quotation,
      hotelAllDetails,
      ConvFee,
      promoData,
      partPayment,
      redeemAmount,
      tourLiveList
    );
    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 5,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: grandTotal ? grandTotal : 0,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          // if (transactionFee && transactionFee?.methodType !== undefined) {
          //   handleSendOTP(contactData);
          // } else {
          //   message.error("Please Select Payment Method Type", 3);
          // }
          if (user && user.Role?.RoleId === 5) {
            if (!SeletedPayOption || (SeletedPayOption && transactionFee?.methodType !== undefined)) {
                handleSendOTP(contactData);
            } else {
                message.error("Please Select Payment Method Type", 3);
            }
        } else {
            if (transactionFee?.methodType !== undefined) {
                handleSendOTP(contactData);
            } else {
                message.error("Please Select Payment Method Type", 3);
            }
        }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const goTo = () => {
    // contact_form.validateFields().then((contactData) => {
    //   guestDetailsForm
    //     .validateFields()
    //     .then((paxData) => {
    //       // setContactData(contactData);
    //       setPaxData(paxData);
    //       agent
    //         ? handlePackageBooking(contactData)
    //         : ValidateBookingLimit(contactData);
    //     })
    //     .catch((e) => {
    //       guestDetailsForm.scrollToField(e.errorFields[0].name);
    //     });
    // });
    dispatchPgDetails({ type: PGDISPLAY, payload: true });
  };

  const blockApiReq = (pgType) => {
    dispatchPgDetails({ type: PGTYPE, payload: pgType });

    if (pgType) {
      contact_form.validateFields().then((contactData) => {
        guestDetailsForm
          .validateFields()
          .then((paxData) => {
            // setContactData(contactData);
            setPaxData(paxData);

            if (agent) {
              handlePackageBooking(contactData, pgType, paxData);
            } else {
              ValidateBookingLimit(contactData);
            }
          })
          .catch((e) => {
            guestDetailsForm.scrollToField(e.errorFields[0].name);
          });
      });
    }
  };

  const OnPaymentModeSelection = (e) => {
    if (e.target.value == 1) {
      setSeletedPayOption(true);
    } else {
      setSeletedPayOption(false);
    }
  };

  const getUpdatePlaceHolderbyTourName = (tourName, type ) => {
    if (type === "PLACEHOLDER") {
      if (tourName.includes ("Pick Up"))
      return "Drop off"
    else if (tourName.includes("Drop Off"))
      return "Pick Up"
    else return "Pick Up"
    } else {
      if (tourName.includes("Pick Up"))
      return "Pick Up"
      else if (tourName.includes("Drop Off"))
        return "Drop off"
      else return "Pick Up"
    }
  }

  const getTimebyUser = (optionData) => {
    if ((optionData.cityTourType.includes('Airport') && optionData.transferName === "Private Transfers"))
      return 1;
    else if ((optionData.cityTourType.includes('Transfer') && optionData.transferName === "Private Transfers"))
      return 0;
    else
      return -1
  };

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section activites-cheout-nav">
          <Content className="admin-container bar-admin-width-quo">
            <Row>
              <Col md={24} xs={24}>
                <p className="nextactivity">
                  <a href="#">
                    <i className="fa fa-arrow-left"></i>&nbsp; Go back and
                    select another Activity
                  </a>
                </p>
              </Col>
            </Row>
          </Content>
        </div>

        <div className="manage-markup-section">
          <Content className="admin-container bar-admin-width-quo">
            <Row className="mobile-row-res">
              <Col md={14} xs={24} className="mobile-offset corner-left">
                <Form
                  layout="vertical"
                  form={contact_form}
                  scrollToFirstError={true}
                >
                  <Card className="checkout-activity-card">
                    <div>
                      <h5 className="booking-option-cart">
                        LEAD PASSENGER DETAILS
                      </h5>
                    </div>
                    <Row gutter={(8, 8)} className="form-rules">
                      <Col md={4} xs={24}>
                        <Form.Item
                          label="Title"
                          rules={[{ required: true }]}
                          name="prefix"
                        >
                          <Select placeholder="Mr">
                            <Select.Option value="Mr">Mr</Select.Option>
                            <Select.Option value="MRS">Mrs.</Select.Option>
                            <Select.Option value="Ms">Ms.</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={10} xs={24}>
                        <Form.Item
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your First Name",
                            },
                          ]}
                          name="firstName"
                        >
                          <Input
                            className="doument-name"
                            placeholder="As shown on passport"
                            size={"large"}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={10} xs={24}>
                        <Form.Item
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your family Name",
                            },
                          ]}
                          name="lastName"
                        >
                          <Input
                            className="doument-name"
                            placeholder="As shown on passport"
                            size={"large"}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Email"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter",
                            },
                          ]}
                          name="email"
                        >
                          <Input
                            className="doument-name"
                            placeholder="Please Enter"
                            size={"large"}
                          />
                        </Form.Item>
                      </Col>

                      {/* <Col md={8} xs={24}>
                        <Form.Item
                          label="Country"
                          rules={[{ required: true }]}
                          name="nationality"
                        >
                          <Select
                            showSearch
                            placeholder="Select Country"
                            style={{ width: "100%" }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {CountryList.map((item) => (
                              <Option key={item.name} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col> */}

                      <Col md={12} xs={24}>
                        <Form.Item
                          label="Phone Number"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter",
                            },
                          ]}
                          name="mobile"
                        >
                          <Input className="doument-name" size={"large"} />
                        </Form.Item>
                      </Col>

                      <Col md={24} xs={24}>
                        <Form.Item>
                          <TextArea rows={4} placeholder="Special Request" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>

                  <Card className="my-2">
                    <div>
                      <h5 className="booking-option-cart">PAN DETAILS</h5>
                    </div>
                    <PanVerification serviceType={5} setPanVerificationNumber={setPanVerificationNumber}/>
                  </Card>

                  <Card className="details-offer-card checkout-activity-card">
                    <div>
                      <h5 className="booking-option-cart">EXTRA DETAILS</h5>
                    </div>

                    <Row gutter={(8, 8)}>
                      {quotation?.TourDetails?.length > 0
                        ? quotation.TourDetails.map((tour, key) =>
                            tour.map((option, i) => {
                            return (
                              <>
                             {option.transferName !== "Without Transfers" && (
                              <Col key={key + i} md={12} xs={24}>
                                <Form.Item
                                  label={option?.tourName}
                                  rules={[
                                    {
                                      required: true,
                                        // option?.transferName ===
                                        // "Without Transfers"
                                        //   ? false
                                        //   : true,
                                      message: "Please Enter",
                                    },
                                  ]}
                                  name={`pickup-${option.tourId}-${option.optionId}-${i}`}
                                >
                                  <Input
                                    className="doument-name"
                                    // placeholder="Pickup Location"
                                    placeholder={`${getUpdatePlaceHolderbyTourName(option?.tourName, "PLACEHOLDER")} Location`}
                                    size={"large"}
                                  />
                                </Form.Item>
                              </Col>
                                )}
                                {getTimebyUser(option) != -1 && (
                                  <>
                                    <Col md={12} xs={24}>
                                      <Form.Item
                                        label={"Flight/Train Name"}
                                        rules={[{
                                          required: true,
                                          message: "Please Enter Flight/Train Name",
                                        }
                                        ]}
                                        name={"flightname" + key}
                                      >
                                        <Input
                                          className="doument-name"
                                          placeholder={`Ex:- AirIndia, Vistara, Indigo...`}
                                          size={"large"}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24}>
                                      <Form.Item
                                        label={"Flight/Train Number"}
                                        rules={[{
                                          required: true,
                                          message: "Please Enter Flight/Train Number",
                                        },
                                        ]}
                                        name={"flightnumber" + key}
                                      >
                                        <Input
                                          className="doument-name"
                                          placeholder={`Ex:- AI-860, UK-979, 6E2117...`}
                                          size={"large"}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24}>
                                      <Form.Item
                                        label={"Check-In Luggage 23Kg/per Pax"}
                                        rules={[{
                                          required: false,
                                          message: "Please Choose Number of Luggage ",
                                        },
                                        ]}
                                        name={"checkInLuggage" + key}
                                      >
                                        <Select
                                          defaultValue={0}
                                        //  style={{ width: 200 }}
                                        //  onChange={handleChange}
                                        >
                                          {[...Array(10)].map((_, i) => (
                                            <Option key={i} value={i}>{i}</Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col md={12} xs={24}>
                                      <Form.Item
                                        label={"Hand Luggage 7Kg/Per Pax "}
                                        rules={[{
                                          required: false,
                                          message: "Please Choose Number of Luggage",
                                        },
                                        ]}
                                        name={"handluggages" + key}
                                      >
                                        <Select
                                          defaultValue={0}
                                        //  style={{ width: 200 }}
                                        //  onChange={handleChange}
                                        >
                                          {[...Array(10)].map((_, i) => (
                                            <Option key={i} value={i}>{i}</Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  </>
                                )}
                              </>
                            );
                          }
                          )
                        ) : null
                      }
                    </Row>
                  </Card>

                  {hotelAllDetails.length > 0 ? (
                    <Card className="details-offer-card checkout-activity-card">
                      {loading ? (
                        <Skeleton active />
                      ) : (
                        hotelAllDetails.map((hotelPkg, index) => (
                          <Form
                            form={guestDetailsForm}
                            scrollToFirstError={true}
                            layout="vertical"
                          >
                            <div>
                              <div>
                                <h5 className="booking-option-cart">
                                  {hotelPkg.HotelName}
                                </h5>
                              </div>

                              {hotelPkg?.hotelPriceRes?.roomGuestInfo.map(
                                (roomsObj, roomIndex) => (
                                  <div
                                    className="form-body-room-content hotel-checkout-mt"
                                    key={roomIndex}
                                  >
                                    <div className="room-header d-flex align-item-center justify-content-between">
                                      <div className="room-title font-weight-bold">
                                        <span>Room : {1 + roomIndex}</span>
                                      </div>
                                      <div className="room-para">
                                        <span>
                                          {Object.keys(
                                            hotelPkg?.hotelPriceRes
                                              ?.hotelPriceData
                                          ).length > 0 ? (
                                            <>
                                              {getAdultChildCount(
                                                roomsObj.paxInfoList
                                              )}
                                            </>
                                          ) : null}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="room-internal-form">
                                      <div className="room-body">
                                        <div className="room-body-form">
                                          {roomsObj.paxInfoList.map(
                                            (pax, paxIndex) => (
                                              <>
                                                <p className="guestsType mb-1 font-weight-bold">
                                                  {pax.guestType === "Adult"
                                                    ? "Adult"
                                                    : "Child"}{" "}
                                                </p>
                                                <Row gutter={[16, 16]}>
                                                  <Col md={3} sm={12} xs={24}>
                                                    {pax.guestType ===
                                                    "Adult" ? (
                                                      <Form.Item
                                                        name={`title-${index}-${roomIndex}-${paxIndex}`}
                                                        label="Title"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: "Required",
                                                          },
                                                        ]}
                                                      >
                                                        <Select
                                                        // onChange={(val) => {
                                                        //   handlePaxField(
                                                        //     val,
                                                        //     hotelPkg.roomsId,
                                                        //     roomIndex,
                                                        //     paxIndex,
                                                        //     "title"
                                                        //   );
                                                        // }}
                                                        >
                                                          <Option value="Mr">
                                                            Mr
                                                          </Option>
                                                          <Option value="Ms">
                                                            Ms
                                                          </Option>
                                                          <Option value="Mrs">
                                                            Mrs
                                                          </Option>
                                                        </Select>
                                                      </Form.Item>
                                                    ) : (
                                                      <Form.Item
                                                        name={`title-${index}-${roomIndex}-${paxIndex}`}
                                                        label="Title"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: "Required",
                                                          },
                                                        ]}
                                                      >
                                                        <Select
                                                        // onChange={(val) => {
                                                        //   handlePaxField(
                                                        //     val,
                                                        //     hotelPkg.roomsId,
                                                        //     roomIndex,
                                                        //     paxIndex,
                                                        //     "title"
                                                        //   );
                                                        // }}
                                                        >
                                                          <Option value="Mstr">
                                                            Mstr
                                                          </Option>
                                                        </Select>
                                                      </Form.Item>
                                                    )}
                                                  </Col>

                                                  <Col md={8} sm={12} xs={24}>
                                                    <Form.Item
                                                      label="First Name"
                                                      name={`firstname-${index}-${roomIndex}-${paxIndex}`}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: "Required",
                                                        },
                                                      ]}
                                                    >
                                                      <Input
                                                      // onChange={(e) => {
                                                      //   handlePaxField(
                                                      //     e.target.value,
                                                      //     hotelPkg.roomsId,
                                                      //     roomIndex,
                                                      //     paxIndex,
                                                      //     "firstName"
                                                      //   );
                                                      // }}
                                                      />
                                                    </Form.Item>
                                                  </Col>

                                                  <Col md={8} sm={12} xs={24}>
                                                    <Form.Item
                                                      label="Last Name"
                                                      name={`lastname-${index}-${roomIndex}-${paxIndex}`}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: "Required",
                                                        },
                                                      ]}
                                                    >
                                                      <Input
                                                      // onChange={(e) => {
                                                      //   handlePaxField(
                                                      //     e.target.value,
                                                      //     hotelPkg.roomsId,
                                                      //     roomIndex,
                                                      //     paxIndex,
                                                      //     "lastName"
                                                      //   );
                                                      // }}
                                                      />
                                                    </Form.Item>
                                                  </Col>
                                                  <Col md={5} sm={12} xs={24}>
                                                    {pax.guestType ===
                                                    "Child" ? (
                                                      <Form.Item
                                                        name={`age-${index}-${roomIndex}-${paxIndex}`}
                                                        label="Child Age"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: "Required",
                                                          },
                                                        ]}
                                                      >
                                                        <Select
                                                        // onChange={(val) => {
                                                        //   handlePaxField(
                                                        //     val,
                                                        //     hotelPkg.roomsId,
                                                        //     roomIndex,
                                                        //     paxIndex,
                                                        //     "age"
                                                        //   );
                                                        // }}
                                                        >
                                                          {[...Array(18)].map(
                                                            (_, i) => (
                                                              <Option
                                                                key={i + "age"}
                                                                value={i}
                                                              >
                                                                {i} Years
                                                              </Option>
                                                            )
                                                          )}
                                                        </Select>
                                                      </Form.Item>
                                                    ) : (
                                                      <Form.Item
                                                        name={`age-${index}-${roomIndex}-${paxIndex}`}
                                                        label="Age"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: "Required",
                                                          },
                                                        ]}
                                                      >
                                                        <Select
                                                        // onChange={(val) => {
                                                        //   handlePaxField(
                                                        //     val,
                                                        //     hotelPkg.roomsId,
                                                        //     roomIndex,
                                                        //     paxIndex,
                                                        //     "age"
                                                        //   );
                                                        // }}
                                                        >
                                                          {[...Array(83)].map(
                                                            (_, i) => (
                                                              <Option
                                                                key={
                                                                  i + 18 + "age"
                                                                }
                                                                value={i + 18}
                                                              >
                                                                {i + 18} Years
                                                              </Option>
                                                            )
                                                          )}
                                                        </Select>
                                                      </Form.Item>
                                                    )}
                                                  </Col>
                                                  {pax.hasOwnProperty(
                                                    "pan"
                                                  ) && (
                                                    <Col md={8} sm={12} xs={24}>
                                                      <Form.Item
                                                        label="PAN Number"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: "Required",
                                                          },
                                                        ]}
                                                        name={`pan-${index}-${roomIndex}-${paxIndex}`}
                                                      >
                                                        <Input
                                                          className="inputbg"
                                                          placeholder="PAN Number"
                                                          // onChange={(e) =>
                                                          //   handlePaxField(
                                                          //     e.target.value,
                                                          //     hotelPkg.roomsId,
                                                          //     roomIndex,
                                                          //     paxIndex,
                                                          //     "pan"
                                                          //   )
                                                          // }
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                  )}

                                                  {pax.hasOwnProperty(
                                                    "passportNo"
                                                  ) && (
                                                    <Col md={8} sm={12} xs={24}>
                                                      <Form.Item
                                                        label="Passport Number"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: "Required",
                                                          },
                                                        ]}
                                                        name={`passportNo-${index}-${roomIndex}-${paxIndex}`}
                                                      >
                                                        <Input
                                                          className="inputbg"
                                                          placeholder="Passport Number"
                                                          // onChange={(e) =>
                                                          //   handlePaxField(
                                                          //     e.target.value,
                                                          //     hotelPkg.roomsId,
                                                          //     roomIndex,
                                                          //     paxIndex,
                                                          //     "passportNo"
                                                          //   )
                                                          // }
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                  )}
                                                  {pax.hasOwnProperty(
                                                    "passportDOI"
                                                  ) && (
                                                    <Col md={8} sm={12} xs={24}>
                                                      <Form.Item
                                                        label="Passport DOI"
                                                        className="passport-dates"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: "Required",
                                                          },
                                                        ]}
                                                        name={`passportDOI-${index}-${roomIndex}-${paxIndex}`}
                                                      >
                                                        <DatePicker
                                                          // onChange={(
                                                          //   date,
                                                          //   dateString
                                                          // ) =>
                                                          //   handlePaxField(
                                                          //     dateString,
                                                          //     hotelPkg.roomsId,
                                                          //     roomIndex,
                                                          //     paxIndex,
                                                          //     "passportDOI"
                                                          //   )
                                                          // }

                                                          format={"YYYY-MM-DD"}
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                  )}
                                                  {pax.hasOwnProperty(
                                                    "passportDOE"
                                                  ) && (
                                                    <Col md={8} sm={12} xs={24}>
                                                      <Form.Item
                                                        label="Passport DOE"
                                                        className="passport-dates"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message: "Required",
                                                          },
                                                        ]}
                                                        name={`passportDOE-${index}-${roomIndex}-${paxIndex}`}
                                                      >
                                                        <DatePicker
                                                          // onChange={(
                                                          //   date,
                                                          //   dateString
                                                          // ) =>
                                                          //   handlePaxField(
                                                          //     dateString,
                                                          //     hotelPkg.roomsId,
                                                          //     roomIndex,
                                                          //     paxIndex,
                                                          //     "passportDOE"
                                                          //   )
                                                          // }

                                                          format={"YYYY-MM-DD"}
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                  )}
                                                </Row>
                                              </>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}

                              <Row>
                                <Col md={24} xs={24}>
                                  <p className="info-note">
                                    <b>
                                      Important Note: we require lead passenger
                                      name for the each and every room at hotel
                                    </b>
                                    Dubai
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        ))
                      )}
                    </Card>
                  ) : null}

                  {user?.Role?.RoleId != 4 && PartPayCapable == true ? (
                    <Card className="details-offer-card checkout-activity-card" id="PartPaymentAviality">
                      <Form.Item
                        label="SELECT SUITABLE OPTION TO PROCEED FURTHER FOR PAYMENT"
                        name="paymentOption"
                        rules={[
                          {
                            required: true,
                            message: "Please Choose Option",
                          },
                        ]}
                      >
                        <Radio.Group size="large">
                          <Radio
                            value={2}
                            onChange={(e) => {
                              getPartPayment(e.target.value, {
                                BusinessType: user?.Role?.RoleId,
                                ServiceType: 5,
                              });
                              setIsPartPayment(true);
                            }}
                          >
                            Part Payment
                          </Radio>
                          <Radio
                            value={1}
                            onChange={(e) => {
                              setIsPartPayment(false);
                              getPartPayment(e.target.value);
                            }}
                          >
                            Full Payment
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Card>
                  ) : null}
                  
                </Form>
                <Card className="details-offer-card checkout-activity-card">
                  <Form layout="vertical">
                    <Form.Item>
                      <Checkbox>
                        By Clicking Pay Now You agree that you have read and
                        understood our Terms & Conditions
                      </Checkbox>
                    </Form.Item>

                    {pgDetails.pgDisplay == false ? (
                      <Button
                        className="book-btn"
                        type="primary"
                        onClick={() => {
                          goTo();
                        }}
                      >
                        {isLoading ? (
                          <Spin indicator={antIcon} />
                        ) : (
                          "Book and Pay "
                        )}
                      </Button>
                    ) : (
                      // <PaymentGateway data={pgData} />
                      <PayGateway
                        blockApiReq={blockApiReq}
                        loadingSpin={loadingSpin}
                        OnPaymentModeSelection={OnPaymentModeSelection}
                      />
                    )}
                  </Form>
                </Card>
              </Col>

              <Col md={10} xs={24} className="mobile-offset total-card-bar">
                <PackageGrandTotalCard
                  quotation={quotation}
                  loading={loading}
                  hotelAllDetails={hotelAllDetails}
                  isPartPayment={isPartPayment}
                  SeletedPayOption={SeletedPayOption}
                />
              </Col>
            </Row>
          </Content>
        </div>
      </div>

      {verifyModalVisible && (
        <VerifyOtpModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyOtp={handleVerifyOtp}
          handleResendOtp={handleSendOTP}
          // mobile={contactData?.mobile}
          contactData={contactData}
        />
      )}
    </div>
  );
};
export default Quotationcheckout;
