import React, { useEffect, useState } from "react";
import moment from "moment";
import ApiClient from "../../../helpers/ApiClient";
import { Col, Row, Rate } from "antd";
import { Link } from "react-router-dom";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";

const BASE = process.env.REACT_APP_BASE_URL;

const TopCities = ({ length = false }) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [cityActivitiesData, setCityActivitiesData] = useState([]);
  useEffect(() => {
    getCityActivitiesList();
  }, []);

  const getCityActivitiesList = () => {
    ApiClient.get("extranet/getAllActivities")
      .then((res) => {
        if (res.status === 200) {
          let itemLength = length ? length : res.data.length;
          let data = res.data.filter(
            (item, index) =>
              item.showOnHomePage === 1 &&
              item.status === 1 
              // && index <= itemLength
          );
          setCityActivitiesData(data);
        } else {
          setCityActivitiesData([]);
        }
      })
      .catch((e) => {
        setCityActivitiesData([]);
      });
  };
  const getActivitiesUrl = (item) => {
    if (item) {
      let travelDate = moment().add(1, "days").format("YYYY-MM-DD");
      let params = `city=${item.cityName}-${item.cityId}&country=${item.countryName}-${item.countryId}&travelDate=${travelDate}`;
      return `/activities/results?${params}`;
    } else {
      return "";
    }
  };

  return cityActivitiesData.length > 0 ? (
    <div className="promo providerapi ">
      <div className="holidays-search-apps activities-home-page">
        <div className="admin-container cms-pages-width text-center">
          <h4 className="popular-clr-name">Popular Destination</h4>
          <Row gutter={[12, 12]}>
            {cityActivitiesData.map((item, i) => (
              <Col key={i + "activities" + item.tourId} md={6} xs={24}>
                <Link
                  to={getActivitiesUrl(item)}
                  className="popular-cities-link"
                >
                  <img
                    className="cart-shopping-img-1"
                    src={
                      item?.imagePath
                        ? `${BASE}${item.imagePath.substring(1)}`
                        : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                            .default
                    }
                    alt={item.tourName}
                  />

                  <p className="text-center font-weight-bold mb-0">
                    {`${item.cityName}, ${item.tourName}`}
                  </p>
                  <div className="mb-0 text-left" style={{ display: "inline" }}>
                    <p className="mb-0 font-weight-bold"> From</p>
                    <div className="d-flex justify-content-between">
                      <span className="font-weight-bold">
                        {`${currencyValue(item.amount)} ${activeCurrency}`}
                      </span>
                      <span>
                        <Rate
                          className="starRating"
                          disabled
                          value={Number(item.rating)}
                          allowHalf={true}
                        />
                      </span>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  ) : null;
};

export default TopCities;
