import React, { useState, useEffect } from "react";
import { Button, Card, Radio, Input, Select } from "antd";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import "./hotelSort.scss";
import queryString from "query-string";

const { Option } = Select;
const HotelSort = ({
  listOfHotels,
  setListOfHotels,
  showNetFare,
  setShowNetFare,
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();

  const [sorting, setSorting] = useState("price");
  const [searchBy, setSearchBy] = useState("hotel");
  const [sortDirection, setSortDirection] = useState("asc");
  const [HotelName, setHotelName] = useState(null)
  const handleChange = (e) => {
    const selSorting = e.target.value;
    setSorting(selSorting);
    setSortDirection("asc");
    sortHotels(selSorting, "asc");
  };

  const handleClick = (e) => {
    const selSorting = e.target.value;
    if (sorting === selSorting) {
      let dir = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      sortHotels(selSorting, dir);
    }
  };

  useEffect(() => {
    sortHotels("price", "asc");
    let searchData = window.location.search.split("=string")[1];
    let HotelName = decodeURIComponent(searchData).replace(/#/g, '')
    HotelName != '' && HotelName != null ? setHotelName(HotelName) : setHotelName(null)
  }, []);

  useEffect(() => {
        const HotelList = listOfHotels;
        let filtered;
        if(HotelName !== null){
          filtered = listOfHotels.filter(hotel => hotel?.hotelName?.toLowerCase() === HotelName?.toLowerCase());
          setListOfHotels(filtered);
        }else{
          setListOfHotels(HotelList);
        }
  }, [HotelName, listOfHotels]);

  const sortHotels = (selSorting, dir) => {
    let newlist = listOfHotels.sort((hotelA, hotelB) => {
      if (selSorting === "name") {
        return dir === "asc"
          ? compareHotels(hotelA.hotelName, hotelB.hotelName, selSorting)
          : compareHotels(hotelB.hotelName, hotelA.hotelName, selSorting);
      } else if (selSorting === "rating") {
        return dir === "asc"
          ? compareHotels(hotelA.starRating, hotelB.starRating, selSorting)
          : compareHotels(hotelB.starRating, hotelA.starRating, selSorting);
      } else if (selSorting === "price") {
        return dir === "asc"
          ? compareHotels(
              Math.round(hotelA.hotelMinPrice),
              Math.round(hotelB.hotelMinPrice),
              selSorting
            )
          : compareHotels(
              Math.round(hotelB.hotelMinPrice),
              Math.round(hotelA.hotelMinPrice),
              selSorting
            );
      }
    });

    setListOfHotels([...newlist]);
  };

  const compareHotels = (a, b, selSorting) => {
    if (selSorting === "name" || selSorting === "price") {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return a - b;
    }
  };

  const getSortIcon = (val) => {
    return val === "asc" ? <CaretUpOutlined /> : <CaretDownOutlined />;
  };

  const onSearch = (val) => {
    applyFilters(val);
  };

  const applyFilters = (text) => {
    let visibleCount = 0;

    let data = listOfHotels.map((hotel) => {
      let isVisible = true;
      if (
        hotel.hotelName.toLowerCase().indexOf(text.toLowerCase()) === -1 &&
        hotel.addresses[0].address.toLowerCase().indexOf(text.toLowerCase()) ===
          -1
      ) {
        isVisible = false;
      }

      isVisible && visibleCount++;

      return { ...hotel, isVisible: isVisible };
    });
    data = data.filter((item) => item.isVisible);
    setListOfHotels(data);
  };

  return (
    <div className="hotel-sort-block sort-block">
      <Card>
        <div className="results-sort-block">
          <div className="flex-wrapper">
            <p className="sort-text">Sort by:</p>
            <Radio.Group onChange={handleChange} value={sorting}>
              <Radio value={"name"} onClick={handleClick}>
                <span className="checkmark">
                  <div className="active-background">
                    <p className="price-type">
                      <span>
                        {sorting === "name" ? getSortIcon(sortDirection) : ""}
                      </span>
                      Name
                    </p>
                  </div>
                </span>
              </Radio>
              <Radio value={"price"} onClick={handleClick}>
                <span className="checkmark">
                  <div className="active-background">
                    <p className="price-type">
                      <span>
                        {sorting === "price" ? getSortIcon(sortDirection) : ""}
                      </span>
                      Price
                    </p>
                    {/* <p className="total-fare-filter">
                    
                  </p> */}
                  </div>
                </span>
              </Radio>
              <Radio value={"rating"} onClick={handleClick}>
                <span className="checkmark">
                  <div className="active-background">
                    <p className="price-type">
                      <span>
                        {sorting === "rating" ? getSortIcon(sortDirection) : ""}
                      </span>
                      Rating
                    </p>
                  </div>
                </span>
              </Radio>
            </Radio.Group>
          </div>
          <div className="flex-wrapper" style={agent && user?.Role?.RoleLevel === 3 ? {width: "60%" } : {width : "50%"}}>
            <Input.Group compact>
              <Select
                defaultValue="hotel"
                className="search-type-box"
                onSelect={(val) => setSearchBy(val)}
              >
                <Option value="hotel">
                  <i className="fa fa-building-o" aria-hidden="true"></i>
                </Option>

                <Option value="loc">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                </Option>
              </Select>

              <Select
                showSearch
                className="search-select-box"
                placeholder={`Search By ${
                  searchBy === "hotel" ? "Name" : "Location"
                }`}
                onSelect={onSearch}
                value={HotelName}
                allowClear={true}
                onClear={() => {
                  onSearch("")
                  setHotelName(null)
                }}
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {listOfHotels.map((hotel, i) => {
                  return searchBy === "hotel" ? (
                    <Option key={"byName" + i} value={hotel.hotelName}>
                      <i className="fa fa-building-o" aria-hidden="true"></i>{" "}
                      {hotel.hotelName}
                    </Option>
                  ) : (
                    <Option
                      key={"byLoc" + i}
                      value={hotel.addresses[0].address}
                    >
                      <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                      {hotel.addresses[0].address}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>

            {agent && user?.Role?.RoleLevel === 3 ? (
              <div
                className="netfareButton ml-1"
                onClick={() => setShowNetFare(!showNetFare)}
              >
                <Button> {showNetFare ? "Hide" : "Show"} Net Fare</Button>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default HotelSort;
