import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Col, Row, Layout, Rate, DatePicker, Form, Button } from "antd";
import moment from "moment";
import queryString from "query-string";
import ApiClient from "../../helpers/ApiClient";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import { useSytContext } from "../../common/providers/SytProvider";
import Banner from "../../components/banner/Banner";
import ActivitiesAutoComplete from "../../common/AutoCompleteSelect/ActivitiesAutoComplete";

const Sastaeurope = (props) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const { activityBanners } = useSytContext();
  const BASE = process.env.REACT_APP_BASE_URL;
  const { Content } = Layout;
  const [form] = Form.useForm();

  const city = useRef(null);
  const dateBox = useRef(null);

  const oriDateString = "YYYY-MM-DD";
  const dateFormat = "YYYY-MM-DD";

  const [cityActivitiesData, setCityActivitiesData] = useState([]);
  let history = useHistory();

  const onSubmitForm = (val) => {
    let countryCities = val.activitiesCityCode;

    let query = {
      country: countryCities.split("&&")[0],
      city: countryCities.split("&&")[1],
      travelDate: moment(val.journeyDate).format(oriDateString),
    };

    let queryParams = queryString.stringify(query);

    if (props.modify) {
      history.replace("/activities/results?" + queryParams);
      props.onModify();
    } else {
      history.push("/activities/results?" + queryParams);
    }
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  useEffect(() => {
    if (props.modify) {
      const activitiesSearchParams = queryString.parse(window.location.search);

      form.setFieldsValue({
        activitiesCityCode: `${activitiesSearchParams.country}&&${activitiesSearchParams.city}`,
        journeyDate: moment(activitiesSearchParams.travelDate),
      });
    }
  }, []);

  const handleOnSubmit = (ref) => {
    ref.current.focus();
  };

  const getCityActivitiesList = () => {
    ApiClient.get("extranet/getAllActivities")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter(
            (item) =>
              item.showOnHomePage === 1 &&
              item.continent === 3 &&
              item.status === 1
          );
          setCityActivitiesData(data);
        } else {
          setCityActivitiesData([]);
        }
      })
      .catch((e) => {
        setCityActivitiesData([]);
      });
  };

  useEffect(() => {
    getCityActivitiesList();
  }, []);

  return (
    <div className="">
      <div className="bus-block">
        <div className="bus-banner">
          <Banner banner={activityBanners} />
        </div>
        <div className="bus-search-header">
          <h2>Activities & Things To Do Around the World</h2>
        </div>
        <div className="buses-search">
          <div className="hotel_search acitiv-pans">
            <Form form={form} onFinish={onSubmitForm}>
              <Row className="search-row activity-cols-num" gutter={16}>
                <Col
                  md={14}
                  xs={24}
                  className="from-to-inputs new-activity-pro br-right"
                >
                  <ActivitiesAutoComplete
                    formItemProps={{
                      label: "City",
                      name: "activitiesCityCode",
                      rules: [
                        { required: true, message: "City Name is required" },
                      ],
                    }}
                    selectProps={{
                      size: "large",
                      placeholder: "Search by Destination",
                    }}
                    api={"activities/sastaEuropeCityAutosuggestion/"}
                    refName={city}
                    focusRef={dateBox}
                    handleOnSubmit={handleOnSubmit}
                    modifySearch={props.modify ? props.modify : false}
                  />
                </Col>
                <Col md={6} xs={24} className="from-to-inputs new-activity-pro">
                  <Form.Item
                    name="journeyDate"
                    rules={[{ required: true }]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      allowClear={false}
                      ref={dateBox}
                      size="large"
                      className="search-inputs journeyDate"
                      placeholder="Journey Date"
                      format={dateFormat}
                      disabledDate={disabledOriginDate}
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={24} className="new-activity-pro">
                  <Form.Item className="mr-none">
                    <Button
                      size="large"
                      className="primary-btn train-search-btn button-promo-activity"
                      htmlType="submit"
                    >
                      <SearchOutlined />
                      Search
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>

      <section className="bus-support-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  {/* <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p> */}
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts1.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  {/* <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {cityActivitiesData.length > 0 ? (
        <div className="promo providerapi ">
          <Layout className="holidays-search-apps activities-home-page">
            <div className="">
              <Content className="admin-container cms-pages-width text-center">
                <h4 className="popular-clr-name">
                  Popular Destination In Europe
                </h4>
                <Row gutter={[12, 12]} className="pb-3">
                  {cityActivitiesData.map((item) => (
                    <Col md={8} xs={24}>
                      {/* <Link to={getActivitiesUrl(item.SearchURL)}> */}
                      <img
                        className="cart-shopping-img-1"
                        src={
                          item?.tourImages
                            ? `${BASE}${item.tourImages[0]?.imagePath.substring(
                                1
                              )}`
                            : require("../../assets/images/hotels/hotel-fil-2.jpg")
                                .default
                        }
                        alt={item.tourName}
                      />
                      {/* </Link> */}
                      <p className="text-center font-weight-bold">
                        {`${item.cityName}, ${item.tourName}`}
                      </p>
                      <p
                        className="mb-0 text-left"
                        style={{ display: "inline" }}
                      >
                        <span className="w-100 float-left"> From</span>
                        <span className="font-weight-bold w-50 float-left">
                          {" "}
                          {`${currencyValue(item.amount)} ${activeCurrency}`}
                        </span>
                        <span className="font-weight-bold float-right">
                          <Rate
                            className="starRating"
                            disabled
                            value={Number(item.rating)}
                            allowHalf={true}
                          />
                        </span>
                      </p>
                    </Col>
                  ))}
                </Row>
              </Content>
            </div>
          </Layout>
        </div>
      ) : null}
    </div>
  );
};

export default Sastaeurope;
