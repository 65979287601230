import React, { useContext, useState } from "react";
import { Input, Button, Form, Col, Row, message } from "antd";
import GlobalStatesContext from "../../../common/providers/GlobalStatesContext";
import ApiClient from "../../../helpers/ApiClient";

const PanVerification = (props) => {
  const {
    state: {
      otherData: { PanVerificationDetails },
    },
    PanVerification,
  } = useContext(GlobalStatesContext);
  const [panNumber, setPanNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePanVerification = () => {
    if(props.serviceType == 2) {
      PanVerification({
        pan_number: panNumber,
        roomIndex:props.roomIndex,
        paxIndex:props.paxIndex
      });
    }else{
      setLoading(true);
      ApiClient.post('hotels-v2/verificationPan/', {pan_number: panNumber}).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          props.setPanVerificationNumber(res.data[0].pan_number)
          message.success({
            content: `${res.data[0].first_name} ${res.data[0].last_name} Your ${res.message}`,
            duration: 2,
          });
        }else {
          message.error({
            content: res.message,
            duration: 2,
          });
        }
      }).catch((error) => {
        setLoading(false);
        console.error("Error: ", error);
        message.error({
          content: error.message,
          duration: 2,
        });
       });
    }
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col md={16} sm={12} xs={12}>
          <Form.Item
            label="Pan Number"
            name={props.serviceType == 2 ? `pan_number${props.roomIndex}_${props.paxIndex}`: `pan_number` }
            // name={`pan_number${props.roomIndex}_${props.paxIndex}`}
            rules={[{ required: props.serviceType == 2 ? true : false, message: "Required" }]}
          >
            <Input
              className="inputbg"
              placeholder="Enter Your Pan Number"
              autoComplete="off"
              onChange={(e) => setPanNumber(e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col md={8} sm={12} xs={12}>
          <Form.Item>
            <Button
              className="mt-4"
              type="primary"
              loading={loading}
              onClick={handlePanVerification}
            >
              Apply
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default PanVerification;
