import {
  AccountBookOutlined,
  ApiOutlined,
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  BuildOutlined,
  CalendarOutlined,
  CloseSquareOutlined,
  ContainerOutlined,
  LayoutOutlined,
  DisconnectOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  FormOutlined,
  IssuesCloseOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PushpinOutlined,
  SecurityScanOutlined,
  SelectOutlined,
  SendOutlined,
  SettingOutlined,
  ShopOutlined,
  SplitCellsOutlined,
  SwitcherOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  BlockOutlined,
  MergeCellsOutlined,
  GoldOutlined,
  HomeOutlined,
  QrcodeOutlined
} from "@ant-design/icons";
import { Button, Col, Layout, Menu, Row } from "antd";
import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import AdminNavigations from "../common/navbar/AdminNavigations";
import ProtectedMenu from "../helpers/ProtectedMenu";
import "./Adminstyles.scss";
import { useSytContext } from "../common/providers/SytProvider";
// import SideBarComponent from "./SideBarComponent";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const Administration = ({ location, accessList, accessToken, resetIsLogin }) => {
  const url = "";
  const { logo } = useSytContext();

  const BASE = process.env.REACT_APP_BASE_URL;

  let history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const menuToggle = () => {
    setCollapsed((prev) => !prev);
  };

  const AdminLogout = () => {
    resetIsLogin();
    history.push("/");
  };

  return (
    <div id="admin">
      <Layout>
        <Sider trigger={null} style={{ minHeight: "100vh" }} width={"220px"} breakpoint="lg" collapsible collapsed={!collapsed} defaultCollapsed={false}>
          <div className="logo">
            <Link to="/">{logo ? <img src={BASE + logo.substring(1)} alt="SYT_Logo" /> : <img src={require("../assets/images/logos/trip-logo.png").default} alt="shopyourtrip" />}</Link>
          </div>
          <Menu theme="dark" mode="inline" selectedKeys={url}>
            <Menu.Item key="/admin/dashboard" icon={<BarChartOutlined />}>
              <Link to="/admin/dashboard">Dashboard</Link>
            </Menu.Item>
            <SubMenu key="sub1" icon={<UserOutlined />} title="Masters" className="left-menu-decre">
              <ProtectedMenu accessList={accessList} key="/admin/role" icon={<UserOutlined />}>
                <Link to="/admin/role">Roles</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/user" icon={<TeamOutlined />}>
                <Link to="/admin/user">Admin Users</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/accesspermissions" icon={<UserSwitchOutlined />}>
                <Link to="/admin/accesspermissions">Access Permissions</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/agentregistration" icon={<UserOutlined />} className="individual-menu">
                <Link to="/admin/agentregistration">Site Agents</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/siteusers" icon={<UserOutlined />}>
                <Link to="/admin/siteusers">Site Users</Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/deals" icon={<LayoutOutlined />}>
                <Link to="/admin/deals">Deals</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/cityhotel" icon={<ContainerOutlined />}>
                <Link to="/admin/cityhotel">City Hotel</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/manageairlines" icon={<SendOutlined />}>
                <Link to="/admin/manageairlines">Manage Airlines</Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/operator" icon={<UsergroupAddOutlined />}>
                <Link to="/admin/operator">Operators</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/membershipinfo" icon={<UserSwitchOutlined />} className="individual-menu">
                <Link to="/admin/membershipinfo">Membershipinfo</Link>
              </ProtectedMenu>

              {/*  <ProtectedMenu
                accessList={accessList}
                key="/admin/busoperator"
                icon={<UserSwitchOutlined />}
              >
                <Link to="/admin/busoperator">Bus Operators</Link>
              </ProtectedMenu> */}

              <ProtectedMenu accessList={accessList} key="/admin/provider" icon={<AuditOutlined />}>
                <Link to="/admin/provider">Providers</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/apiprovider" icon={<ApiOutlined />}>
                <Link to="/admin/apiprovider">API Providers</Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/blog" icon={<BlockOutlined />}>
                <Link to="/admin/blog">Blog</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/commentsblog" icon={<BlockOutlined />}>
                <Link to="/admin/commentsblog">Blog Comments</Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/banners" icon={<SwitcherOutlined />}>
                <Link to="/admin/banners">Banners</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/promo" icon={<DisconnectOutlined />}>
                <Link to="/admin/promo">Promo Code</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/cms" icon={<SecurityScanOutlined />}>
                <Link to="/admin/cms">CMS Screens</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/seodata" icon={<SecurityScanOutlined />}>
                <Link to="/admin/seodata">SEO</Link>
              </ProtectedMenu>

              {/* <ProtectedMenu
                accessList={accessList}
                key="/admin/termsofferscms"
                icon={<DisconnectOutlined />}
              >
                <Link to="/admin/termsofferscms">CmsTermsOffers </Link>
              </ProtectedMenu> */}

              <ProtectedMenu accessList={accessList} key="/admin/crm" icon={<AuditOutlined />}>
                <Link to="/admin/crm">CRM</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/set-commission" icon={<UsergroupAddOutlined />}>
                <Link to="/admin/set-commission">Set Commission</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/margins" icon={<UsergroupAddOutlined />}>
                <Link to="/admin/margins">Margins</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/CmsFareRules" icon={<FileDoneOutlined />}>
                <Link to="/admin/CmsFareRules">Cms Fare Rules</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/HelpInfo" icon={<AuditOutlined />}>
                <Link to="/admin/HelpInfo">Help Info</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/BookingLimit" icon={<AuditOutlined />}>
                <Link to="/admin/bookinglimit">Booking Limit</Link>
              </ProtectedMenu>
            </SubMenu>

            <SubMenu key="sub4" icon={<DollarOutlined />} title="Accounts">
              <ProtectedMenu accessList={accessList} key="/admin/ledger-statement" icon={<FileDoneOutlined />}>
                <Link to="/admin/ledger-statement">Ledger Statement</Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/b2b" icon={<SplitCellsOutlined />} className="individual-menu">
                <Link to="/admin/b2b">B2B Pg</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/coupons" icon={<DisconnectOutlined />} className="individual-menu">
                <Link to="/admin/coupons">Coupons</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/partpayment" icon={<DisconnectOutlined />} className="individual-menu">
                <Link to="/admin/partpayment">PartPayment</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/managebalance" icon={<AccountBookOutlined />} className="individual-menu">
                <Link to="/admin/managebalance">Manage Balance</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/bankdetails" icon={<BankOutlined />} className="individual-menu">
                <Link to="/admin/bankdetails">Bank Details</Link>
              </ProtectedMenu>
              {/* <ProtectedMenu
                accessList={accessList}
                key="/admin/discounts"
                icon={<UsergroupAddOutlined />}
              >
                <Link to="/admin/discounts">Discounts</Link>
              </ProtectedMenu> */}
            </SubMenu>

            <SubMenu key="sub11" icon={<UserOutlined />} title="Packages" className="left-menu-decre">
              <ProtectedMenu accessList={accessList} key="/admin/viewpackages" icon={<SettingOutlined />} className="individual-menu">
                <Link to="/admin/viewpackages">Packages</Link>
              </ProtectedMenu>
            </SubMenu>
            <SubMenu key="sub9" icon={<PushpinOutlined />} title="Activities">
              <ProtectedMenu accessList={accessList} key="/admin/activities" icon={<ShopOutlined />} className="individual-menu">
                <Link to="/admin/activities">Activities</Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/activitiesoption" icon={<ShopOutlined />} className="individual-menu">
                <Link to="/admin/activitiesoption">Activities Options </Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/activitytourrate" icon={<ShopOutlined />} className="individual-menu">
                <Link to="/admin/activitytourrate">Activities Tour Rate </Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/activitytimeslot" icon={<ShopOutlined />} className="individual-menu">
                <Link to="/admin/activitytimeslot">Activities Time Slot</Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/activitiescategory" icon={<ShopOutlined />} className="individual-menu">
                <Link to="/admin/activitiescategory">Activities Category</Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/activitiesCities" icon={<ShopOutlined />} className="individual-menu">
                <Link to="/admin/activitiesCities">Activities Cities</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/transfercategory" icon={<ShopOutlined />} className="individual-menu">
                <Link to="/admin/transfercategory">Transfer Category</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/transferslab" icon={<ShopOutlined />} className="individual-menu">
                <Link to="/admin/transferslab">Transfer Slab</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/createTicket" icon={<ShopOutlined />} className="individual-menu">
                <Link to="/admin/createTicket">Create Ticket</Link>
              </ProtectedMenu>
              {/* <ProtectedMenu
                accessList={accessList}
                key="/admin/packagesdetailed"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/packagesdetailed">
                  Activities Pkg Details{" "}
                </Link>
              </ProtectedMenu> */}
            </SubMenu>

            <SubMenu key="sub3" icon={<AuditOutlined />} title="Commissions">
              <ProtectedMenu accessList={accessList} key="/admin/activitiescommissions" icon={<SendOutlined />}>
                <Link to="/admin/activitiescommissions">Activities Commission</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/flightcommissions" icon={<SendOutlined />}>
                <Link to="/admin/flightcommissions">Flight Commission</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/hotelcommissions" icon={<BuildOutlined />}>
                <Link to="/admin/hotelcommissions">Hotel Commission</Link>
              </ProtectedMenu>

              <ProtectedMenu accessList={accessList} key="/admin/buscommissions" icon={<SelectOutlined />}>
                <Link to="/admin/buscommissions">Bus Commission</Link>
              </ProtectedMenu>
            </SubMenu>

            <SubMenu key="sub5" icon={<FilePdfOutlined />} title="Ticketing">
              <ProtectedMenu accessList={accessList} key="/admin/travelcalendar" icon={<CalendarOutlined />}>
                <Link to="/admin/travelcalendar">Travel Calendar</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/bookoffline" icon={<IssuesCloseOutlined />}>
                <Link to="/admin/bookoffline">Book Offline</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/cancelrequest" icon={<CloseSquareOutlined />}>
                <Link to="/admin/cancelrequest">Cancel Requests</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/reshedule-request" icon={<CloseSquareOutlined />}>
                <Link to="/admin/reshedule-request">Reshedule Requests</Link>
              </ProtectedMenu>
              {/* <ProtectedMenu
                accessList={accessList}
                key="/admin/refundcancellation"
                icon={<DiffOutlined />}
              >
                <Link to="/admin/refundcancellation">Refund Cancellation</Link>
              </ProtectedMenu> */}
              {/* <ProtectedMenu
                accessList={accessList}
                key="/admin/refundticket"
                icon={<SendOutlined />}
              >
                <Link to="/admin/refundticket">Air Refunded Ticket</Link>
              </ProtectedMenu> */}
            </SubMenu>
            <SubMenu key="sub6" icon={<FileSearchOutlined />} title="Reports">
              <ProtectedMenu accessList={accessList} key="/admin/reports" icon={<FileTextOutlined />}>
                <Link to="/admin/reports">All Reports</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/storevistors" icon={<SettingOutlined />} className="individual-menu">
                <Link to="/admin/storevistors">Store Vistors</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/contactforms" icon={<FormOutlined />}>
                <Link to="/admin/contactforms">Contact Forms</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/flightenquiries" icon={<ThunderboltOutlined />}>
                <Link to="/admin/flightenquiries">Flight Enquiries</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/offlineEnquiries" icon={<ThunderboltOutlined />}>
                <Link to="/admin/offlineEnquiries">Offline Enquiries</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/subscriberreport" icon={<UserOutlined />}>
                <Link to="/admin/subscriberreport">Subscriber Report</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/insurancereports" icon={<UserOutlined />}>
                <Link to="/admin/insurancereports">Insurance Reports</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/reviews-rating" icon={<UserOutlined />}>
                <Link to="/admin/reviews-rating">Review & Rating/Photos</Link>
              </ProtectedMenu>
            </SubMenu>

            <SubMenu key="sub7" icon={<UserOutlined />} title="Logs">
              <ProtectedMenu accessList={accessList} key="/admin/pglogs" icon={<ApiOutlined />}>
                <Link to="/admin/pglogs">PG Log</Link>
              </ProtectedMenu>
              <ProtectedMenu accessList={accessList} key="/admin/apilogs" icon={<ApiOutlined />}>
                <Link to="/admin/apilogs">API Log</Link>
              </ProtectedMenu>
            </SubMenu>
            {/* Add Code For Confimation Number */}
            <SubMenu key="sub2" icon={<HomeOutlined />} title="Hotel">
              <ProtectedMenu accessList={accessList} key="/admin/cnf/number" icon={<QrcodeOutlined />}>
                <Link to="/admin/cnf/number">CNF Number</Link>
              </ProtectedMenu>
            </SubMenu>
            {/* Add Code For Confimation Number */}

            <SubMenu key="sub8" icon={<SettingOutlined />} title="Settings">
              <ProtectedMenu accessList={accessList} key="/admin/settings" icon={<SettingOutlined />} className="individual-menu">
                <Link to="/admin/settings">Settings</Link>
              </ProtectedMenu>
            </SubMenu>

            {/* <SubMenu key="sub13" icon={<GoldOutlined />} title="Car Module">
              <ProtectedMenu
                accessList={accessList}
                key="/admin/cartypes"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/cartypes">Car Types</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/driverinfo"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/driverinfo">DriverInfo</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/carsmapping"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/carsmapping">Cars Mapping</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/carsavailability"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/carsavailability">Cars Availability</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/carsorder"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/carsorder">Cars Orders</Link>
              </ProtectedMenu>
            </SubMenu>

            <SubMenu
              key="sub14"
              icon={<SelectOutlined />}
              title="Bus Transport"
            >
              <ProtectedMenu
                accessList={accessList}
                key="/admin/managebus"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/managebus">Manage Bus</Link>
              </ProtectedMenu>
            </SubMenu>

            <SubMenu
              key="sub15"
              icon={<MergeCellsOutlined />}
              title="Tours Extranet"
            >
              <ProtectedMenu
                accessList={accessList}
                key="/admin/toursextranet-packages"
                icon={<ShopOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/toursextranet-packages">Packages</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/toursextranet-hotels"
                icon={<ShopOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/toursextranet-hotels">Hotels</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/toursextranet-flights"
                icon={<ShopOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/toursextranet-flights">Flights</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/toursextranet-transfers"
                icon={<ShopOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/toursextranet-transfers">Transfers</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/toursextranet-vendor"
                icon={<ShopOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/toursextranet-vendor">Vendors</Link>
              </ProtectedMenu>
            </SubMenu>


            {/* Commented CarExtranet Module Temperar */}

            {/* <SubMenu
              key="sub16"
              icon={<PushpinOutlined />}
              title="Hotel Extranet"
            >
              <ProtectedMenu
                accessList={accessList}
                key="/admin/hotelfacilities"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/hotelfacilities">Hotel Facilities</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/hotelroomtypes"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/hotelroomtypes">Hotel Room Types</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/Hotelamenities"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/hotelamenities">Hotel Amenities</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/Hotelimagetypes"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/hotelimagetypes">Hotel Image Types</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/propertytype"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/propertytype">Property Type</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/aroundthishotel"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/aroundthishotel">Around this hotel</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/bedtype"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/bedtype">Bed Type</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/managehotel"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/managehotel">Manage Hotels</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/managerooms"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/managerooms">Manage Rooms</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/managepolicies"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/managepolicies">Manage Policies</Link>
              </ProtectedMenu>
               <ProtectedMenu
                accessList={accessList}
                key="/admin/managearoundhotel"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/managearoundhotel">ManageAround Hotel</Link>
              </ProtectedMenu>

              <ProtectedMenu
                accessList={accessList}
                key="/admin/manageimages"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/manageimages">Manage Media</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/managevideos"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/managevideos">Manage Videos</Link>
              </ProtectedMenu>
              
            </SubMenu> 

           
            <SubMenu
              key="sub17"
              icon={<PushpinOutlined />}
              title="Visa"
            >
              <ProtectedMenu
                accessList={accessList}
                key="/admin/visaDocs"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/visaDocs">VisaEssential Documents</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/managevisa"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/managevisa">ManageVisa Master</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/docsReqdVisa"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/docsReqdVisa">Documents required for Visa</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/visafaqs"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/visafaqs">Visa FAQs</Link>
              </ProtectedMenu>
              <ProtectedMenu
                accessList={accessList}
                key="/admin/embassyDetails"
                icon={<SettingOutlined />}
                className="individual-menu"
              >
                <Link to="/admin/embassyDetails">Embassy Details</Link>
              </ProtectedMenu>
            </SubMenu> */}
          </Menu>
        </Sider>

        <Layout>
          <Header className="site-layout-background admin_header">
            {collapsed ? <MenuUnfoldOutlined className="trigger" onClick={menuToggle} /> : <MenuFoldOutlined className="trigger" onClick={menuToggle} />}

            <Button type="primary" onClick={AdminLogout}>
              Logout
            </Button>
          </Header>
          <Content>
            <AdminNavigations accessList={accessList} accessToken={accessToken} />
          </Content>
          <Footer className="footer">
            <Row>
              <Col span={24}>
                <p style={{ textAlign: "center", margin: 0 }}>All Right Resereved @ ShopYourTrip</p>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default Administration;
