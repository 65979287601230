import React from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import SeoMetaData from "../../common/Seo/SeoMetaData";
const Disclaimer = () => {
  return (
    <>
    <SeoMetaData path={window.location.pathname}/>
   <CmsPages cmsId={7} pageTitle="Disclaimer" />
   </>
  );
};

export default Disclaimer;
