import React, { useEffect, useState } from "react";
import { Button, Col, Row, Card } from "antd";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSytContext } from "../../common/providers/SytProvider";
import "./Blog.scss";
import { AppConstants } from "../../helpers/constants";
import SeoMetaData from "../../common/Seo/SeoMetaData";

const BASE = process.env.REACT_APP_BASE_URL;

const Blog = () => {
  const { blog } = useSytContext();

  // const [blogList, setBlogList] = useState([]);
  // console.log(blogList, "blopg");
  // let chunkSize = 6;

  // useEffect(() => {
  //   if (blog?.length > 0) {
  //     splitIntoChunk(blog, chunkSize);
  //   }
  // }, [blog]);

  // function splitIntoChunk(arr, chunkSize) {
  //   var chunks = [],
  //     i = 0,
  //     n = arr.length;
  //   while (i < n) {
  //     chunks.push(arr.slice(i, (i += chunkSize)));
  //   }
  //   setBlogList(chunks);
  // }

  return (
    <div className="deals-section">
      <SeoMetaData path={window.location.pathname} />
      {/* <div className="container deals-top-content blog-containe-wdh"> */}
      {blog.length > 0 ? (
        <div className="blog_wrapper">
          <div className="blog_grid">
            {/* <h2>Blogs</h2> */}
            <Row gutter={[24, 24]}>
              {blog.map(
                (item, index) => (
                  // index < 6 ? (
                  <Col md={8} xs={24}>
                    <Link to={`/bloginfo/${item.BlogID}/${item.urlTitle}`}>
                      <Card
                        className="card "
                        // cover={
                        //   <img
                        //     className="img"
                        //     src={BASE + item.BlogTitleImage1.substring(1)}
                        //     alt="Hotel Image"
                        //   />
                        // }
                        style={{
                          minHeight: "200px",
                          backgroundRepeat: "no - repeat",
                          backgroundSize: "cover",
                          backgroundImage: `url(${
                            BASE + item.BlogTitleImage1.substring(1)
                          })`,
                          paddingTop: "25% !important",
                        }}
                      >
                        <p
                          className="title"
                          style={{
                            color: "white",
                            backgroundColor: "#00000094",
                            padding: "10px",
                          }}
                        >
                          {item.BlogTitle}
                        </p>
                      </Card>
                    </Link>
                  </Col>
                )
                // ) : null
              )}
            </Row>
          </div>
        </div>
      ) : null}
      {/* {blogList.map((items, i) => {
          return items.length > 0 ? (
            i % 2 === 0 ? (
              <Row>
                {items.length > 0 && (
                  <>
                    {items[0] && (
                      <Col md={15} xs={24}>
                        <div
                          className="img-blog-1"
                          style={{
                            backgroundImage: `url(${
                              BASE + items[0].BlogTitleImage1.substring(1)
                            })`,
                          }}
                        >
                          <div className="imp-text-blog">
                            <div className="travel-width-text">
                              <h5 className="blog-travel">
                                <strong>{items[0].BlogTitle}</strong>
                              </h5>
                              <Link
                                to={`/bloginfo/${items[0].BlogID}/${items[0].urlTitle}`}
                              >
                                <Button className="blog-btn">READ MORE</Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}

                    <Col md={9} xs={24}>
                      {items[1] && (
                        <div
                          className="img-blog-2"
                          style={{
                            backgroundImage: `url(${
                              BASE + items[1].BlogTitleImage1.substring(1)
                            })`,
                          }}
                        >
                          <div className="imp-text-blog-1">
                            <div className="travel-width-text-1">
                              <Link
                                to={`/bloginfo/${items[1].BlogID}/${items[1].urlTitle}`}
                              >
                                <h5 className="blog-travel-1">
                                  <strong>{items[1].BlogTitle}</strong>
                                </h5>
                              </Link>
                              <hr className="bd-hr"></hr>

                              <Link
                                to={`/bloginfo/${items[1].BlogID}/${items[1].urlTitle}`}
                              >
                                <p className="blog-read">READ MORE</p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}

                      {items[2] && (
                        <div
                          className="img-blog-3"
                          style={{
                            backgroundImage: `url(${
                              BASE + items[2].BlogTitleImage1.substring(1)
                            })`,
                          }}
                        >
                          <div className="imp-text-blog-2">
                            <div className="travel-width-text-2">
                              <Link
                                to={`/bloginfo/${items[2].BlogID}/${items[2].urlTitle}`}
                              >
                                <h5 className="blog-travel-2">
                                  <strong>{items[2].BlogTitle}</strong>
                                </h5>
                              </Link>
                              <hr className="bd-hr"></hr>
                              <Link
                                to={`/bloginfo/${items[2].BlogID}/${items[2].urlTitle}`}
                              >
                                <p className="blog-read">READ MORE</p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                  </>
                )}
              </Row>
            ) : (
              <Row>
                {items.length > 0 && (
                  <>
                    <Col md={9} xs={24}>
                      {items[1] && (
                        <div
                          className="img-blog-2 padding-left-rm"
                          style={{
                            backgroundImage: `url(${
                              BASE + items[1].BlogTitleImage1.substring(1)
                            })`,
                          }}
                        >
                          <div className="imp-text-blog-1">
                            <div className="travel-width-text-1">
                              <Link
                                to={`/bloginfo/${items[1].BlogID}/${items[1].urlTitle}`}
                              >
                                <h5 className="blog-travel-1">
                                  <strong>{items[1].BlogTitle}</strong>
                                </h5>
                              </Link>
                              <hr className="bd-hr"></hr>

                              <Link
                                to={`/bloginfo/${items[1].BlogID}/${items[1].urlTitle}`}
                              >
                                <p className="blog-read">READ MORE</p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}

                      {items[2] && (
                        <div
                          className="img-blog-3 padding-left-rm"
                          style={{
                            backgroundImage: `url(${
                              BASE + items[2].BlogTitleImage1.substring(1)
                            })`,
                          }}
                        >
                          <div className="imp-text-blog-2">
                            <div className="travel-width-text-2">
                              <Link
                                to={`/bloginfo/${items[2].BlogID}/${items[2].urlTitle}`}
                              >
                                <h5 className="blog-travel-2">
                                  <strong>{items[2].BlogTitle}</strong>
                                </h5>
                              </Link>
                              <hr className="bd-hr"></hr>
                              <Link
                                to={`/bloginfo/${items[2].BlogID}/${items[2].urlTitle}`}
                              >
                                <p className="blog-read">READ MORE</p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                    {items[0] && (
                      <Col md={15} xs={24}>
                        <div
                          className="img-blog-1 padding-left-add"
                          style={{
                            backgroundImage: `url(${
                              BASE + items[0].BlogTitleImage1.substring(1)
                            })`,
                          }}
                        >
                          <div className="imp-text-blog">
                            <div className="travel-width-text">
                              <h5 className="blog-travel">
                                <strong>{items[0].BlogTitle}</strong>
                              </h5>
                              <Link
                                to={`/bloginfo/${items[0].BlogID}/${items[0].urlTitle}`}
                              >
                                <Button className="blog-btn">READ MORE</Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}
                  </>
                )}
              </Row>
            )
          ) : null;
        })} */}
      {/* </div> */}
    </div>
  );
};

export default Blog;
