import React, { createContext, useContext, useEffect, useState } from "react";
import ApiClient from "../../helpers/ApiClient";
import { useLocalStorage } from "../../helpers/useStorage";

const CurrencyContext = createContext();

export function useCurrencyContext() {
  return useContext(CurrencyContext);
}

const CurrencyProvider = ({ children }) => {
  const [activeCurrency, setActiveCurrency] = useLocalStorage(
    "currency",
    "INR"
  );

  const [currencies, setCurrencies] = useState({ INR: 1 });
  useEffect(() => {
    ApiClient.get("admin/currencyConversionLatest")
      .then((resp) => {
        if (resp?.status == 200) {
          setCurrencies(resp.data);
        } else {
          // if (window.location.search !== "?error=Currency%20Error")
          //   window.location.href = "/error?error=Currency Error";
        }
      })
      .catch((e) => {
        // if (window.location.search !== "?error=Currency%20Error")
        //   window.location.href = "/error?error=Currency Error";
      });
  }, []);

  const getCurrencyValue = (amount) => {
    let currentUrl = window.location.href;
    let containsActivities = currentUrl?.includes("/activities");
    let buildyourpackage=currentUrl?.includes("/buildyourpackage");
    if(activeCurrency!=="INR" && (containsActivities || buildyourpackage)){
    return Number(
      parseInt(Math.ceil(amount)) / parseFloat(parseFloat(currencies[activeCurrency])+ parseFloat(currencies["INRBaseAmount"])).toFixed(2)
    ).toFixed(2);  // --Code Changes by Karthik 27/April/2023
  }else{
    return Number(
      parseInt(Math.ceil(amount)) /  parseFloat(currencies[activeCurrency]).toFixed(2)
    ).toFixed(2); 
  }
}

  return (
    <CurrencyContext.Provider
      value={{
        activeCurrency: activeCurrency,
        currencyValue: (a) => getCurrencyValue(a),
        setActiveCurrency: (a) => setActiveCurrency(a),
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
