import inr from "../../assets/images/currency-flags/inr.svg";
import usd from "../../assets/images/currency-flags/usd.svg";
import nzd from "../../assets/images/currency-flags/nzd.svg";
import gbp from "../../assets/images/currency-flags/gbp.svg";
import aud from "../../assets/images/currency-flags/aud.svg";
import cad from "../../assets/images/currency-flags/cad.svg";
import uah from "../../assets/images/currency-flags/uah.svg";
import cny from "../../assets/images/currency-flags/cny.svg";
import mxn from "../../assets/images/currency-flags/mxn.svg";
import aed from "../../assets/images/currency-flags/aed.svg";
import sgd from "../../assets/images/currency-flags/sgd.svg";
import eur from "../../assets/images/currency-flags/eur.svg";
export default [
  {
    name: "INR",
    url: inr,
  },
  {
    name: "USD",
    url: usd,
  },
  {
    name: "NZD",
    url: nzd,
  },
  {
    name: "GBP",
    url: gbp,
  },
  {
    name: "AUD",
    url: aud,
  },
  {
    name: "CAD",
    url: cad,
  },
  {
    name: "UAH",
    url: uah,
  },
  {
    name: "CNY",
    url: cny,
  },
  {
    name: "MXN",
    url: mxn,
  },
  {
    name: "AED",
    url: aed,
   }
   ,
  {
    name: "SGD",
    url: sgd,
  },
  {
    name: "EUR",
    url: eur,
  }
];
