import React, { useState, useEffect } from "react";
import { Button, Card, Input, Form, message } from "antd";
import "./ChangePassword.scss";
import APIClient from "../../helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";
import VerifyOtpModal from "../../common/OtpModal/VerifyOtpModal";

const ChangePassword = () => {
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [credentialDetails, setCredentialDetails] = useState({});
  const [form] = Form.useForm();
  const { user } = useAuthContext();

  useEffect(() => {
    if (verifyOTP) {
      APIClient.post("admin/changePassword", credentialDetails).then((response) => {
        if (response.status === 200) {
          message.success("Your password has been changed successfully", 3);
          form.resetFields();
        } else if (response.status === 400) {
          form.setFields([
            {
              name: "oldpassword",
              errors: ["Invalid Password"],
            },
          ]);
        } else if (response.status === 409) {
          message.error(response.message, 3);
          form.setFields([
            {
              name: "oldpassword",
              errors: [response.message],
            },
          ]);
        }
      });
    }
  }, [verifyOTP, credentialDetails, form]);

  const onFinish = (values) => {
    const reqObj = {
      UserID: user.UserID,
      OldPassword: values.oldpassword,
      NewPassword: values.newPassword,
      mobile: user.Mobile,
    };

    if (reqObj && reqObj.mobile && !verifyOTP) {
      setCredentialDetails(reqObj);
      APIClient.post("admin/sendserviceOtp", {
        Mobile: reqObj.mobile,
        DialingCode: "+91",
        refUrl: "/change-password",
      })
        .then((res) => {
          if (res.status === 200) {
            setVerifyModalVisible(true);
          } else {
            if (res.message) message.error(res.message, 3);
            else message.error("Booking Failed", 3);
          }
        })
        .catch(() => {
          message.error("Error occurred while sending OTP");
        });
    }
  };

  const handleVerifyOtp = (otpValue) => {
    APIClient.post("admin/verifyserviceOtp", {
      Mobile: user.Mobile,
      DialingCode: "+91",
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res.status === 200 && Object.keys(credentialDetails).length > 0) {
          setVerifyModalVisible(false);
          setVerifyOTP(true);
        } else {
          if (res.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch(() => {
        message.error("Error occurred while verifying OTP");
      });
  };

  return (
    <div id="changePwd">
      <div className="change-pwd-container">
        <h2>Change Password</h2>
        <Card
          title="Choose a strong Password"
          bordered={false}
          className="change-password-user"
        >
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
              label="Old Password"
              name="oldpassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your old password!",
                },
              ]}
            >
              <Input.Password placeholder="Enter old password" />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your new password!",
                },
              ]}
            >
              <Input.Password placeholder="Enter new password" />
            </Form.Item>
            <div className="change-pwd-btn">
              <Button htmlType="submit">Change Password</Button>
            </div>
          </Form>
        </Card>

        {verifyModalVisible && (
          <VerifyOtpModal
            visible={verifyModalVisible}
            setVerifyModalVisible={setVerifyModalVisible}
            handleVerifyOtp={handleVerifyOtp}
            handleResendOtp={onFinish}
            contactDetails={credentialDetails}
          />
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
