import React, { useContext, useEffect, useState } from "react";
import { Card, Radio, Space } from "antd";
import { useAuthContext } from "../providers/AuthProvider";
import { GlobalStatesContext } from "../../common/providers";
import type { RadioChangeEvent } from 'antd';
const PayMentSelection = ({ userId, setUserId, setModalVisibl, setValue, value }) => {
  // const { setUser, setAccessToken, updateLoginFlag, resetIsLogin } =
  //   useAuthContext();
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();

    const {
        state: {
          otherData: {  },
        },
        AddTransFee
      } = useContext(GlobalStatesContext);
    //  const [value, setValue] = useState();
    const onChange = (e: RadioChangeEvent) => {
      console.log('radio checked', e.target.value);
      setValue(e.target.value);
      AddTransFee(e.target.value);
    //  if(value!='')
    //  {
    //     AddTransFee(value);
    //  }
        //setValue(e.target.value);
      };

    
  // const displayDiscount = (value) => {
  //   switch(value){
  //     case 'DC':
  //       return "Debit Card ( Select this Option and get discount upto 1.70% )";
  //     case 'CC': 
  //     return "Credit Card ( Select this Option and get discount upto 2.10% )";
  //     case 'NB':
  //       return "Net Banking ( Select this Option and get discount upto 1 % )";
  //     case 'UPI': 
  //     return "UPI ( Select this Option and get discount upto 3 % )";
  //     default: 
  //     return
  //   }
  // }
     
  return (
    <div>
        <div className="promo-card apply-promo-fbt">
        
        <Card className="flight-cards-details">
          <div className="promo-card-header">
            <p className="name">Select Payment Method</p>
          </div>
        
                <div className="promo-cp-coupons mt-3">
                  <div className="inline-cp-promo">
                  <Radio.Group onChange={onChange} value={value}>
                      <Space direction="vertical">
                        <Radio value={"DC"}>Debit Card (Get discount upto 1.70%)</Radio>
                        <Radio value={"CC"}>Credit Card</Radio>
                        <Radio value={"NB"}>Net banking (Get discount upto 1%)</Radio>
                        <Radio value={"UPI"}>UPI (Get discount upto 3%)</Radio>
                      </Space>
                    </Radio.Group>
                  </div>

                  {user?.Role?.RoleId !== 5 && (
                    <div className="promo-percentage-cp center-content">
                        {/* <p><b>{value && (displayDiscount(value))}</b></p> */}
                    </div> 
                    )}
                </div>
            
        </Card>
      
    </div>
    </div>
  );
};
export default PayMentSelection;
