import React, { useState } from "react";
import { Col, Row, message, Modal, Collapse, Select } from "antd";
import { useAuthContext } from "../../providers/AuthProvider";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { getAllActivitiesPrice } from "../../../pages/Activitespkg/Activitiescheckout/activitiesHelper";
import "../ticketActivities.scss";
import QRCode from "qrcode.react";
import ApiClient from "../../../helpers/ApiClient";
import Table from "react-bootstrap/Table";
import { useCurrencyContext } from "../../providers/CurrencyProvider";
import {
  DownloadOutlined,
  FileExcelOutlined,
  MailOutlined,
  MessageOutlined,
  PrinterOutlined,
  DollarOutlined,
  UserOutlined,
  PhoneOutlined,
  QrcodeOutlined,
  IdcardOutlined
} from "@ant-design/icons";
const { Panel } = Collapse;
const { Option } = Select;

const TicketActivites = ({ ticketData, cmsFareRules, voucherData }) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  const { totalAmount, discount } = getAllActivitiesPrice(
    ticketData.TourDetails,
    ticketData.convienenceData,
    ticketData.promoData
  );
  const [CancellationPolicyModal, setCancellationPolicyModal] = useState(false);
  const [CancellationPolicy, setCancellationPolicy] = useState([]);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const getStatus = (status) => {
    // if (voucherData == true) {
    //   status = "VOUCHERED"
    // }
    switch (status) {
      case "BLOCKED": {
        return <span style={{ color: "#FFA500" }}>BLOCKED </span>;
      }

      case "SUCCESS": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status} </b>
          </span>
        );
      }

      case "CONFIRMED": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status} </b>
          </span>
        );

      }

      case "ALREADYCANCELLED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> ALREADY CANCELLED </b>
          </span>
        );
      }
      case "CANCELLED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status} </b>
          </span>
        );
      }

      case "VOUCHERED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status} </b>
          </span>
        );
      }
      case "NOT-AVAILABLE": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {"On Request"} </b>
          </span>
        );
      }

      default:
        return;
    }
  };

  const getPaxCount = (pax) => {
    return `Adult(s) ${pax?.adults !== "0" ? pax?.adults : 0} ${pax?.childCount !== "0" ? ", Child(s) " + pax?.childCount : " "
      } ${pax?.infantCount !== "0" ? ", Infant(s) " + pax?.infantCount : " "}`;
  };

  const getTotalAmount = () => {
    let postMarkup = ticketData.totalPostMarkup
      ? ticketData.totalPostMarkup
      : 0;
    let totalAmount =
      Number(ticketData.PartPayment.serviceTotal) + Number(postMarkup);

    return Number(totalAmount).toFixed(2);
  };

  const getTicketPolicy = (tour) => {
    let { tourId, journeyDate, startTime, serviceUniqueId, optionId } = tour;
    //const currentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
    // message.success("Successfully")
    setCancellationPolicyModal(true)
    setCancellationPolicy(tour?.ticketPolicy)
  }

  const toggleCancelHandler = () => {
    setCancellationPolicyModal((prev) => !prev);
  };

  return (
    <div className="activities-ticket">
      <div className="activities-details">
        <ul className="tour-timeline tour-timeline-left">
          <div className="tour-details-panel">
            <h6 className="pax-title">Passanger Details</h6>
            <div className="passenger-details">
              <Row gutter={[8, 8]}>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    <UserOutlined />
                    Guest Name:{" "}
                    <span>
                      {ticketData?.Passengers.firstName}{" "}
                      {ticketData?.Passengers.lastName}
                    </span>
                  </p>
                </Col>

                <Col md={12} sm={12} xs={24}>
                  <p>
                    <MailOutlined />
                    Email: <span>{ticketData?.Passengers.email}</span>
                  </p>
                </Col>
                <Col md={12} sm={12} xs={24}>
                  <p>
                    <PhoneOutlined />
                    Contact: <span>{ticketData?.Passengers.mobile}</span>
                  </p>
                </Col>
                {/* <Col md={12} sm={12} xs={24}>
                  <p>
                    <IdcardOutlined />
                    Pan Card No: <span>{ticketData?.Passengers.pan_no}</span>
                  </p>
                </Col> */}
              </Row>
            </div>
            {(ticketData.TourDetails?.[0].apikey === "EXT" || ticketData.TourDetails?.[0].transferName === "Private Transfer") ? (
              <h6 className="pax-title">Transfer Details</h6>
            ) : (
              <h6 className="pax-title">Tour Details</h6>
            )}
            <div className="passenger-details">
              <Row gutter={[8, 8]}>

                {/* <Col md={12} sm={12} xs={24}>
                  <p>
                    Reference Number:
                    <span> {ticketData?.TourDetails?.[0].referenceNumber}</span>
                  </p>
                </Col> */}
                <Col md={12} sm={12} xs={24}>
                  <p>
                    {(ticketData.TourDetails?.[0].apikey === "EXT" || ticketData.TourDetails?.[0].transferName === "Private Transfer") && ticketData.TourDetails?.[0].BookingStatus === "VOUCHERED" ?
                      "Confirmation Number:" :
                      "Reference Number:"}
                    <span> {ticketData?.TourDetails?.[0].referenceNumber}</span>
                  </p>
                </Col>



                {!agent && (
                  <Col md={12} sm={12} xs={24}>
                    <p>
                      Provider Ref No:
                      <span> {ticketData?.TourDetails?.[0].ProviderRefNo}</span>
                    </p>
                  </Col>
                )}

                {/*Starting Block  (Confirmation No Hide On Condition Base Changes by Karthik --12/April/2023) */}
                {ticketData?.TourDetails?.[0].confirmationNo != '' ?
                  <Col md={12} sm={12} xs={24}>
                    <p>
                      Confirmation No:
                      <span> {ticketData?.TourDetails?.[0].confirmationNo}</span>
                    </p>
                  </Col>
                  : null}
                {/*Ending Block  (Confirmation No Hide On Condition Base Changes by Karthik --12/April/2023) */}
                {ticketData.TourDetails[0]?.bookingStatus == 11 && (
                  <Col md={12} sm={12} xs={24}>
                    <p>
                      Payment Mode:
                      <span>
                        {ticketData.PartPayment.isPartPayment === true
                          ? " Part"
                          : " Full"}{" "}
                        Payment
                      </span>
                    </p>
                  </Col>
                )}

                {ticketData?.convienenceData?.amount > 0 && (
                  <Col md={12} sm={12} xs={24}>
                    {/*Starting Block  (Convienence Fee Hide On Condition Base Changes by Karthik --12/April/2023) */}
                    {/* {Number(ticketData.convienenceData.amount) > 0 && ( */}
                    <p>
                      Convienence Fee:
                      <span>
                        {" "}
                        INR{" "}
                        {Number(
                          ticketData?.convienenceData?.amount ?? 0
                        ).toFixed(2)}
                      </span>
                    </p>
                    {/* )} */}
                    {/*Ending Block  (Convienence Fee Hide On Condition Base Changes by Karthik --12/April/2023) */}
                  </Col>
                )}

                <Col md={12} sm={12} xs={24}>
                  <p>
                    Total Amount:
                    <span> {activeCurrency} {currencyValue(totalAmount)}</span>
                  </p>
                </Col>
                {/*Starting Block  (Convienence Fee Hide On Condition Base Changes by Karthik --12/April/2023) */}
                {ticketData?.promoData && (
                  <>
                    {ticketData.promoData.Discount > 0 &&
                      <Col md={12} sm={12} xs={24}>
                        <p>
                          Discount:
                          <span>
                            {" - INR "}
                            {(isNaN(discount)) ? 0 : Number(discount).toFixed(2)}
                          </span>
                        </p>
                      </Col>
                    }
                  </>
                )}
                {/*Ending Block  (Convienence Fee Hide On Condition Base Changes by Karthik --12/April/2023) */}
                <Col md={12} sm={12} xs={24}>
                  <p>
                    Grand Total:
                    <span style={{ color: '#2f5a87', fontWeight: 'bold' }}> {activeCurrency} {currencyValue(getTotalAmount())}</span>
                  </p>
                </Col>

                {ticketData.PartPayment.isPartPayment === true ? (
                  <>
                    <Col md={12} sm={12} xs={24}>
                      <p>
                        Paid Amount:
                        <span>
                          {" "}
                          {activeCurrency} {currencyValue(ticketData.PartPayment.partPaymentAmount)}
                        </span>
                      </p>
                    </Col>
                    <Col md={12} sm={12} xs={24}>
                      <p className="text-danger">
                        Due Amount:
                        <span> {activeCurrency} {currencyValue(ticketData.PartPayment.dueAmount)} </span>
                      </p>
                    </Col>
                  </>
                ) : null}
              </Row>
            </div>
          </div>

          {ticketData?.TourDetails?.map((tour, index) => {
            return (<li
              key={index}
              className="tour-timeline-inverted tour-timeline-item"
            >
              <div className="tour-timeline-badge warning">
                <span className="font-12">
                  {moment(tour.journeyDate, "YYYY-MM-DD").format("DD MMM")}
                </span>
              </div>
              <div className="tour-timeline-panel">
                <h5 className="tour-timeline-title">{tour.tourName}</h5>
                <p className="wt-activity3">
                  {moment(tour.journeyDate, "YYYY-MM-DD").format("DD MMM YYYY")}
                </p>
                <p className="subtitle">{tour.optionName}</p>

                <div className="tour-timeline-body">
                  <Row className="justify-content-between">
                    <Col md={6} xs={24}>
                      <img
                        className="hotel-tag-1"
                        src={tour.tourImage}
                        alt="First slide"
                      />
                    </Col>

                    {!(tour.apikey === "EXT" || tour.transferName === "Private Transfer") && tour?.BookingStatus !== "VOUCHERED" && (
                      <Col md={8} xs={24} style={{ position: 'relative', textAlign: 'center' }}>
                        <QrcodeOutlined style={{ fontSize: '140px', filter: 'blur(3px)', opacity: '0.2' }} />
                        <span style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          fontSize: '14px',
                          color: 'black',
                          textShadow: '1px 1px 2px rgba(248, 248, 247, 0.2)' // Slight shadow for better readability
                        }}>
                          QR Code not Generated
                        </span>
                      </Col>
                    )}
                  </Row>
                  <Row className="justify-content-between pr-3" gutter={[2, 2]}>
                    {tour?.ticket?.length > 0 ? null : ((tour?.bookingStatus === 11 && tour.apikey !== "EXT") ? (
                      < Col className="wt-activity" md={24} xs={24}>
                        <p style={{ backgroundColor: '#fcf6c5', padding: '1px', marginBottom: '-2px' }}>
                          <p><strong style={{ color: 'red' }}>Note :- </strong>Please wait for 30 minutes for the availability of the QR code.If any query contact on <strong>Emergency Number :- 9166868837, 9999442804, 011-49511313</strong>. Thank you for your patience.</p>
                        </p>
                      </Col>
                    ) : null
                    )}
                    {(tour.apikey === "EXT" || tour.transferName === "Private Transfer") && tour?.BookingStatus !== "VOUCHERED" ? (
                      <Col className="wt-activity" md={24} xs={24}>
                        <p style={{ backgroundColor: '#fcf6c5', padding: '1px', marginBottom: '-2px' }}>
                          <p><strong style={{ color: 'red' }}>Note :- </strong>Don't forget to voucher your transfer. If you do not voucher this transfer, it will be cancelled automatically.</p>
                        </p>
                      </Col>
                    ) : (
                      tour?.bookingStatus == 3 && (
                        <Col className="wt-activity" md={24} xs={24}>
                          <p style={{ backgroundColor: '#fcf6c5', padding: '1px', marginBottom: '-2px' }}>
                            <p><strong style={{ color: 'red' }}>Note :- </strong>Please do vouchered your ticket.</p>
                          </p>
                        </Col>
                      )
                    )}

                    {(tour.apikey !== "EXT" && tour.transferName !== "Private Transfer") && (
                      <Col md={24} xs={24}>
                        <p
                          className="hotel-list-para"
                          dangerouslySetInnerHTML={{
                            __html: tour.tourShortDescription,
                          }}
                        ></p>
                      </Col>
                    )}
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Booking Status :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">
                        {getStatus(tour.BookingStatus)}
                      </p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Tour Date  :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">
                        {moment(tour.journeyDate, "YYYY-MM-DD").format("ddd DD MMM YYYY")}
                      </p>
                    </Col>
              {(tour.bookingStatus === 3 || tour.bookingStatus === 7) && tour.transferName === "Private Transfers" && tour.apikey==="EXT" ? (
                      <>
                        <Col md={12} xs={24}>
                          <p className="wt-activity">Last Vouchered Date :</p>
                        </Col>
                        <Col md={12} xs={24}>
                          <p className="wt-activity2">
                            {moment(tour.journeyDate, "YYYY-MM-DD").subtract(4, 'days').format("ddd DD MMM YYYY")}
                          </p>
                        </Col>
                      </>
                    ) : null}

                    {(tour.bookingStatus === 11 || tour.bookingStatus === 7)  && tour.transferName === "Private Transfers" && tour.apikey==="EXT" ? (
                      <>
                        <Col md={12} xs={24}>
                          <p className="wt-activity">Last Cancellation Date :</p>
                        </Col>
                        <Col md={12} xs={24}>
                          <p className="wt-activity2">
                            {moment(tour.journeyDate, "YYYY-MM-DD").subtract(4, 'days').format("ddd DD MMM YYYY")}
                          </p>
                        </Col>
                      </>
                    ) : null}

                    <Col md={12} xs={24}>
                      <p className="wt-activity">Transfer Option :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">{tour.transferName}</p>
                    </Col>
                    {tour.duration != "" && tour.apikey !== "EXT" && tour.transferName !== "Private Transfer" && (
                  <>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Duration :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">{tour.duration}</p>
                    </Col>  
                  </>
                    )}
                    {(tour.apikey === "EXT" || tour.transferName === "Private Transfer") ? (
                      <>
                        <Col md={12} xs={24}>
                          <p className="wt-activity">Pickup Time :</p>
                        </Col>
                        <Col md={12} xs={24}>
                          <p className="wt-activity2">
                            {tour ? tour.startTime : 'Time Not Available'}
                          </p>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md={12} xs={24}>
                          <p className="wt-activity">Start Time :</p>
                        </Col>
                        <Col md={12} xs={24}>
                          <p className="wt-activity2">
                            {tour ? tour.startTime : 'Time Not Available'}
                          </p>
                        </Col>
                      </>
                    )}
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Number Of Passengers :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">{getPaxCount(tour.pax)}</p>
                    </Col>
                    {tour.pickup && (
                      <>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">
                      {tour.transferName === "Private Transfers" ? "Pick/Drop Location" : "Pickup Location"} :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">{tour.pickup}</p>
                    </Col>
                     </>
                    )}
                    {!(tour?.TransferObj == undefined || Object.keys(tour?.TransferObj).length == 0) && tour?.TransferObj?.FlightName !== null && tour?.TransferObj?.FlightNo !== null && (<>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Check-In Luggage :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">23 Kg/per X {tour?.TransferObj?.CheckLuggages} Pax</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Hand Luggage :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">7 Kg/per X {tour?.TransferObj?.Handluggage} Pax</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Flight/Train Name :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">{tour?.TransferObj?.FlightName}</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Flight/Train Number :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">{tour?.TransferObj?.FlightNo}</p>
                    </Col>
                    </>)}
                    <Col md={12} xs={24}>
                      <p className="wt-activity">Amount :</p>
                    </Col>
                    <Col md={12} xs={24}>
                      <p className="wt-activity2">
                        {activeCurrency} {currencyValue(Number(tour.serviceTotal).toFixed(2))}
                      </p>
                    </Col>
                    {!(tour.apikey === "EXT" || tour.transferName === "Private Transfer") && (
                      <>
                        <Col md={12} xs={24}>
                          <p className="wt-activity mt-3">Policy :</p>
                        </Col>
                        <Col md={12} xs={24}>
                          <p className="wt-activity2 mt-3 btn-link" onClick={() => getTicketPolicy(tour)} style={{ cursor: "pointer", textDecoration: "none" }}>
                            View Cancellation Policy
                          </p>
                        </Col>
                      </>
                    )}
                     {tour.apikey === "EXT" && (
                      <>
                        <Col md={12} xs={24}>
                          <p className="wt-activity mt-3">Policy :</p>
                        </Col>
                        <Col md={12} xs={24}>
                          <p className="wt-activity2 mt-3 btn-link" onClick={() => getTicketPolicy(tour)} style={{ cursor: "pointer", textDecoration: "none" }}>
                            View Cancellation Policy
                          </p>
                        </Col>
                      </>
                    )}

                    {tour != undefined && tour != null && tour?.bookingStatus == 11 && tour?.ticket?.length >= 1 && tour?.ticket[0]?.slot != '' && (<>
                      <Col md={12} xs={24}>
                        <p className="wt-activity mt-3">Slot :</p>
                      </Col>
                      <Col md={12} xs={24}>
                        <p className="wt-activity2 mt-3">
                          {tour?.ticket[0]?.slot}
                        </p>
                      </Col>
                    </>
                    )}
                    <Col md={12} xs={24}>
                      <p style={{ color: "#FF0000" }}>{tour?.ticketPolicy[0]?.cancellationPolicy}</p>

                    </Col>
                
                  </Row>
                  <Row className="justify-content-between pr-3" gutter={[2, 2]}>
                  {tour.BookingStatus == "NOT-AVAILABLE" &&
                      <Col className="wt-activity" md={24} xs={24}>
                        <p style={{ color: "#FF0000" }}>On Request: Your booking is being processed, but confirmation is pending. Our team will contact you shortly</p>
                      </Col>}
                  </Row>
                  {tour.bookingStatus == 11 && tour?.ticket?.map((x, i) => {
                    return (x.barcode && (<>
                      <Row className="justify-content-between my-3">
                        <Col md={6} xs={24}>
                          <img
                            className="hotel-tag-1"
                            src={tour.tourImage}
                            alt="First slide"
                          />
                        </Col>
                        <Col md={4} xs={24}>
                          <QRCode
                            value={x.barcode}
                            size={100}
                            title={tour.tourName}
                            id={"packageQR" + index + "" + i}
                          />
                        </Col>
                      </Row>
                    </>))
                  })}
                  {tour.emergencyContact != undefined && tour.emergencyContact != '' && (
                      <Row className="justify-content-between my-3 text-danger" style={{fontSize: '16px'}}>
                        <Col md={12} xs={24}>
                          <p>Emergency Number :</p>
                        </Col>
                        <Col md={12} xs={24}>
                          <p style={{textAlign: "end"}}>{tour?.emergencyContact}</p>
                        </Col>
                      </Row>
                    )}
                </div>
              </div>
            </li>
            )
          })}
          <li className="tour-timeline-inverted">
            {cmsFareRules?.cancelPolicyDescription ? (
              <div className="tour-timeline-panel">
                <div className="terms-panel">
                  <h5 className="terms-title">Terms and Condition</h5>
                  <div className="terms-description">
                    <p className="terms-title">Cancel Policy</p>

                    {ReactHtmlParser(cmsFareRules?.cancelPolicyDescription)}
                    {cmsFareRules?.otherRulesDescription ? (
                      <p className="title">Other Policy</p>
                    ) : null}
                    {ReactHtmlParser(cmsFareRules?.otherRulesDescription)}
                  </div>
                </div>
              </div>
            ) : null}
          </li>

          {ticketData?.Passengers?.hasOwnProperty('specialReq') && ticketData?.Passengers?.specialReq != '' && (
          <div className="tour-details-panel">
            <h6 className="pax-title">Special Requset :- <span>{ticketData?.Passengers?.specialReq}</span></h6>
          </div>
          )}

          <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>Tour Cancellation Policy</strong>
                </h6>
              </div>,
            ]}
            centered
            width={1000}
            visible={CancellationPolicyModal}
            onCancel={toggleCancelHandler}
            footer={[]}
          >
            <Collapse defaultActiveKey={["1"]}>
              <Panel header="Booking Policy" key="1">
                <h5>Cancellation Policy</h5>
                <p>{CancellationPolicy[0]?.cancellationPolicy} {CancellationPolicy.length == 0 && "To obtain information regarding our cancellation policy, kindly reach out to our support team"}</p>
                <pre>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        CancellationPolicy[0]?.cancellationPolicyDescription,
                    }}
                  ></div>
                </pre>
                <h5>Child Policy</h5>
                <pre>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: CancellationPolicy[0]?.childPolicyDescription,
                    }}
                  ></div>
                </pre>
              </Panel>
            </Collapse>
          </Modal>
        </ul>
      </div>
    </div >
  );
};

export default TicketActivites;
