import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSytContext } from "../../../common/providers/SytProvider";
import Banner from "../../../components/banner/Banner";
import Hotels from "../../../components/Hotels/Hotels";
import * as ReactBoostrap from "react-bootstrap";
import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
import Subscribe from "../../../components/subscribe/Subscribe";
import moment from "moment";
import Reviews from "../../../common/Reviews/Reviews";
import { AppConstants } from "../../../helpers/constants";
import "../HotelSearch/HotelSearch.scss";
import { useSelector } from "react-redux";
import SeoMetaData from "../../../common/Seo/SeoMetaData";

const HotelSearch = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { hotelBanners } = useSytContext();
  const [cityHotelData, setCityHotelData] = useState([]);
  const ReduxContainer = useSelector(state => state);

  const getCityHotelList = () => {
    ApiClient.get("admin/cityHotels")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter(
            (item) => item.Status === 0 && item.Servicetype === 2
          );
          let result = data.reduce(function (obj, key) {
            obj[`${key.CityName}, ${key.CountryName}`] =
              obj[`${key.CityName}, ${key.CountryName}`] || [];
            obj[`${key.CityName}, ${key.CountryName}`].push(key);
            return obj;
          }, {});

          setCityHotelData(result);
        }
      })
      .catch((e) => console.log("api error", e));
  };

  useEffect(() => {
    getCityHotelList();
  }, []);

  const getHotelUrl = (url) => {
    if (url) {
      let params = queryString.parse(url);
      params.checkInDate = moment().add(1, "days").format("YYYY-MM-DD");
      params.checkOutDate = moment().add(2, "days").format("YYYY-MM-DD");
      params = queryString.stringify(params);
      return `/hotels/listing?${params}`;
    } else {
      return "";
    }
  };

  return (
    <div className="hotels_search_container">
      <SeoMetaData path={`${window.location.pathname}`} />
      <section className="hotel_banner">
        <div>
          <Banner banner={hotelBanners} />
        </div>

        <div className="heading_text">
          <h2>
            Hotels and accommodation. Book safely with the option of
            cancellation.
          </h2>
        </div>
        <div className="hotel_search">
          <Hotels hotelSearch={props.hotelContext} />
        </div>
      </section>

      <section className="bus-support-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts.png").default
                  }
                  alt="icon"
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  {/* <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p> */}
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={
                    require("../../../assets/images/one-icon-ts1.png").default
                  }
                  alt="icon hotel"
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  {/* <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <div className="container">
        {/*--start----end--*/}

        <Row className="heading-hotel">
          <Col md={24} xs={24}>
            <h3>Only here! New, larger accommodation base</h3>
            <p>
              Hotels, apartments, bungalows &amp; homestays - just the way you
              like it
            </p>
          </Col>
        </Row>

        {Object.keys(cityHotelData).length > 0
          ? Object.keys(cityHotelData).map((cityHotel, index) => (
              <div key={index}>
                <Row gutter={[6, 6]}>
                  <Col md={24} xs={24}>
                    <p className="text-center font-weight-bold">{cityHotel}</p>
                  </Col>
                  {cityHotelData[cityHotel].map((item, i) => (
                    <Col md={6} xs={24} key={i}>
                      <Link to={getHotelUrl(item.SearchURL)} className="d-block">
                        <img
                          className="cart-shopping-img"
                          src={
                            item.HotelImage1
                              ? `${BASE}${item.HotelImage1.substring(1)}`
                              : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                                  .default
                          }
                          alt="Hotel Image"
                        />
                      </Link>
                      <p className="text-center font-weight-bold mb-0">
                        {item.Title}
                      </p>
                    </Col>
                  ))}
                </Row>{" "}
              </div>
            ))
          : null}
      </div>

      <section className="hotels_advantages">
        <div className="hotels-advantages-wrapper">
          <div className="hotels-adv-box left-box">
            <img
              src={
                require("../../../assets/images/hotels/save_up_to.png").default
              }
              alt="hotel icon"
            />
            <div className="hotels-adv-box-wrapper">
              <p> SAVE UP TO 30%!</p>
              <small>
                We've made deals with over 1.3 M hotels so you can book up to
                30% cheaper
              </small>
            </div>
          </div>
          <div className="hotels-adv-box right-box">
            <img
              src={require("../../../assets/images/hotels/no_tf.png").default}
              alt="hotel icon"
            />
            <div className="hotels-adv-box-wrapper">
              <p> NO HIDDEN CHARGES</p>
              <small>
                We tell you exactly what you'll pay from the very beginning -
                transparent reservation policies mean no nasty surprises
              </small>
            </div>
          </div>
        </div>
      </section>
      <section className="queries_about_hotels">
        <div className="query-container">
          <div className="query-hotels-heading">
            <h2>Any questions about hotel or your booking?</h2>
            <p>You will find the answers in our Travel Guide</p>
          </div>
          <div className="query-hotels-body">
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-1.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">What do hotel stars mean?</a>
                  <p>
                    The number of stars indicates the standard and amenities
                    that you can expect in a hotel. The more, the more luxurious
                    the conditions are. However,..
                  </p>
                </div>
              </div>
            </ReactBoostrap.Col>
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-2.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">Different types of hotel rooms</a>
                  <p>
                    Hotel rooms may be divided due to the number and type of
                    beds. Single room (SGL) – a room for one guest, with a
                    single bed. Depending on the..
                  </p>
                </div>
              </div>
            </ReactBoostrap.Col>
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-3.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">Types of breakfast</a>
                  <p>
                    In different hotels around the world you can find various
                    kinds of breakfast. It's worth checking the breakfast offer
                    during the booking process. We..
                  </p>
                </div>
              </div>
            </ReactBoostrap.Col>
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-body">
                  <a href="/">
                    Check-in procedures for hotels, apartments, guest houses
                  </a>
                  <p>
                    All hotels or similar establishments usually require guests
                    to..
                  </p>
                </div>
              </div>
            </ReactBoostrap.Col>
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-body">
                  <a href="/">Types of beds</a>
                  <p>There are several basic types of hotel beds: single..</p>
                </div>
              </div>
            </ReactBoostrap.Col>
            <ReactBoostrap.Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-body">
                  <a href="/">
                    Additional services available and offered in hotels
                  </a>
                  <p>
                    Many hotels offer additional services such as: wake-up
                    calls, laundry..
                  </p>
                </div>
              </div>
            </ReactBoostrap.Col>
          </div>
        </div>
      </section>
      <section className="top-route-bb">
        <Reviews serviceType={2} />
      </section>
      <section className="discounts_container">
        <Subscribe />
      </section>
    </div>
  );
};

export default HotelSearch;
