import React, { useState, useEffect } from "react";
import {
  Button,
  Breadcrumb,
  Carousel,
  Col,
  Divider,
  Row,
  Skeleton,
  Layout,
} from "antd";
import "./activitiestourdetail.scss";
import queryString from "query-string";
import Table from "react-bootstrap/Table";
import CustomNoResultFound from "../../../components/ErrorPages/CustomNoResultFound";
import ApiClient from "../../../helpers/ApiClient";
import ActivitiesReview from "./ActivitiesReview";
import ActivitiesSelectpkg from "./ActivitiesSelectpkg";
import moment, { duration } from "moment";
import Item from "antd/lib/list/Item";

const { Content } = Layout;

const ActivitiesTourDetail = () => {
  const [isLoading, setLoading] = useState(true);
  const [tourDetail, setTourDetail] = useState({});
  const [tourDuration, setTourDuration] = useState('')

  const selectTours = () => {
    const param = queryString.parse(document.location.search);

    const ReqObj = {
      traceId: param.traceId,
      tourId: param.tourId,
      apikey: param.key,
    };

    if (ReqObj.traceId != null && ReqObj.tourId != null) {
      ApiClient.post("activities/tours/details", ReqObj)
        .then((result) => result)
        .then((resp) => {
          if (resp.statusCode == 200) {
            setTourDetail({
              ...resp.data,
              traceId: ReqObj.traceId,
              apikey: ReqObj.apikey,
            });
            const tourId = resp.data.tourId;
            /**Testing Mode ON  */
            ApiClient.get(`extranet/getAllActivities`)
              .then((resp, index) => {
                if (resp.status == 200) {
                  let data = resp.data.filter(item => item.tourId == tourId);
                  let tourDuration = data[0].duration;
                  setTourDuration(tourDuration);
                } else if (resp.status == 400) {
                  console.error("Failed To Activities Data", 3);
                }
              })
              .catch((error) => {
                console.log(error);
              });
            /**Testing Mode Here */
          } else {
            setTourDetail({});
          }
          setLoading(false);
        })
        .catch((err) => {
          setTourDetail({});
        });
    }
  };

  // const getAllActivities = () => {
  //   ApiClient.get(`extranet/getAllActivities`)
  //     .then((resp, index) => {
  //       if (resp.status == 200) {
  //         console.log(resp);
  //       } else if (resp.status == 400) {
  //         console.error("Failed To Activities Data", 3);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    selectTours();
    // getAllActivities();
  }, []);

  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        {isLoading ? (
          <div className="hotel-skeleton-layout">
            <div className="hotel-skeleton-layout-container">
              <Row>
                <Col md={6} xs={24}>
                  <Skeleton paragraph={{ rows: 0 }} />
                </Col>
                <Col md={18} xs={24}>
                  <Skeleton paragraph={{ rows: 2 }} />
                  <Skeleton paragraph={{ rows: 2 }} />
                  <Divider />
                </Col>
              </Row>
              <Row gutter={16}>
                <Row>
                  <Col md={6} xs={24}>
                    <Skeleton paragraph={{ rows: 0 }} />
                  </Col>
                  <Col md={18} xs={24}>
                    <Skeleton paragraph={{ rows: 3 }} />
                  </Col>
                </Row>
              </Row>
            </div>
          </div>
        ) : (
          <Content className="admin-container cms-pages-width-places">
            {Object.keys(tourDetail).length > 0 ? (
              <>
                <Row>
                  <Col span={24}>
                    <Carousel autoplay>
                      {tourDetail.tourImages.map((x) => (
                        <div key={x.tourId}>
                          <img
                            className="slider-img-holidays"
                            src={x.imagePath}
                            alt="slider-img"
                          />
                        </div>
                      ))}
                    </Carousel>
                  </Col>
                </Row>

                {/* {/--near--places--start--/} */}

                <Row className="bread-cubs">
                  <Col md={18} xs={24}>
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <a href="#rates-tour">TOUR RATES</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <a href="#incluopt">INCLUSIONS</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <a href="#tmint">TIMINGS</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <a href="#infoint">IMPORTANT INFORMATION</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <a href="#pobook">BOOKING POLICY</a>
                      </Breadcrumb.Item>

                      <Breadcrumb.Item>
                        <a href="#reviewbox">REVIEWS</a>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </Col>
                  <Col md={6} xs={24}>
                    <Button className="book-cart-all">
                      <a href="#rates-tour">Book Now</a>
                    </Button>
                  </Col>
                </Row>

                {/* {/--close--bread--/} */}

                <Row className="holidays-near">
                  <Col md={24} xs={24}>
                    <h3>{tourDetail.tourName}</h3>
                    <h5>{tourDetail.cityTourType}</h5>
                    {/* {/--first--row--close--/}

                    {/new--section--start/} */}
                    <Row className="new-sec-1key">
                      <Col md={24} xs={24}>
                        <h4 className="deatils-key">Key Details</h4>
                        <Row>
                          <Col md={6} xs={24}>
                            <div className="key-option">
                              <i
                                className="fa fa-calendar tour-favi"
                                aria-hidden="true"
                              ></i>
                              <span className="aling-middle">
                                Availability: Daily
                              </span>
                            </div>
                          </Col>

                          <Col md={6} xs={24}>
                            <div className="key-option">
                              <i
                                className="fa fa-hourglass-half tour-favi"
                                aria-hidden="true"
                              ></i>
                              <span className="aling-middle">
                                Duration:{tourDetail.duration == undefined ? tourDuration : tourDetail.duration}                              </span>
                            </div>
                          </Col>

                          <Col md={6} xs={24}>
                            <div className="key-option">
                              <i
                                className="fa fa-clock-o tour-favi"
                                aria-hidden="true"
                              ></i>
                              <span className="aling-middle">
                                Starting Time:
                                {tourDetail.startTime}
                              </span>
                            </div>
                          </Col>

                          <Col md={6} xs={24}>
                            <div className="key-option">
                              <i
                                className="fa fa-flash tour-favi"
                                aria-hidden="true"
                              ></i>
                              <span className="aling-middle">
                                Instant Confirmation
                              </span>
                            </div>
                          </Col>

                          {/* <Col md={6} xs={24}>
                            <div className="key-option">
                              <i
                                className="fa fa-car tour-favi"
                                aria-hidden="true"
                              ></i>
                              <span className="aling-middle">
                                Pickup & Drop Back
                              </span>
                            </div>
                          </Col> */}

                        </Row>

                        <Row className="key-row-2">

                          {/* <Col md={6} xs={24}>
                            <div className="key-option">
                              <i
                                className="fa fa-check-square-o tour-favi"
                                aria-hidden="true"
                              ></i>
                              <span className="aling-middle">Check Rooms</span>
                            </div>
                          </Col> */}

                          <Col md={6} xs={24}>
                            <div className="key-option">
                              <i
                                className="fa fa-print tour-favi"
                                aria-hidden="true"
                              ></i>
                              <span className="aling-middle">
                                Printed Voucher Accepted
                              </span>
                            </div>
                          </Col>

                          {/* <Col md={6} xs={24}>
                            <div className="key-option">
                              <i
                                className="fa fa-language tour-favi"
                                aria-hidden="true"
                              ></i>
                              <span className="aling-middle">
                                {tourDetail.tourLanguage}
                              </span>
                            </div>
                          </Col> */}

                        </Row>
                      </Col>
                    </Row>

                    <Row className="new-sec-1key des-row-key">
                      <Col md={24} xs={24}>
                        <h4 className="deatils-key">DESCRIPTION</h4>
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: tourDetail.tourDescription,
                            }}
                          ></div>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <ActivitiesSelectpkg
                  tourDetail={tourDetail}
                  isfromPackage={false}
                  searchReq={tourDetail.searchRequest}
                />

                <Row className="new-sec-1key des-row-key">
                  <Col md={24} xs={24} className="img-inclusion-c2">
                    <h4 className="deatils-key " id="incluopt">
                      INCLUSIONS
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: tourDetail.tourInclusion,
                      }}
                    ></div>
                  </Col>
                </Row>

                <Row className="new-sec-1key des-row-key">
                  <Col md={24} xs={24}>
                    <h4 className="deatils-key" id="tmint">
                      Timings
                    </h4>
                    <Table responsive="xl" striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Duration</th>
                          <th>Departure Point</th>
                          <th>Reporting Point</th>
                          <th>Tour Language</th>
                          <th>Meals</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{tourDetail.duration == undefined ? tourDuration : tourDetail.duration}</td>
                          <td>{tourDetail.departurePoint}</td>
                          <td>{tourDetail.reportingTime}</td>
                          <td>{tourDetail.tourLanguage}</td>
                          <td>{tourDetail?.meal}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>

                {tourDetail.importantInformation ?
                  <Row className="new-sec-1key des-row-key">
                    <Col md={24} xs={24}>
                      <h4 className="deatils-key" id="infoint">
                        IMPORTANT INFORMATION
                      </h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tourDetail.importantInformation,
                        }}
                      ></div>
                    </Col>
                  </Row> : null}


                <Row className="new-sec-1key des-row-key">
                  <Col md={24} xs={24}>
                    <h4 className="deatils-key" id="pobook">
                      {tourDetail.cancellationPolicyName}
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: tourDetail.cancellationPolicyDescription,
                      }}
                    ></div>

                    <h4 className="deatils-key" id="pobook">
                      {tourDetail.childCancellationPolicyName}
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: tourDetail.childCancellationPolicyDescription,
                      }}
                    ></div>
                  </Col>
                </Row>

                {tourDetail.tourExclusion ?
                  <Row className="new-sec-1key des-row-key">
                    <Col md={24} xs={24}>
                      <h4 className="deatils-key" id="pobook">
                        Exclusion{' '}
                      </h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tourDetail.tourExclusion,
                        }}
                      ></div>
                    </Col>
                  </Row> : null}

                {tourDetail.usefulInformation ?
                  <Row className="new-sec-1key des-row-key">
                    <Col md={24} xs={24}>
                      <h4 className="deatils-key" id="pobook">
                        UseFull Information{' '}
                      </h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tourDetail.usefulInformation,
                        }}
                      ></div>
                    </Col>
                  </Row> : null}

                {tourDetail.itenararyDescription ?
                  <Row className="new-sec-1key des-row-key">
                    <Col md={24} xs={24}>
                      <h4 className="deatils-key" id="pobook">
                      Itinerary Description{' '}
                      </h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tourDetail.itenararyDescription,
                        }}
                      ></div>
                    </Col>
                  </Row> : null}

                {tourDetail.termsAndConditions ?
                  <Row className="new-sec-1key des-row-key">
                    <Col md={24} xs={24}>
                      <h4 className="deatils-key" id="pobook">
                        Terms & conditions{' '}
                      </h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tourDetail.termsAndConditions,
                        }}
                      ></div>
                    </Col>
                  </Row> : null}

                {tourDetail.questions ?
                  <Row className="new-sec-1key des-row-key">
                    <Col md={24} xs={24}>
                      <h4 className="deatils-key" id="pobook">
                        Common Questions{' '}
                      </h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tourDetail.questions,
                        }}
                      ></div>
                    </Col>
                  </Row> : null}
                {/* {/--near--places--end--/}

                {/--reviewa----open--/} */}
                <Row>
                  <Col md={18} xs={24}>
                    <h3 className="booking-option" id="reviewbox">
                      Reviews
                    </h3>
                  </Col>

                  <Col md={24} xs={24}>
                    <ActivitiesReview Reviews={tourDetail} />
                  </Col>
                </Row>
              </>
            ) : (
              <CustomNoResultFound title={"No Tour Available"} />
            )}
            {/* {/--reviewa----close--/} */}
          </Content>
        )}
      </div>
    </div>
  );
};
export default ActivitiesTourDetail;