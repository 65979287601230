import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Popover,
  Radio,
  message,
} from "antd";

import GlobalStatesContext from "../../../common/providers/GlobalStatesContext";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import APIClient from "../../../helpers/ApiClient";

import { CloseOutlined } from "@ant-design/icons";

export const getPromoDiscount = (promoData, total) => {
  let promoAmount = 0;

  if (promoData && promoData?.status) {
    if (promoData.DiscountType == 1) {
      promoAmount = Number((total / 100) * promoData.Discount);
    } else {
      promoAmount = Number(promoData.Discount);
    }
  }

  return Number(promoAmount);
};
export const getHotelGrandTotal = (
  hotelDetailsObj,
  hotelSearchData,
  ConvFee,
  promoData,
  selectedInsuranceData,
  redeemAmount,
  transactionFee,
  IsPaymentSelociton
) => {
  let totalRoomBaseFare = 0;
  let totalRoomTax = 0;
  let totalOtherCharges = 0;
  let totalExtraGuestCharges = 0;
  let noOfNights = 0;
  let convamount = 0;
  let discount = 0;
  let noOfRooms = 0;
  let adminCommission = 0;
  let agentMarkup = 0;
  let markup = 0;
  let insuranceTotal = 0;
  let totalCommission = 0;
  let redeemTotal = 0;
  let gstOnMarkup = 0;
  let tds=0;
  let totalIncAmount = 0;
  let grandTotal;
  let roomDetails = {
    roomCount: 0,
    roomNightPrice: 0,
    totalTax: 0,
  };
  let tranxFree = 0;

  if (Object.keys(hotelSearchData).length > 0) {
    let checkin = new Date(hotelSearchData.checkInDate);
    let checkout = new Date(hotelSearchData.checkOutDate);
    let diffTime = checkout - checkin;
    let diffDays = Math.ceil(diffTime / (1000 * 24 * 60 * 60));

    noOfNights = Number(diffDays);
  } else return;
let hotelTotalPrice=0;
  if (hotelDetailsObj?.rooms?.length > 0) {
    noOfRooms = hotelDetailsObj?.rooms.length;
    hotelDetailsObj?.rooms.forEach((roomDetail) => {
      if (roomDetail?.ratePlans?.length > 0) {
        const priceObj = roomDetail?.ratePlans[0].price;
        hotelTotalPrice+=  priceObj.total;
        totalRoomBaseFare += Number(priceObj.base);
        totalRoomTax += Number(priceObj.tax);
        totalOtherCharges += Number(priceObj.otherCharges);
        totalExtraGuestCharges += Number(priceObj.extraGuestCharges);
        agentMarkup += Number(priceObj.agentMarkup);
        adminCommission += Number(priceObj.adminCommission);
        markup += Number(priceObj.markup);
        totalCommission += Number(priceObj.commission);
        gstOnMarkup+= Number(priceObj.gstOnMarkup);
        tds+= Number(priceObj.tds);
        totalIncAmount+= Number(priceObj.allInctotalAmount);
      }
    });
  }

  // let grandBaseFare = Number(totalRoomBaseFare) * noOfNights;
  // let grandTax = Number(totalRoomTax) * noOfNights;
  // let grandOtherCharges = Number(totalOtherCharges) * noOfNights;
  // let grandExtraGuestCharges = Number(totalExtraGuestCharges) * noOfNights;
  let grandBaseFare = Number(totalRoomBaseFare);
  let grandTax = Number(totalRoomTax);
  let grandOtherCharges = Number(totalOtherCharges);
  let grandExtraGuestCharges = Number(totalExtraGuestCharges);

  let totalTax = Number(grandTax) + Number(grandOtherCharges);

  roomDetails = {
    roomCount: noOfRooms,
    roomNightPrice: grandBaseFare,
    totalTax: totalTax,
    tax: grandTax,
    otherCharges: grandOtherCharges,
    extraGuestCharges: grandExtraGuestCharges,
  };

  let total =hotelTotalPrice+ Number(grandExtraGuestCharges);

  if (ConvFee && ConvFee?.type) {
    if (ConvFee.type == 1) {
      convamount = Number(ConvFee.amount);
    } else {
      convamount = Number(
        (total / 100) * Number(ConvFee.amount ? ConvFee.amount : 0)
      );
    }
  }

  if (
    selectedInsuranceData.status === 1 &&
    selectedInsuranceData?.serviceType === 2
  ) {
    let totalPax = hotelSearchData.roomGuests.reduce(
      (acc, cur) => acc + Number(cur.noOfAdults) + Number(cur.noOfChilds),
      0
    );

    insuranceTotal = totalPax * Number(selectedInsuranceData.amount);
  }

  let totalAmount = Number(total) + Number(convamount) + Number(insuranceTotal);

  if((transactionFee?.type=="P" || transactionFee?.type=="p") && IsPaymentSelociton === true) {
    tranxFree =(totalAmount /100) * parseFloat(transactionFee.amount)
  } 

  if ((transactionFee?.type=="f" || transactionFee?.type=="F") && IsPaymentSelociton === true) {
    tranxFree = parseFloat(transactionFee.amount)
  }

  discount = getPromoDiscount(promoData, totalAmount);
  totalAmount = Number(totalAmount) - Number(discount);

  if (redeemAmount?.CouponAmt) {
    redeemTotal = Number(redeemAmount?.CouponAmt);
  }
  grandTotal = totalAmount;
  grandTotal += tranxFree;
  grandTotal -= redeemTotal;
  //if (totalCommission) totalAmount// -= totalCommission;

  return {
    roomDetails,
    noOfNights,
    discount,
    totalAmount: Number(totalAmount).toFixed(2),
    convamount,
    totalCommission,
    adminCommission,
    agentMarkup,
    markup,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
    redeemTotal: redeemTotal,
    totalIncAmount:totalIncAmount,
    gstOnMarkup:gstOnMarkup,
    tds:tds,
    tranxFree,
    grandTotal: Number(grandTotal).toFixed(2),
    };
};

const HotelFairBox = ({ hotelDetailsObj, hotelSearchData, isPromoVisible }) => {
  const [form] = Form.useForm();
  let dateFormat = "YYYY-MM-DD";
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    state: {
      otherData: { ConvFee, promoData, selectedInsuranceData, redeemAmount, transactionFee },
      SeletedPayOption
    },
    AddConvFee,
    validatePromoCode,
    RemovePromo,
    getInsuranceByServiceType,
    validateRedeemCoupon,
    RemoveRedeemCoupon,
  } = useContext(GlobalStatesContext);

  const [promoCode, setPromoCode] = useState([]);
  const [redeemData, setRedeemData] = useState({});
  const {
    roomDetails,
    noOfNights,
    discount,
    totalAmount,
    convamount,
    insuranceTotal,
    totalCommission,
    redeemTotal,
    totalIncAmount,
    gstOnMarkup,
    tds,
    tranxFree,
    grandTotal
  } = getHotelGrandTotal(
    hotelDetailsObj,
    hotelSearchData,
    ConvFee,
    promoData,
    selectedInsuranceData,
    redeemAmount,
    transactionFee,
    SeletedPayOption
  );

  useEffect(() => {
    if (!agent) {
      AddConvFee(2);
      getpromo();
    }
  }, [agent]);
  useEffect(() => {
    getInsuranceByServiceType(2);
  }, []);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {
            let busPromo = data.filter(
              (promo) =>
                promo.ServiceType === 2 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(busPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };

  const handleApply = (code) => {
    form.setFieldsValue({
      promo: code,
    });

    form.submit();
  };

  const roomFare = () => {
    return (
      <div className="pax-count-acc-body">
        <p>({`${roomDetails.roomCount} Rooms x ${noOfNights} Nights`})</p>
        <p>
          {activeCurrency} {currencyValue(roomDetails.roomNightPrice)}{" "}
        </p>
      </div>
    );
  };
  useEffect(() => {
    if (user) {
      getRedeemCoupon(user);
    }
  }, [user]);
  const getRedeemCoupon = (userData) => {
    setRedeemData({});
    let obj = {
      userId: userData?.UserID,
      roleType: userData?.Role?.RoleId,
      membershipId: userData?.Membership,
    };
    APIClient.post("admin/userAmtWithReedemPer", obj)
      .then((res) => {
        if (res.status === 200) {
          setRedeemData(res.data);
        }
      })
      .catch((error) => {
        setRedeemData({});
      });
  };

  return (
    <>
      <div className="sticky-card-container">
        <Card className="pax-count-card flight-cards-details">
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Room Price</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(roomDetails.roomNightPrice)}
              </p>

              <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={roomFare()}
                title="Room Price"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Taxes and Fees</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(roomDetails.totalTax)}
              </p>
              <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={
                  <>
                    <div className="pax-count-acc-body">
                      <div className="pax-type">
                        <p>Service fee</p>
                      </div>

                      <div className="service-price">
                        <p>
                          {activeCurrency} {currencyValue(roomDetails.tax)}
                        </p>
                      </div>
                    </div>
                    <div className="pax-count-acc-body">
                      <div className="pax-type">
                        <p>Other Charges</p>
                      </div>

                      <div className="service-price">
                        <p>
                          {activeCurrency}{" "}
                          {currencyValue(roomDetails.otherCharges)}
                        </p>
                      </div>
                    </div>
                    {
                      //      agent ? 
                      //      (
                      //    <>
                      //       <div className="pax-count-acc-body">
                      //       <div className="pax-type">
                      //         <p>Markup</p>
                      //       </div>

                      //       <div className="service-price">
                      //         <p>
                      //           {activeCurrency}{" "}
                      //           {/* {currencyValue(markup)} */}
                      //         </p>
                      //       </div>
                      //       </div>
                      //      <div className="pax-count-acc-body">
                      //       <div className="pax-type">
                      //         <p>gstOnMarkup</p>
                      //       </div>

                      //       <div className="service-price">
                      //         <p>
                      //           {activeCurrency}{" "}
                      //           {currencyValue(gstOnMarkup)}
                      //         </p>
                      //       </div>
                      //     </div>
                      //   </>
                      //  ) : null           
                    }
                  </>
                }
                title="Taxes and Fees"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
          {roomDetails?.extraGuestCharges !== 0 && roomDetails?.extraGuestCharges !== null && (
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Extra Guest Charge</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(roomDetails.extraGuestCharges)}
              </p>
            </Col>
          </Row>
          )}
          {promoData?.status ? (
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Discount</p>
              </Col>
              <Col className="d-flex">
                <p>
                  - {activeCurrency} {currencyValue(discount)}
                </p>
              </Col>
            </Row>
          ) : null}

          {selectedInsuranceData.status === 1 &&
          selectedInsuranceData?.serviceType === 2 ? (
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Insurance Amount</p>
              </Col>
              <Col>
                <p>
                  {activeCurrency} {currencyValue(insuranceTotal)}
                </p>
              </Col>
            </Row>
          ) : null}

          {convamount !== null && convamount !== 0 && (
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Convenience Fee</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(convamount)}
              </p>
            </Col>
          </Row>
          )}
          <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Transation Fee :</p>
              </Col>
              <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(tranxFree)}
              </p>
            </Col>
          </Row>
          <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Total :</p>
              </Col>
              <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(totalAmount)}
              </p>
            </Col>
          </Row>
          <div className="pax-total-price">
            <div className="pax-type">
              <p>Final Payment :</p>
            </div>
            <div className="total">
              <p> {activeCurrency} </p>
              <p className="amount ml-1"> {currencyValue(grandTotal)}</p>
            </div>
          </div>
          <p className="all-taxes">Including all taxes and fees</p>
          {agent ? (
            <>
            <div className="pax-total-price">
              <div className="pax-type">
                <p className="pax-comm">- Commission Earned:</p>
              </div>
              <div className="total">
                <p className="pax-comm"> {activeCurrency} </p>
                <p className="amount ml-1 pax-comm">
                  {" "}
                  {currencyValue(totalCommission)}
                </p>
              </div>
            </div>
              <div className="pax-total-price">
              <div className="pax-type">
                <p className="pax-comm">+ TDS:</p>
              </div>
              <div className="total">
                <p className="pax-comm"> {activeCurrency} </p>
                <p className="amount ml-1 pax-comm">
                  {" "}
                  {currencyValue(tds)}
                </p>
              </div>
            </div></>
          ) : null}
        </Card>
      </div>

      {!agent && user?.Role?.RoleLevel !== 3 && isPromoVisible ? (
        <div className="buspromo_wrapper">
          {promoData.status == false ? (
            <Card bordered={false} className="hotel-card-wrapper">
              <p className="name">Apply Promo</p>

              <Form
                layout="vertical"
                form={form}
                onFinish={(d) => {
                  validatePromoCode({
                    ServiceType: 2,
                    Amount: Number(totalAmount),
                    PromoCode: d.promo,
                    UserId: user?.UserID ?? 1,
                  });
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col md={16} sm={12} xs={12}>
                    <Form.Item
                      name="promo"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input
                        className="inputbg"
                        placeholder="Enter Your Promo code"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={12}>
                    <Form.Item>
                      <Button type="primary" onClick={() => form.submit()}>
                        Apply
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              {promoCode.length ? (
                promoCode.map((item, key) => {
                  return (
                    <>
                      <div className="promo-cp-coupons" key={key}>
                        <div className="inline-cp-promo">
                          <Form>
                            <Form.Item>
                              <Radio
                                onClick={() => handleApply(item.Code)}
                              ></Radio>
                            </Form.Item>
                          </Form>
                          <p className="promo-key-cp">{item.Code}</p>
                          {item.DiscountType === 1 ? (
                            <p className="save-cp-offer">
                              Save {item.Discount + " %"}
                            </p>
                          ) : (
                            <p className="save-cp-offer">
                              Save {activeCurrency}&nbsp;
                              {currencyValue(item.Discount)}
                            </p>
                          )}
                        </div>

                        <div className="promo-percentage-cp">
                          <p>{item.Description}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="promo-cp-coupons">
                  <div className="promo-percentage-cp pl-0 pt-0">
                    <p>No Promo Code Available</p>
                  </div>
                </div>
              )}
            </Card>
          ) : (
            <Card bordered={false} className="hotel-card-wrapper">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      RemovePromo();
                      form.resetFields();
                    }}
                  />{" "}
                </p>
              </div>
              <div className="promo-input">
                <p className="mb-0">
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}

      {user && redeemData?.couponAmt > 0 ? (
        redeemAmount.status === true ? (
          <Card className="flight-cards-details mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="name mb-0">
                Redeemed From Coupon Wallet: {redeemTotal}
              </p>
              {!isPromoVisible ? null : (
                <CloseOutlined
                  onClick={() => {
                    RemoveRedeemCoupon();
                  }}
                />
              )}
            </div>
          </Card>
        ) : !isPromoVisible ? null : (
          <Card className="flight-cards-details mt-3">
            <p className="font-weight-bold mb-0">
              Coupon Wallet: {/* {activeCurrency} */}
              {"("}
              {redeemData?.couponAmt} {")"}
            </p>
            {/* <p className="mb-2">
              You can use {redeemData?.reedemPercentage}% of the Total Amount
            </p> */}

            <div className="book-pay-btn">
              {" "}
              <Button
                type="primary"
                className=" redeem_btn"
                onClick={() => {
                  validateRedeemCoupon({
                    userId: user?.UserID ?? 0,
                    roleType: user?.Role?.RoleId ?? 0,
                    membershipId: user?.Membership ?? 0,
                    couponAmt: redeemData?.couponAmt ?? 0,
                  });
                }}
              >
                Redeem Coupon
              </Button>
            </div>
          </Card>
        )
      ) : null}
    </>
  );
};

export default HotelFairBox;
