import React, { useState, useRef, useEffect } from "react";
import * as ReactBootstrap from "react-bootstrap";
import { Button, Card, Col, Skeleton, Rate, Row, message, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import moment from "moment";

import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../../common/providers/AuthProvider";
// import { useHotelContext } from "../../../common/providers/Hotels/HotelProvider";
import ImagesLightbox from "../../../components/ImagesLightbox/ImagesLightbox";
import ApiClient from "../../../helpers/ApiClient";

import queryString from "query-string";
import HotelCardImage from "./HotelCardImage";
import { EnvironmentOutlined } from "@ant-design/icons";
import hotelNoImg from "../../../assets/images/hotels/no_img.png";
import giftimg from "../../../assets/images/cp-gift-icon.png";
import "./HotelDet.scss";
import RoomSort from './RoomSort';

const HotelDet = () => {
  let history = useHistory();
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const { user } = useAuthContext();
  // const { setSelectedHotel } = useHotelContext();

  const [hotelDetailsRespObj, setHotelDetailsRespObj] = useState({});

  const [isRoomModal, setIsRoomModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roomsDetails, setRoomsDetails] = useState({});
  // const [roomsDetails, setRoomsDetails] = useState({ roomList: [], type: "" });
  const [fixRoomsDetails, setRoomsDetailsFix] = useState({ roomList: [], type: "" });
  const [openRoomsDetails, setRoomsDetailsOpen] = useState({ roomList: [], type: "" });
  const [CopyRoomsDetails, setCopyRoomsDetails] = useState({ roomList: []})
  const [isShowModal, setIsShowModal] = useState(false);
  const [roomImagesModal, setRoomImagesModal] = useState({});
  const [selectedRooms, setSelectedRooms] = useState({});
  const [isCheckoutButtonActive, setIsCheckoutButtonActive] = useState(false);

  const [sorting, setSorting] = useState('price');
  const [sortDirection, setSortDirection] = useState('asc');

  // Define month names
  const Month = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  
  const [defaultProps, setDefaultProps] = useState({
    center: {
      address: "",
      lat: 17.42159,
      lng: 78.33752,
    },
    zoom: 12,
    mapVisible: false,
  });

  const cancellationTableStyle = {
    border: "1px solid #fff"
  }

  useEffect(() => {
    fetchHotelDetails();
  }, []);

  const fetchHotelDetails = () => {
    const hotelDetSearchParams = queryString.parse(window.location.search);
    fetchHotelRooms({
      traceId: hotelDetSearchParams.traceId,
      hotelCode: hotelDetSearchParams.hotelId,
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
    });
  };

  const fetchHotelRooms = (params) => {
    setLoading(true);
    setHotelDetailsRespObj({});
    ApiClient.post("hotels-v2/hotelrooms", params)
      .then((res) => res)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.errors.length < 1) {
            let combinedrooms = [];
            // setRoomsList(res.data);

            setHotelDetailsRespObj(res.data);
            if (res.data?.latitude && res.data?.longitude) {
              setDefaultProps((prev) => ({
                ...prev,

                center:{
                  address: res.data && res.data.hasOwnProperty('addresses') && Array.isArray(res.data.addresses) && res.data.addresses.length > 0
                    ? res.data.addresses[0].address
                    : 'Default Address',
                  lat: Number(res.data.latitude),
                  lng: Number(res.data.longitude),
                },
                mapVisible: true,
              }));
            }

            if (res.data?.roomCombination?.length > 0) {
              let roomSet = [];

              const grouped = res.data?.roomCombination.reduce((acc, item) => {
                if (item.fixedFormat === "FixedCombination") {
                    acc.fixed.push(...item.roomCombination);
                } else if (item.fixedFormat === "OpenCombination") {
                    //acc.open.push(...item.roomCombination);
                    item.roomCombination.forEach(cobinEntry => {
                      acc.open = acc.open.concat(cobinEntry.rooms);
                  });
            
                }
                return acc;
            }, { fixed: [], open: [] });

          //     res.data?.roomCombination.map((x) => {
          //       let rooms = [];
          //     if(x.fixedFormat=="FixedCombination"){
          //       let obj={
          //         fixedFormat:"Fixed",
          //         roomCombination:x.roomCombination
          //       }
          //       rooms.push(obj)
          //     }
          //     else if(x.fixedFormat=="OpenCombination"){
          //       let obj={
          //         fixedFormat:"Open",
          //         roomCombination:x.roomCombination
          //       }
          //       roomSet.push(obj)
          //     }
          // //    if(res.data?.request?.roomGuests.length>1){
          // //     combinedrooms = res.data?.roomCombination.map((x) => {
              
          // //       x.roomId.map((roomId) => {
          // //         let room = res.data.rooms.filter((y) => y.roomId == roomId);
          // //         roomSet.push(room[0]);
          //       });

                combinedrooms=grouped;
               // return roomSet;
          //     });
          //   }
          //  else{
          //    roomSet.push([...res.data.rooms]);
          //    combinedrooms = roomSet;
          //   }
          }
          
            // if(res.data?.request.roomGuests.length>1){
            setRoomsDetailsFix({roomList: combinedrooms.fixed,type: 'Fixed'});
            // get 
            if(combinedrooms.fixed.length > 0){
              setRoomsDetails({roomList:  combinedrooms.fixed,type:'Fixed',}) 
            }else if(combinedrooms.open > 0) {
              setRoomsDetails({roomList:  combinedrooms.fixed,type:'Open',}) 
            }
            // }

              if(res.data?.request.roomGuests.length==1){
              setRoomsDetailsOpen({roomList: combinedrooms.open,
                type: 'Open'});
              }
              let margeObj = [];
              margeObj.push({roomList: combinedrooms.open,type: 'Open'}, {roomList: combinedrooms.fixed,type: 'Fixed'})
              setRoomsDetails(margeObj);
          
          } else {
            setRoomsDetails({
              roomList: [],
              type: "",
            });
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSelectedRooms = (hotelRoom, key,indx) => {
    let array = Object.keys(selectedRooms).map((key) => selectedRooms[key]);
    
    if(selectedRooms.hasOwnProperty(key)){
      let copyData = { ...selectedRooms };

      delete copyData[key]
      setSelectedRooms(copyData);
      setIsCheckoutButtonActive(false);
    }else{
    if(hotelDetailsRespObj.request.roomGuests.length <= array.length){
      message.error(`You Can not select Rooms More than ${hotelDetailsRespObj.request.roomGuests.length}`, 3);
    }else{
    let copyData = { ...selectedRooms };
    copyData[key] = hotelRoom;
    copyData[key]["selectedRoomIndx"]= indx;
    copyData[key]["key"]=key
    setSelectedRooms(copyData);
    setIsCheckoutButtonActive(true);
    }}
  };

  const handleCheckout = () => {
    let array = Object.keys(selectedRooms).map((key) => selectedRooms[key]);
    if (hotelDetailsRespObj.request.roomGuests.length === array.length) {
      navigateToCheckout(array);
    } else if(hotelDetailsRespObj.request.roomGuests.length <= array.length){
      message.error(`You Can not select Rooms More than ${hotelDetailsRespObj.request.roomGuests.length}`, 3);
    }else
    {
      message.error("Please select Rooms", 3);
    }
  };

  const navigateToCheckout = (roomsArray) => {
    if (roomsArray.length > 0) {
      const hotelDetSearchParams = queryString.parse(window.location.search);

      let roomPlan = roomsArray.map((data) => ({
        roomID: data.roomId,
        rateID: data.ratePlans[0].ratePlanId,
      }));

      let query = {
        traceId: hotelDetSearchParams.traceId,
        roomsId: hotelDetailsRespObj.roomsId,
        roomPlan: JSON.stringify(roomPlan),
      };
      query = queryString.stringify(query);
      history.push(`/hotels/hotel-checkout?${query}`);
    } else {
      message.error("Please select Rooms", 3);
    }
  };

  const backToList = () => {
    history.goBack("/hotels/listing");
  };

  let myRef1 = useRef(null);
  let myRef2 = useRef(null);
  let myRef3 = useRef(null);
  let myRef4 = useRef(null);
  let myRef5 = useRef(null);

  const scrollToRef = (ref) => {
    try {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    } catch (error) {}
  };

  // /* Opening Hotel location in Map */
  // const onHandleHotelLocation = (hotelRespObj) => {
  //   let hotelRespObjToArray = [];
  //   hotelRespObjToArray.push(hotelRespObj);
  //   setSelectedHotel(hotelRespObjToArray);
  //   history.push("/hotel-location");
  // };

  /* Triggering rooms images modal */
  const onHandleModal = (roomObj) => {
    setRoomImagesModal(roomObj);
    setIsShowModal(true);
  };

  const getRoomDec = (roomDesc, ratePlans) => {
    return (
      <div className="tooltipWrapper">
        <p>
          <b> {roomDesc} </b>
        </p>
        <p>Policies:</p>
        {ratePlans.cancellationPolicy[0]?.policies.map((pol, i) => (
          <div key={pol + i}> {ReactHtmlParser(pol)}</div>
        ))}
      </div>
    );
  };
  const Marker = ({ text }) => (
    <div className="markerWrapper">
      <EnvironmentOutlined />
    </div>
  );
  const guestCount = (roomGuests) => {
    return roomGuests.reduce(
      (acc, cur) => acc + (cur.noOfChilds + cur.noOfAdults),
      0
    );
  };

  const getAllInclusions = (inclusions) => {
    let inclusion = inclusions.split(",");

    if (inclusion.length > 2) {
      return (
        <Tooltip
          title={inclusion.map((i, key) => (
            <p key={key} className="mb-0">
              {i}
            </p>
          ))}
        >
          <p className="inclusion-more">Show More</p>
        </Tooltip>
      );
    }
  };
  const [moreFacilities, setMoreFacilities] = useState(29);
  const showMore = () => {
    setMoreFacilities((prev) => prev + 100);
  };

  const cancellationPolicyTable = (ratePlan) => {
    return (
      <table className="cancellationDetails text-center w-100">
        <thead>
          <tr>
            <th style={cancellationTableStyle}>Cancelled on or After</th>
            <th style={cancellationTableStyle}>Cancelled on or Before</th>
            <th style={cancellationTableStyle}>Cancellation Charges</th>
          </tr>
        </thead>
        <tbody>
          {ratePlan.cancellationPolicy.length > 0 && ratePlan.cancellationPolicy.map((cancellationDate, i) => {
            return (
              <tr className={`cancellationDate${i}`} style={cancellationTableStyle}>
                <td>{dateFormating(cancellationDate?.fromDate)}</td>
                <td>{dateFormating(cancellationDate?.toDate)}</td>
                {/* <td>{cancellationDate.penaltyAmount === '100' ? `${cancellationDate.penaltyAmount}%` : `${currencyValue(cancellationDate.penaltyAmount)} ${activeCurrency}`}</td> */}
                <td>{parseInt(cancellationDate.penaltyAmount) <= 100 ? `${cancellationDate.penaltyAmount}%` : `${currencyValue(cancellationDate.penaltyAmount)} ${activeCurrency}`}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  };

  const dateFormating = (date) => {
    let day, monthNum, year;
    if (date.includes('-')) {
      [day, monthNum, year] = date?.split('-');
    }else if (date.includes('/')) {
      [monthNum, day, year] = date?.split('/');
    }else {
      console.error('Invalid date format: ' + date);
    }
    const [currentYear, time] = year?.split(" ");
    const monthName = Month[parseInt(monthNum) - 1];
    const formattedDate = `${day}/${monthName}/${currentYear}`;
    return formattedDate;
  }

  // code add by karthik on test purpose 29-Dec-2023
  const compareTodayDate = (date, ...args) => {
    // Refundable logic for multiple rooms
    if(args.length > 0 && args[0].length > 1) {
      let condition = new Array();
      for(let i=0; i<args[0].length; i++) {
        let validate = compareTodayDate(moment(args[0][i].ratePlans[0].lastCancellationDate).format("DD/MM/YYYY"));
        condition.push(validate);
      }
      if(condition.length > 0) {
        let check = condition.filter(x => x === "Non Refundable");
        if (check.length === args[0].length) {
          return "Non Refundable";
        } else {
          condition = condition.filter(x => x !== "Non Refundable");
          return compareTodayDate(condition[0])
        }
      }
    }
    // Refundable logic for only one room
    let lastCancellationDate = moment(date, 'DD/MM/YYYY'); // Adjust the format here if needed
    let currentDate = moment(); // Get the current date
  
    if (lastCancellationDate.isBefore(currentDate, 'day')) {
      return "Non Refundable";
    }
    return lastCancellationDate.format('DD/MM/YYYY');
  };

  const sortRoom = (selSorting, dir) => {
    // const OpenRoomDetails = roomsDetails[0];
    // const FixRoomDetails = roomsDetails[1];

    if(openRoomsDetails?.roomList?.length > 0){
        let OpenPriceFilterList = openRoomsDetails?.roomList?.slice().sort((roomA, roomB) => {
            if (selSorting === 'price') {
                const priceA = Math.round(roomA?.ratePlans[0]?.price?.total);
                const priceB = Math.round(roomB?.ratePlans[0]?.price?.total);
                return dir === 'asc' ? priceA - priceB : priceB - priceA;
            }
            return 0;
        });

        setRoomsDetailsOpen({ roomList: OpenPriceFilterList, type: "Open" })
    }
    
    if(fixRoomsDetails?.roomList.length > 0){
        let FixPriceFilterList = fixRoomsDetails?.roomList?.slice().sort((roomA, roomB) => {
            if (selSorting === 'price') {
                const priceA = Math.round(roomA?.rooms[0]?.ratePlans[0]?.price?.total);
                const priceB = Math.round(roomB?.rooms[0]?.ratePlans[0]?.price?.total);
                return dir === 'asc' ? priceA - priceB : priceB - priceA;
            }
            return 0;
        });
    setRoomsDetailsFix({roomList: FixPriceFilterList, type: "Fixed"})
  }
  };

  useEffect(() => {
    sortRoom(sorting, sortDirection);
  }, [roomsDetails, sorting, sortDirection]);

  const handlePriceSortClick = () => {
    const newSortDirection = sorting === 'price' ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
    setSorting('price');
    setSortDirection(newSortDirection);
  };
  
  const descriptionHtml = hotelDetailsRespObj?.description;
  const parsedHtml = typeof descriptionHtml === 'string' ? ReactHtmlParser(descriptionHtml.replaceAll(" mi ", "mi<br />")) : null;

  return (
    <div className="hotel-details">
      <section className="hotel-details-header">
        <div className="details-header-container">
          {loading ? (
            <div className="hotel-full-address">
              <div className="hotel-header-wrapper">
                <Row className="hotel-detail-header-row" align="middle">
                  <Col md={18} xs={24} className="hotel-details-header-right">
                    <Skeleton active={true} paragraph={{ rows: 1 }} />
                  </Col>
                  <Col md={6} xs={0} className="show-moreskeleton-btn">
                    <Skeleton.Button active={true} size={"large"} />
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            Object.keys(hotelDetailsRespObj).length > 0 && (
              <div className="hotel-full-address">
                <div className="hotel-header-wrapper">
                  <div className="hotel-name">
                    <div className="hotel-name-wrapper">
                      <h4>
                        {hotelDetailsRespObj?.hotelName}
                        {hotelDetailsRespObj?.starRating && (
                          <sup>
                            <Rate
                              className="starRating"
                              disabled
                              defaultValue={Number(
                                hotelDetailsRespObj.starRating
                              )}
                              allowHalf={true}
                            />
                          </sup>
                        )}
                      </h4>

                      <p>{Object.hasOwnProperty(hotelDetailsRespObj?.addresses) && hotelDetailsRespObj?.addresses[0]?.address}</p>

                      {/* <span>GREAT LOCATION!</span> */}
                    </div>
                    <div className="show-rooms-btn">
                      <Button onClick={() => scrollToRef(myRef2)}>
                        Show rooms
                        <i
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}

          <div className="sticky-links">
            <ul>
              <li>
                <a onClick={() => scrollToRef(myRef1)}>gallery</a>
              </li>
              <li>
                <a onClick={() => scrollToRef(myRef2)}>rooms</a>
              </li>

              <li>
                <a onClick={() => scrollToRef(myRef3)}>
                  about hotel and facilities{" "}
                </a>
              </li>
              <li>
                <a onClick={() => scrollToRef(myRef4)}>
                  Check-in Instructions{" "}
                </a>
              </li>

              <li>
                <a onClick={() => scrollToRef(myRef5)}>Special Instructions </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {loading ? (
        <div className="cp-hotel-inf0-top">
          <Row gutter={[16, 16]}>
            <Col md={10} xs={24} className="hotel-details-carsl-img">
              <Skeleton.Image />
            </Col>
            <Col md={7} xs={24}>
              <Skeleton active />
              <Skeleton active />
            </Col>
            <Col md={7} xs={24}>
              <Skeleton active />
              <Skeleton active />
            </Col>
          </Row>
        </div>
      ) : (
        Object.keys(hotelDetailsRespObj).length > 0 && (
          <Row className="cp-hotel-inf0-top">
            <Col md={10} xs={24}>
              <section
                className="hotel-detail-images cp-slider-hotel"
                ref={myRef1}
              >
                <div className="light-box-wrapper">
                  <ReactBootstrap.Container>
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col md={12}>
                        {hotelDetailsRespObj?.images?.length > 0 ? (
                          <ImagesLightbox
                            hotelImages={hotelDetailsRespObj.images}
                          />
                        ) : (
                          <img src={hotelNoImg} alt="no-photo" />
                        )}
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </ReactBootstrap.Container>
                </div>
              </section>
            </Col>
            {Object.keys(hotelDetailsRespObj).length > 0 ? (
              <Col md={7} xs={24} className="check-out-cp-panel">
                <div className="cp-bdr-info">
                  <h4>{hotelDetailsRespObj.propertyName}</h4>

                  <div className="cp-details-check">
                    <strong>Star Rating</strong>
                    <div className="hotel-star">
                      <Rate
                        className="starRating"
                        disabled
                        value={Number(hotelDetailsRespObj.starRating)}
                        allowHalf={true}
                      />
                    </div>
                  </div>
                  <div className="cp-details-check">
                      <p>Address: </p>
                      <div className="hotel-star">
                      {hotelDetailsRespObj?.addresses?.[0]?.address ?? undefined}
                      </div>
                    </div>
                  {/* <div className="cp-location-address">
                    <p>
                      <strong>Address: </strong>
                      <span>{(hotelDetailsRespObj?.addresses) && hotelDetailsRespObj?.addresses[0]?.address}</span>
                    </p>
                    <p>
                      <strong>City: </strong>
                      <span>{(hotelDetailsRespObj?.addresses) && hotelDetailsRespObj?.addresses[0]?.cityName}</span>
                    </p>
                    <p>
                      <strong>PIN: </strong>
                      <span>{(hotelDetailsRespObj?.addresses) && hotelDetailsRespObj?.addresses[0]?.postalCode}</span>
                    </p>
                  </div> */}
                  <div className="cp-details-check">
                    <p>City: </p>
                    <div className="hotel-star">
                      {/* {hotelDetailsRespObj?.addresses[0]?.address} */}
                      {hotelDetailsRespObj?.addresses?.[0]?.cityName ?? undefined}
                    </div>
                  </div>
                  <div className="cp-details-check">
                    <p>PIN: </p>
                    <div className="hotel-star">
                      {/* {hotelDetailsRespObj?.addresses[0]?.address} */}
                      {hotelDetailsRespObj?.addresses?.[0]?.postalCode ?? undefined}
                    </div>
                  </div>

                </div>

                {/* <div className="cp-gift-img-text">
                  <div className="img-text-cp-level">
                    <img src={giftimg} alt="no-photo" />
                    <p className="more-panel-cp">Save more</p>
                    <p className="login-bal-cp">
                      Search,Book Hotels & Apartments across global region
                    </p>
                  </div>

                  <div className="cp-app-regions">
                    <p className="rates-best-regions">
                      Get the best rates from all regions
                    </p>
                    <p className="promo-tariff-cp">
                      Promo discounts on normal room tariff{" "}
                      <a href="offers" target="_blank">
                        Click here
                      </a>{" "}
                      to see more deals
                    </p>
                  </div>
                </div> */}
              </Col>
            ) : null}
            <Col md={7} xs={24} className="cp-rooms-guest">
              <div className="cp-bdr-info">
                <div className="cp-location-date">
                  <p className="cp-best-book-fee-1">Searched Criteria</p>
                  <p>
                    <span>Check In : </span>
                    {moment(hotelDetailsRespObj?.request?.checkInDate).format(
                      "DD MMM, YYYY"
                    )}
                    {/* 25 Aug 2021 */}
                  </p>
                  <p>
                    <span>Check Out : </span>
                    {moment(hotelDetailsRespObj?.request?.checkOutDate).format(
                      "DD MMM, YYYY"
                    )}
                  </p>
                  {/* <p>
                      <span>Nights(S) : </span>
                      {getDifferenceInDays()}
                      {hotelDetailsRespObj?.request?.checkOutDate -
                        hotelDetailsRespObj?.request?.checkInDate}
                    </p> */}
                  {/* <p>
                      <span>Rooms(S) : </span>
                      {hotelDetailsRespObj?.roomCombination?.length}
                    </p> */}
                  <p className="guest-bottom">
                    <span>Guest(s) : </span>
                    {guestCount(hotelDetailsRespObj?.request?.roomGuests)}
                  </p>

                  <div className="cp-other-rooms">
                    <p
                      className="book-other-cp"
                      onClick={() => scrollToRef(myRef2)}
                    >
                      View Rooms
                    </p>
                    <Button
                      className="cp-book-this"
                      onClick={() => scrollToRef(myRef2)}
                    >
                      <span>BOOK NOW</span>
                    </Button>
                  </div>
                </div>
              </div>

              {/* <div className="cp-bdr-info  data-book-with-us">
                <div className="rooms-out-in-cp">
                  <p className="cp-best-book-fee">Why Book with us?</p>
                  <p className="fees-cp">Best Rates Guaranteed</p>
                  <p className="fees-cp">Get the best rates from all regions</p>
                  <p className="fees-cp">No booking fees.</p>
                </div>
              </div> */}
            </Col>
          </Row>
        )
      )}
      <section className="hotel-rooms-list" ref={myRef2}>
        <div className="rooms-wrapper">
          <div>
            <h5 className="rooms-available-sta">Available Rooms</h5>



          </div>

          <div className="rooms-list">
          {roomsDetails && roomsDetails.length > 0 ? 
          <RoomSort 
            openRoomsDetails={openRoomsDetails}
            setRoomsDetailsOpen={setRoomsDetailsOpen}
            fixRoomsDetails={fixRoomsDetails}
            setRoomsDetailsFix={setRoomsDetailsFix}
            roomsDetails={roomsDetails}
            setRoomsDetails={setRoomsDetails}
            handleCheckout={handleCheckout}
            isCheckoutButtonActive={isCheckoutButtonActive}
            handlePriceSortClick={handlePriceSortClick}
            sorting={sorting}
            sortDirection={sortDirection}
            setSorting={setSorting}
            setSortDirection={setSortDirection}
            /> : null }
            {loading && (
              <div className="hotel-details-block">
                <div className="hotel-details-room-card-container">
                  {/* ----Room Skeleton Card---- */}
                  {[...Array(2)].map((_, i) => (
                    <div key={"skeleton" + i} className="room-card">
                      <Row gutter={16}>
                        <Col md={4}>
                          <div className="room-image-skel">
                            <Skeleton.Image />
                          </div>
                        </Col>
                        <Col md={16}>
                          <Skeleton active />
                        </Col>
                        <Col md={4}>
                          <div className="choose-btn-s">
                            <Skeleton paragraph={{ rows: 0 }} />
                            <Skeleton.Button active={true} size={"large"} />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  {/* ----End Of Room Skeleton Card---- */}
                </div>
              </div>
            )}
              
              {fixRoomsDetails.roomList.length > 0 && (
                fixRoomsDetails.roomList.map((roomsArray, index) => (
                  <Card className="room-card-wrapper mb-2" key={index}>
                    {roomsArray.rooms.map((hotelRoom, key) => (
                      <div className="room-card" key={hotelRoom.roomId}>
                        <span className="room-number-span">Room {key + 1}</span>
                        <div className="room-card-wrapper">
                          <div className="hotel-image-box">
                            <div className="hotel-image">
                              {hotelDetailsRespObj?.images ? (
                                hotelDetailsRespObj?.images?.length === 0 ? (
                                  <div className="hotel_image_">
                                    <img src={hotelNoImg} alt="no-photo" />
                                  </div>
                                ) : (
                                  hotelDetailsRespObj?.images && (
                                    <HotelCardImage
                                      key={hotelRoom.roomId + index}
                                      hotelDetailsRespObj={hotelDetailsRespObj}
                                      hotelRoom={hotelRoom}
                                      onHandleModal={onHandleModal}
                                    />
                                  )
                                )
                              ) : (
                                <div className="hotel_image_">
                                  <img src={hotelNoImg} alt="no-photo" />
                                </div>
                              )}
                              {/* {hotelDetailsRespObj?.images?.length === 0 ? (
                                <div className="hotel_image_">
                                  <img src={hotelNoImg} alt="no-photo" />
                                </div>
                              ) : (
                                hotelDetailsRespObj?.images && (
                                  <HotelCardImage
                                    key={hotelRoom.roomsId + index}
                                    hotelDetailsRespObj={hotelDetailsRespObj}
                                    hotelRoom={hotelRoom}
                                    onHandleModal={onHandleModal}
                                  />
                                )
                              )} */}
                            </div>
                          </div>

                          <div className="hotel-info-wrapper">
                            {hotelRoom.ratePlans.map((ratePlan, i) => (
                              <>
                                <div className="hotel-room-details">
                                  <div className="hotel-room-type">
                                    {i === 0 && (
                                      <div className="room-title">
                                        <p className="roomName">
                                          {hotelRoom.roomName}
                                        </p>
                                        {ratePlan.lastCancellationDate ? (
                                          <Tooltip title={cancellationPolicyTable(ratePlan)}>
                                            <p className="roomlastcancel">
                                              Last Cancellation Date:
                                              {compareTodayDate(moment(
                                                ratePlan.lastCancellationDate
                                              ).format("DD/MM/YYYY"), roomsArray.rooms)}
                                            </p>
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                  <div className="amenities-box d-flex">
                                    {ratePlan.inclusions.length > 0
                                      ? ratePlan.inclusions[0]
                                        .split(",")
                                        .map((i, idx) =>
                                          idx < 2 ? (
                                            <>
                                              {idx === 0 ? (
                                                <p className="mb-0 mr-1 incl-name">
                                                  <strong>
                                                    Inclusions :
                                                  </strong>
                                                </p>
                                              ) : null}

                                              <p
                                                className="mb-0 mr-1 incl-name"
                                                key={idx}
                                              >
                                                <i
                                                  className="fa fa-check color-blue"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                                {i}{" "}
                                              </p>
                                            </>
                                          ) : null
                                        )
                                      : null}
                                    {ratePlan.inclusions.length > 0
                                      ? getAllInclusions(ratePlan.inclusions[0])
                                      : null}
                                  </div>

                                  {/* Room Descripation Add by karthik 29-Dec-23 */}
                                  {/* {hotelRoom?.roomDesc && hotelRoom?.roomDesc != '' && (
                                  <div className="d-flex">
                                      <p className="mb-0 mr-1 incl-name">
                                          Room Descripation :
                                      </p>
                                      <p className="mb-0 mr-1 incl-name" key={key} > {" "}{hotelRoom.roomDesc}
                                      </p>
                                  </div>
                                  )} */}
                                  {/* Room Descripation Add by karthik 29-Dec-23 */}

                                  <div className="amenities-box">
                                    <ul>
                                      <li>
                                        <i
                                          className="fa fa-info"
                                          aria-hidden="true"
                                        ></i>
                                        <Tooltip
                                          title={
                                            ratePlan.cancellationPolicy[0]
                                              ?.policies.length > 0
                                              ? ratePlan.cancellationPolicy[0]?.policies.map(
                                                (pol, i) => (
                                                  <p key={pol + i}>
                                                    {" "}
                                                    {ReactHtmlParser(pol)}
                                                  </p>
                                                )
                                              )
                                              : ""
                                          }
                                          key={i}
                                        >
                                          {
                                            ratePlan.cancellationPolicy[0]
                                              ?.policyName
                                          }
                                        </Tooltip>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="select-room-btn">
                                  <p className="hotel-room-price">
                                    {activeCurrency}
                                    <span>
                                      {currencyValue(ratePlan.price.total)}
                                    </span>
                                  </p>
                                  {/* <p className="nightCount">
                                    Price for
                                    <b> 1 night</b>
                                  </p> */}
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="button-wrapper d-flex align-items-end flex-column">
                      <Button
                        // disabled={ratePlan.soldOut === true}
                        onClick={() => navigateToCheckout(roomsArray.rooms)}
                      >
                        Book room
                      </Button>
                    </div>
                  </Card>
                ))
              // ) : (
              //   <p>Rooms are not available</p>
            )}
             <Card className="room-card-wrapper mb-2" key={"openRooms"}>
              {(openRoomsDetails.roomList.length > 0 && (
               
              openRoomsDetails.roomList.map((hotelRoom, index) => (
                <>
                  {openRoomsDetails.roomList.length > 1 ? (
                    <span className="room-number-span">Room {index + 1}</span>
                  ) : (
                    ""
                  )}

                  {/* {roomsArray.map((hotelRoom, key) => ( */}
                    <div className="room-card" key={hotelRoom.roomId }>
                      <div className="room-card-wrapper">
                        <div className="hotel-image-box">
                          <div className="hotel-image">
                            {/* {hotelDetailsRespObj?.images?.length === 0 ? (
                              <div className="hotel_image_">
                                <img src={hotelNoImg} alt="no-photo" />
                              </div>
                            ) : (
                              <HotelCardImage
                                key={hotelRoom.roomsId + key}
                                hotelDetailsRespObj={hotelDetailsRespObj}
                                hotelRoom={hotelRoom}
                                onHandleModal={onHandleModal}
                              />
                            )} */}
                            {hotelDetailsRespObj?.images ? (
                              hotelDetailsRespObj?.images?.length === 0 ? (
                                <div className="hotel_image_">
                                  <img src={hotelNoImg} alt="no-photo" />
                                </div>
                              ) : (
                                hotelDetailsRespObj?.images && (
                                  <HotelCardImage
                                    key={hotelRoom.roomId +"img"}
                                    hotelDetailsRespObj={hotelDetailsRespObj}
                                    hotelRoom={hotelRoom}
                                    onHandleModal={onHandleModal}
                                  />
                                )
                              )
                            ) : (
                              <div className="hotel_image_">
                                <img src={hotelNoImg} alt="no-photo" />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="hotel-info-wrapper">
                          {hotelRoom.ratePlans.map((ratePlan, i) => (
                            <>
                              <div className="hotel-room-details">
                                <div className="hotel-room-type">
                                  {i === 0 && (
                                    <div className="room-title">
                                      <p className="roomName">
                                        {hotelRoom.roomName}
                                      </p>
                                      {ratePlan.lastCancellationDate ? (
                                        <p className="roomlastcancel">
                                          Last Cancellation Date:
                                          {compareTodayDate(moment(
                                                ratePlan.lastCancellationDate
                                              ).format("DD/MM/YYYY"))}
                                        </p>
                                      ) : null}
                                    </div>
                                  )}
                                </div>
                                <div className="amenities-box">
                                  <ul>
                                    <li>
                                      <i
                                        className="fa fa-check"
                                        aria-hidden="true"
                                      ></i>
                                      {
                                        ratePlan.cancellationPolicy[0]
                                          ?.policyName
                                      }
                                    </li>
                                  </ul>
                                </div>

                                {ratePlan.cancellationPolicy[0]?.policies
                                  .length > 0 ? (
                                  <>
                                    {ratePlan.cancellationPolicy[0]?.policies.map(
                                      (pol, i) =>
                                        pol != "" ? (
                                          <div className="description-modal">
                                            <p key={pol + i} className="mb-0">
                                              {" "}
                                              {ReactHtmlParser(pol)}
                                            </p>
                                          </div>
                                        ) : (
                                          ""
                                        )
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}

                                <div className="amenities-box d-flex">
                                  {hotelRoom.roomName.split(",").map((i, idx) =>
                                    idx > 0 ? (
                                      <>
                                        {idx === 1 ? (
                                          <p className="mb-0 mr-1">
                                            <strong>Inclusions :</strong>
                                          </p>
                                        ) : null}

                                        <p className="mb-0 mr-1">
                                          <i
                                            className="fa fa-check color-blue"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          {i}{" "}
                                        </p>
                                      </>
                                    ) : (
                                      ""
                                    )
                                  )}
                                </div>
                              </div>

                              <div className="select-room-btn">
                                <p className="hotel-room-price">
                                  {activeCurrency}
                                  <span>
                                    {currencyValue(ratePlan.price.total)}
                                  </span>
                                </p>
                                {/* <p className="nightCount">
                                  Price for
                                  <b> 1 night</b>
                                </p> */}
                                <Button
                                  className={`select-button ${
                                    selectedRooms[index]?.selectedRoomIndx ===
                                    hotelRoom.roomId ? "active"
                                      : ""
                                    }`}
                                  onClick={() =>
                                    handleSelectedRooms(hotelRoom,index, hotelRoom.roomId)
                                  }
                                >
                                  {selectedRooms[index]?.selectedRoomIndx ===
                                   hotelRoom.roomId
                                    ? "Selected"
                                    : "Select Room"}
                                </Button>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  {/* ))} */}
                 
                  </>
              
              ))
            ) )}
        
              </Card>
            {/* {
              openRoomsDetails.roomList.length <= 0 && fixRoomsDetails.roomList.length <= 0 && (
                <p>Rooms are not available</p>
              )
            } */}
          </div>
        </div>
      </section>

      <section className="hotel-description" ref={myRef3}>
        <div className="hotel-description-wrapper">
          {hotelDetailsRespObj?.description && hotelDetailsRespObj?.description != '' && (
          <div className="description-block">
            <div className="description-title">About hotel</div>
            {loading ? (
              <div className="description-content">
                {" "}
                <Skeleton active />
              </div>
            ) : (
             <div className="description-content">
				{parsedHtml}
			</div>
            )}
          </div>
          )} 

          <div className="description-block facilities-list">
            <div className="description-title">Hotel facilities</div>
            <div className="description-content miscell-data">
              <div className="facilities-block">
                <p className="rooms-available-sta">Miscellaneous</p>
                {loading ? (
                  <Skeleton active />
                ) : (
                  <ul>
                    <Row>
                      {hotelDetailsRespObj?.rooms?.length > 0 ? (
                        hotelDetailsRespObj?.rooms[0]?.ratePlans[0]?.amenities
                          ?.length > 0 ? (
                          hotelDetailsRespObj?.rooms[0]?.ratePlans[0]?.amenities.map(
                            (amenitie, index) =>
                              index < moreFacilities ? (
                                <Col
                                  key={"facili" + index}
                                  md={8}
                                  sm={8}
                                  xs={12}
                                >
                                  <li>
                                    <i className="fa fa-check color-blue"></i>{" "}
                                    {amenitie}
                                  </li>{" "}
                                </Col>
                              ) : index === moreFacilities ? (
                                <Col
                                  key={"facili" + index}
                                  md={8}
                                  sm={8}
                                  xs={12}
                                >
                                  <li
                                    className="showMore-btn"
                                    onClick={showMore}
                                  >
                                    Show More
                                  </li>
                                </Col>
                              ) : null
                          )
                        ) : (
                          <p>No data available</p>
                        )
                      ) : null}
                      {/* {hotelDetailsRespObj?.rooms?.length > 0 ? (
                        hotelDetailsRespObj.hotelFacility.map(
                          (facility, index) => (
                            <Col key={"facili" + index} md={8} sm={8} xs={12}>
                              <li>
                                <i className="fa fa-check color-blue"></i>{" "}
                                {facility}
                              </li>{" "}
                            </Col>
                          )
                        )
                      ) : (
                        <p>No data available</p>
                      )} */}
                    </Row>
                  </ul>
                )}
              </div>
            </div>
          </div>

          <div className="description-block facilities-list" ref={myRef4}>
            <div className="description-title">Check-in Instructions</div>
            <div className="description-content miscell-data">
              <div className="facilities-block">
                <Row>
                  <Col md={24} sm={24} xs={24}>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Extra-person
                      charges may apply and vary depending on property policy.
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i>{" "}
                      Government-issued photo identification and a credit card,
                      debit card, or cash deposit may be required at check-in
                      for incidental charges.
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Special
                      requests are subject to availability upon check-in and may
                      incur additional charges; special requests cannot be
                      guaranteed .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> The primary
                      guest must be at least 18 years of age to check into this
                      hotel(s) .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> In some
                      countries including India, as per Government regulations,
                      it is mandatory for all guests above 18 years of age to
                      carry a valid photo identity card & address proof at the
                      time of check-in. In case, check-in is denied by the hotel
                      due to lack of required documents, you cannot claim for
                      the refund & the booking will be considered as NO SHOW.
                      Please check with the hotel(s) directly .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Unless
                      mentioned, the tariff does not include charges for
                      optional room services (such as telephone calls, room
                      service, mini bar, snacks, laundry extra bed etc.). In
                      case, such additional charges are levied by the hotel(s),
                      we shall not be held responsible for it .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Extra bed can
                      be accommodated with a folding cot or a mattress, subject
                      to room size & availability .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> The hotel(s)
                      reserves the right to decline accommodation to
                      localities/same city residents.ShopYourTrip.com will not
                      be responsible for any check-in declined by the hotel(s)
                      or any refunds due to the above-mentioned reason .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i>{" "}
                      ShopYourTrip.com will not be responsible for any service
                      issues at the hotel(s) .
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="description-block facilities-list" ref={myRef5}>
            <div className="description-title">Special Instructions</div>
            <div className="description-content miscell-data">
              <div className="facilities-block">
                <Row>
                  <Col md={24} sm={24} xs={24}>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Early check
                      -in/ Late checkout (Subject to availability, Amount
                      varies) to be Charges by the Property at time of Service.
                      .
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> To make
                      arrangements for check-in please contact the property at
                      least 24 hours before arrival using the information on the
                      booking confirmation.
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Guests must
                      contact the property in advance for check-in instructions.
                      Front desk staff will greet guests on arrival.
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="description-block facilities-list">
            <div className="description-title">Disclaimer notification</div>
            <div className="description-content miscell-data">
              <div className="facilities-block">
                <Row>
                  <Col md={24} sm={24} xs={24}>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> Amenities are
                      subject to availability and may be chargeable as per the
                      hotel policy.
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> We attempts to
                      ensure that the information on this page is complete and
                      accurate; however this information along with its links
                      may contain typographical errors, and other errors or
                      inaccuracies. We assume no responsibility for such errors
                      or omissions, and reserve the right to correct any errors,
                      inaccuracies or omissions.
                    </p>
                    <p className="font-weight-normal">
                      <i className="fa fa-check color-blue"></i> All information
                      provided on this page is meant to serve as a general
                      information source only and does not constitute
                      professional advice. This page may not cover all
                      information available on a particular issue. Before
                      relying on this page, we urge you to independently
                      validate or obtain professional advice relevant to your
                      particular circumstances .
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // defaultProps.mapVisible */}
      {defaultProps.mapVisible ? (
        <section className="locationWrapper">
          <h3 className="rooms-available-sta">Location</h3>
          {loading ? (
            <Skeleton active paragraph={{ rows: 0 }} />
          ) : (
            <p className="loc">
              <EnvironmentOutlined /> {defaultProps.center.address}
            </p>
          )}

          <div className="mapWrapper">
            <GoogleMapReact
              bootstrapURLKeys={{
                // key: "AIzaSyApWaGzClO2aNWPzZuKY2ztugrFFEfeEJg",
                key: "AIzaSyCWMz7PQCXuxL28R5tUY7nzjHY2L1NtgUc",
                // key: "AIzaSyD1eL7vm99WsciYyWg0xOhH18gh5pMXSxw",
                // key: "AIzaSyDrB7HCnuC-lseyy_5feUufvBzYi4wZ2PI",
                // key: "AIzaSyAIEHlHNcVPdcAFiTUs5JhtRRe-z7iQ5QI",
                language: "en",
                region: "in",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              distanceToMouse={() => {}}
            >
              <Marker
                lat={defaultProps.center.lat}
                lng={defaultProps.center.lng}
              />
            </GoogleMapReact>
          </div>
        </section>
      ) : null}
      <section className="hotel-details-footer e-hide">
        <div className="details-footer-wrapper">
          <div>
            <p>Ready for booking ?</p>
          </div>
          <div>
            <ReactBootstrap.Button>Show rooms</ReactBootstrap.Button>
          </div>
        </div>
        <div className="details-footer-second">
          <div className="second-footer-wrapper">
            <div>
              <p>Looking for something else in Hyderabad ?</p>
            </div>
            <div>
              <ReactBootstrap.Button>See similar hotels</ReactBootstrap.Button>
            </div>
            <div>
              <ReactBootstrap.Button>
                Return to search results
              </ReactBootstrap.Button>
            </div>
          </div>
        </div>
      </section>

      {/* Hotel Facilities Modal */}
      <div className="facilities-modal">
        <ReactBootstrap.Modal
          show={isRoomModal}
          onHide={(e) => setIsRoomModal(false)}
          size="lg"
          className="room-modal"
        >
          <ReactBootstrap.Modal.Header closeButton>
            <ReactBootstrap.Modal.Title>
              <div className="custom-modal-header">
                <div className="header-img">
                  <img
                    src={require("../../../assets/images/hotels/no_photo.png")}
                    alt="blank"
                  />
                </div>
                <div className="header-text">
                  <h4>
                    {/* {roomObjInModal.roomName} -{" "}
                    {roomObjInModal.ratePlans
                      ? roomObjInModal.ratePlans[0].refundable === true
                        ? "Refundable"
                        : "Non-Refundable"
                      : null} */}
                  </h4>
                  <p>
                    <i className="fa fa-wifi" aria-hidden="true"></i>
                    {/* {roomObjInModal.roomDesc} */}
                  </p>
                </div>
              </div>
            </ReactBootstrap.Modal.Title>
          </ReactBootstrap.Modal.Header>
          <ReactBootstrap.Modal.Body scrollable="true">
            <div className="custom-details-modal-body">
              <div>
                <p>Room facilities</p>
                <ul>
                  {/* {roomObjInModal.ratePlans ? (
                    roomObjInModal.ratePlans[0].inclusions.length > 0 ? (
                      roomObjInModal.ratePlans[0].inclusions.map(
                        (facility, index) => <li key={index}>{facility}</li>
                      )
                    ) : (
                      <p>No Facilities data available</p>
                    )
                  ) : null} */}
                </ul>
              </div>
              <div className="room-cancellation-policy">
                {/* {roomObjInModal.ratePlans ? (
                  roomObjInModal.ratePlans[0].cancellationPolicy.policies
                    .length > 0 &&
                  roomObjInModal.ratePlans[0].cancellationPolicy.policies[0] !==
                    "" ? (
                    <div>
                      <p>Cancellation Policy</p>
                      <p>
                        {roomObjInModal.ratePlans[0].cancellationPolicy.policies.map(
                          (canPolicy) => canPolicy
                        )}
                      </p>
                    </div>
                  ) : null
                ) : null} */}
              </div>
            </div>
          </ReactBootstrap.Modal.Body>
        </ReactBootstrap.Modal>
      </div>

      {/* Room images modal */}
      <div className="images-carousel-modal">
        <ReactBootstrap.Modal
          className="hotel-images-modal"
          show={isShowModal}
          onHide={(e) => setIsShowModal(false)}
          size="lg"
        >
          <ReactBootstrap.Modal.Header closeButton className="modal-wrapper">
            <ReactBootstrap.Modal.Title>
              <p>{roomImagesModal.roomName}</p>
            </ReactBootstrap.Modal.Title>
          </ReactBootstrap.Modal.Header>
          <ReactBootstrap.Modal.Body>
            <ImagesLightbox hotelImages={roomImagesModal.images} />
          </ReactBootstrap.Modal.Body>
        </ReactBootstrap.Modal>
      </div>
    </div>
  );
};

export default HotelDet;
