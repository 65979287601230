import React from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import SeoMetaData from "../../common/Seo/SeoMetaData";
const Careers = () => {
  return (
    <React.Fragment>
      <SeoMetaData path={window.location.pathname} />
      <CmsPages cmsId={8} pageTitle="Careers" />
    </React.Fragment>
  );
};

export default Careers;
