import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Layout, Row } from "antd";
import "../Busticketsuccess/Busticketsuccess.scss";

const { Content } = Layout;

const ActivitiesTicketSuccess = ({ activitiesTicketDetails }) => {
  return (
    <Content className="admin-container cms-pages-width retrieve-linebar">
      <Card className="retrieve-booking busmtb">
        <Row gutter={[16, 16]}>
          <Col lg={12} md={16} sm={16} xs={24}>
            <h4 className="ticket-lti-bus">Activities ltinerary</h4>
            {/* sub--row--start */}

            {activitiesTicketDetails.message === "FAILED" && <p>Booking is on hold please don't try to book the ticket as our backend team is working on this ticket, please allow us 30 minutes to confirm the same, if you don't receive any confirmation within 30 minutes please call us on this number  <table style={{'color':'red'}}>
                  <tr>
                    <td> 011 4951 13 13</td>
                    <td> ( 10 am - 7:00 pm ) </td>
                  </tr>
                  <tr>
                    <td> + 91 9999442804</td>
                    <td>  ( 07:00 pm - 12:00 am )  </td>
                  </tr>
                  <tr>
                    <td> +91 916686 883 7 </td>
                    <td>( 07:00 pm - 12:00 am )  </td>
                  </tr>
                  <tr>
                    <td> +91 9958690805</td>
                    <td>  ( 07:00 pm - 12:00 am )  </td>
                  </tr>
                  </table></p>
            }
            {activitiesTicketDetails?.TourDetails.map((tour) => (
              <div className="fic-tickets-data-bus">
                <h6 className="round-bus-tic">{tour.tourName}</h6>
                <div className="bd-bus-info">
                  <Row gutter={[8, 8]}>
                    <Col md={5} sm={10} xs={24}>
                      <img className="img-fluid" src={tour.tourImage} />
                    </Col>
                    <Col md={19} sm={14} xs={24}>
                      <h6>{tour.optionName}</h6>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: tour.tourShortDescription,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </div>

                <Row gutter={[8, 8]}>
                  {/* <Col md={12} sm={12} xs={24}>
                    <p className="pnr text-left">
                      Provider Ref No: {tour.ProviderRefNo}
                    </p>
                  </Col> */}

                  <Col md={12} sm={12} xs={24}>
                    <p className="pnr text-left">
                      Transfer Name: {tour.transferName}
                    </p>
                  </Col>
                </Row>
              </div>
            ))}
          </Col>

          <Col lg={12} md={8} sm={8} xs={24} className="flight-email-dd-bus">
            {activitiesTicketDetails?.TourDetails.length > 0 ? (
              <>
                <div className="paymet-booking-tic">
                  <h4>
                    {activitiesTicketDetails?.TourDetails?.[0].bookingStatus === 3
                      ? "Activities Ticket Booking Confirmed Successful"
                      : "Activities Ticket Booking Payment Successful"}
                  </h4>
                  <p>
                    A confirmation email has been sent your provided email
                    address
                  </p>
                  <p>
                    Ref-No :{" "}
                    {activitiesTicketDetails?.TourDetails?.[0].referenceNumber}
                  </p>
                  <p>
                    Status : <span className="succes-pay-value">Success</span>
                  </p>

                  <p>
                  <Button className="tic-retrive-book ml-0" htmlType="submit">
                    <Link
                      to={`activities/ticket?ref=${activitiesTicketDetails?.TourDetails?.[0].referenceNumber}`}
                    >
                      Retrieve Booking
                    </Link>
                  </Button>
                  </p>
                </div>
                {/** no need to retrive button ticket will show upload or sent on mail */}
                {/* <Row>
                  <Col lg={12} md={18} xs={24}>
                    <Button className="tic-retrive-book" htmlType="submit">
                      <Link
                        to={`activities/ticket?ref=${activitiesTicketDetails?.TourDetails?.[0].referenceNumber}`}
                      >
                        Retrieve Booking
                      </Link>
                    </Button>
                  </Col>
                </Row> */}
              </>
            ) : <Col lg={24} md={24} sm={24} xs={24}>
            <p className="mb-0">
              <strong>
              Message :  Booking is on hold please don't try to book the ticket as our backend team is working on this ticket, please allow us 30 minutes to confirm the same, if you don't receive any confirmation within 30 minutes please call us on this number
                <table style={{'color':'red'}}>
                  <tr>
                    <td> 011 4951 13 13</td>
                    <td> ( 10 am - 7:00 pm ) </td>
                  </tr>
                  <tr>
                    <td> + 91 9999442804</td>
                    <td>  ( 07:00 pm - 12:00 am )  </td>
                  </tr>
                  <tr>
                    <td> +91 916686 883 7 </td>
                    <td>( 07:00 pm - 12:00 am )  </td>
                  </tr>
                  <tr>
                    <td> +91 9958690805</td>
                    <td>  ( 07:00 pm - 12:00 am )  </td>
                  </tr>
                  </table>
                  Email us at - activities@shopyourtrip.com
                {/* {flightTic
                  ketsDetails.otherMessage} */}
                </strong>
            </p>
          
          </Col>}
          </Col>
          {/* <Col lg={8} md={12} sm={18} xs={24}>
            <Button className="tic-retrive-book ml-0" htmlType="submit">
              <Link
                to={`activities/ticket?ref=${activitiesTicketDetails?.TourDetails?.[0].referenceNumber}`}
              >
                Retrieve Booking
              </Link>
            </Button>
          </Col> */}
        </Row>
        {/* <Row>
          <Col lg={8} md={12} sm={18} xs={24}>
            <Button className="tic-retrive-book ml-0" htmlType="submit">
              <Link
                to={`activities/ticket?ref=${activitiesTicketDetails?.TourDetails?.[0].referenceNumber}`}
              >
                Retrieve Booking
              </Link>
            </Button>
          </Col>
        </Row> */}
      </Card>
    </Content>
  );
};

export default ActivitiesTicketSuccess;
