import { Card, Button, Col, Form, Input, message, Row, Select } from "antd";
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import CountryList from "../../common/CountryList";
import APIClient from "../../helpers/ApiClient";

import { AppConstants } from "../../helpers/constants";
import { useHistory } from "react-router-dom";
import SeoMetaData from "../../common/Seo/SeoMetaData";

const Forex = () => {
  const { Option } = Select;
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [currency, setCurrency] = useState(false);
  const [form] = Form.useForm();
  const validateMessages = {
    required: "",
  };
  const history = useHistory();
  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  const submitForm = (value) => {
    let req = {
      ServiceType: 10,
      CarType: 0,
      CarCapacity: 0,
      Occasion: 0,
      BusType: 0,
      VisitPurpose: value.VisitPurpose,
      BookingCurrencyFor: value.BookingCurrencyFor,
      Name: value.Name,
      Email: value.Email,
      ISDCode: value.ISDCode,
      ContactNumber: value.ContactNumber,
      AlternateNumber: value.AlternateNumber ? value.AlternateNumber : "",
      TripType: "",
      SourceCity: "",
      DestinationCity: "",
      PreferredClass: "",
      DepartureDate: "",
      DepartureTime: "",
      PickUpLocation: value.PickUpLocation,
      DropLocation: "",
      EndDate: "",
      EndTime: "",
      ReturnDate: "",
      ReturnTime: "",
      OnwardFlightNumber: "",
      OnwardFilghtName: "",
      ReturnFlightNumber: "",
      ReturnFilghtName: "",
      NumberOfPassengers: 0,
      Remarks: value.Remarks,
      CreatedBy: 0,
      ModifiedBy: 0,
      NumberOfBuses: 0,
      Currency: value.Currency,
      Quantity: value.Quantity,
      Amount: 0,
    };

    APIClient.post("admin/offlineEnquiry", req)
      .then((response) => {
        if (response.status == 200) {
          history.push("/Thankyou");
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAllCurrency();
  }, []);

  const getAllCurrency = () => {
    APIClient.get("currency/getallcurrency")
      .then((response) => {
        if (response.status == 200) {
          setCurrency(response.data);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="contact_wrapper from-contact-24">
      <SeoMetaData path={window.location.pathname} />
      <Row justify="center">
        <Col lg={14} md={20} xs={24}>
          <div className="inner_wrapper">
            <div className="contact_header">
              <h2>Forex</h2>
            </div>
            <Form
              layout="vertical"
              name="ContactUsForm"
              form={form}
              initialValues={{ ISDCode: "+91" }}
              validateMessages={validateMessages}
              onFinish={submitForm}
            >
              <Card bordered={false}>
                <Row gutter={16}>
                  <Col md={8} sm={8} xs={24}>
                    <Form.Item
                      label="Name "
                      name="Name"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input placeholder="Enter Name" />
                    </Form.Item>
                  </Col>

                  <Col md={8} sm={8} xs={24}>
                    <Form.Item
                      label="Contact Number"
                      name="ContactNumber"
                      className="phno"
                      rules={[
                        {
                          required: true,
                          message: "Phone Number  Required",
                        },
                        {
                          minLength: 10,
                          maxLength: 10,
                          pattern: "^[0-9]{10}$",
                          message: "Must be 10 digits",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={
                          <Form.Item
                            style={{ width: "35%" }}
                            name="ISDCode"
                            className="phno"
                            rules={[
                              {
                                required: true,
                                message: "Phone Number Code Required",
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              showSearch
                              // placeholder="Select Country"
                              style={{ width: "80px" }}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {CountryList.map((item) => (
                                <Option
                                  key={item.dial_code}
                                  value={item.dial_code}
                                >
                                  {item.dial_code}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        }
                        className="inputbg"
                        placeholder="Enter Mobile No."
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label="Email"
                      name="Email"
                      rules={[
                        { required: true, message: "Required" },
                        {
                          type: "email",
                          message: "Email is not a valid email",
                        },
                      ]}
                    >
                      <Input type="email" placeholder="Enter Email" />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label="Alternate  Contact Number"
                      name="AlternateNumber"
                      rules={[
                        {
                          minLength: 10,
                          maxLength: 10,
                          pattern: "^[0-9]{10}$",
                          message: "Must be 10 digits",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Enter Number"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <div className="card-gap">
                <Card bordered={false}>
                  <Row gutter={16}>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Location"
                        name="PickUpLocation"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input placeholder="Location" />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Currency"
                        name="Currency"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Select showSearch placeholder="Select Currency">
                          {currency.length
                            ? currency.map((item, i) => (
                                <Option
                                  key={i}
                                  value={`${item.Code}`}
                                >
                                  {item.Code}
                                </Option>
                              ))
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Quantity"
                        name="Quantity"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input placeholder="Enter Quantity" />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Purpose Of Visit"
                        name="VisitPurpose"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Select placeholder="Select Purpose Of Visit">
                          <Option value={1}>Personal Visit</Option>
                          <Option value={2}>Overseas Education</Option>
                          <Option value={3}>Business Travel</Option>
                          <Option value={4}>Employment</Option>
                          <Option value={5}>Medical Visit</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Booking Currency For"
                        name="BookingCurrencyFor"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Select placeholder="Select Booking Currency For">
                          <Option value={1}>My Self</Option>
                          <Option value={2}>Spouse</Option>
                          <Option value={3}>Son</Option>
                          <Option value={4}>Daughter</Option>
                          <Option value={5}>Parents</Option>
                          <Option value={6}>Brother</Option>
                          <Option value={7}>Sister</Option>
                          <Option value={8}>Grand Daughter</Option>
                          <Option value={9}>Grand Son</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label="Amount"
                      name="Amount"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <InputNumber
                        placeholder="Amount"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col> */}
                  </Row>

                  <Row justify="center" className="my-3">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      onChange={handleCaptcha}
                    />
                  </Row>

                  <Form.Item className="center">
                    <Button
                      disabled={buttonDisabled}
                      type="primary"
                      onClick={() => form.submit()}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Card>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Forex;
