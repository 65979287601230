import React from "react";
import { Table } from "react-bootstrap";

const PassengerDetails = (props) => {
  return (
    <div className="passenger-details-container">
      <div className="passenger-details-card">
        {props.passengersInfo.length > 0 ? (
          <div>
            {props.passengersInfo.map((paxInRoomObj, roomIndex) => (
              <div>
                <h5>Room {roomIndex + 1}</h5>
                <div className="passenger-info">
                  <Table responsive>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Name</th>
                    <th>Type</th>
                    {paxInRoomObj.paxInfoList.some((paxObj) => paxObj.pan) && <th>Pan Number</th>}
                  </tr>
                </thead>
                <tbody>
                  {paxInRoomObj.paxInfoList.map((paxObj, index) => (
                    <tr key={index}>
                      <td>{paxObj.title} </td>
                        <td>
                          {paxObj.firstName} {paxObj.lastName}
                        </td>
                        <td>
                        {paxObj.guestType === "Adult" ? "Adult" : "Child"}
                        </td>
                        {/* <td>{paxObj.pan } </td> */}
                        {paxObj.pan && <td>{paxObj.pan}</td>}
                      </tr>
                  ))}
                </tbody>
              </Table>
                </div>
              </div>
            ))}
            </div>

        ) : (
          <p>No Passengers Info available</p>
        )}
      </div>
    </div>
  );
};
export default PassengerDetails;
