import {
  getConvFee,
  getPromoDiscount,
} from "../../../common/AllTickets/promoConvhelper";

export function getAllActivitiesPrice(
  activitiesCheckOutData,
  ConvFee,
  promoData,
  redeemAmount,
  transactionFee
) {
  let totalAmount = 0;
  let grandTotal = 0;
  let convAmount = 0;
  let discount = 0;
  let markup = 0;
  let agentMarkup = 0;
  let commision = 0;
  let redeemTotal = 0;
  let tranxFree =0;


  totalAmount = activitiesCheckOutData?.reduce(
    (acc, cur) => acc + Number(cur.serviceTotal),
    0
  );
  markup = activitiesCheckOutData?.reduce(
    (acc, cur) => acc + Number(cur.markup),
    0
  );

  agentMarkup = activitiesCheckOutData?.reduce(
    (acc, cur) => acc + Number(cur.agentMarkup),
    0
  );

  commision = activitiesCheckOutData?.reduce(
    (acc, cur) => acc + Number(cur.commission),
    0
  );
  let totalPax = 0;
  const TourInfo = {adultPrice: 0,childPrice: 0}
  activitiesCheckOutData?.forEach(element => {
    totalPax =  element.child + element.adult + element.infant;  
    // get total adult price and child price by karthik
    TourInfo.adultPrice += parseInt(element.adultRate) * element.adult;
    TourInfo.childPrice += parseInt(element.childRate) * element.child;
  });  

  convAmount = Number(getConvFee(ConvFee, totalAmount, totalPax));
  totalAmount += convAmount;
  discount = Number(getPromoDiscount(promoData, totalAmount));

  grandTotal = totalAmount - discount;
  if (redeemAmount?.CouponAmt) {
    redeemTotal = Number(redeemAmount?.CouponAmt);
  }
  grandTotal -= redeemTotal;

  if (commision) grandTotal -= commision;

  
  if(transactionFee?.type=="P" || transactionFee?.type=="p")
  {
    tranxFree =(grandTotal /100) * parseFloat(transactionFee.amount)
    
  }
  else if(transactionFee?.type=="f" || transactionFee?.type=="F") {
    tranxFree = parseFloat(transactionFee.amount)
  
  }grandTotal += tranxFree;



  return {
    totalAmount: Number(totalAmount).toFixed(2),
    grandTotal: Number(grandTotal).toFixed(2),
    convAmount,
    discount,
    markup,
    agentMarkup,
    commision,
    redeemTotal,
    tranxFree,
    TourInfo
  };
}

export function getActivitiesPartPaymentDetails(grandTotal, partPayment) {
  let partPaymentAmount = 0;
  let dueAmount = 0;
  if (partPayment.isPartPayment) {
    let copyTotal = grandTotal;

    partPaymentAmount = Number(
      (parseFloat(copyTotal) * partPayment?.PartPaymentPercentage) / 100
    ).toFixed(2);

    dueAmount = Number(copyTotal - partPaymentAmount).toFixed(2);
  } else {
    partPaymentAmount = grandTotal;
  }

  return {
    partPaymentAmount,
    dueAmount,
  };
}
