import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row } from "antd";
import moment from "moment";
import "./hotelticketsuccess.scss";

let dateFormat = "MMM, DD-YYYY";

const HotelTicketSuccess = ({ hotelTicketsDetails }) => {
  return (
    <div className="admin-container cms-pages-width retrieve-linebar">
      <Card className="retrieve-booking busmtb">
        <h4 className="ticket-lti">Hotel ltinerary</h4>
        <Row gutter={[16, 16]}>
          {Object.keys(hotelTicketsDetails.ticketAllData).length > 0 ? (
            hotelTicketsDetails.ticketAllData.BookingStatus === 3  || hotelTicketsDetails.ticketAllData.BookingStatus==11 ? (
              <>
                <Col lg={12} md={16} sm={16} xs={24}>
                  <div className="hotel-tickets-data">
                    <div className="hotel-info ">
                      <p className="name">
                        {hotelTicketsDetails.ticketAllData.HotelName}
                      </p>
                      <p className="subtitle">
                        {
                          hotelTicketsDetails.ticketAllData?.HotelAddress
                            ?.address
                        }
                      </p>
                      <p className="title">
                        Check-In :{" "}
                        {moment(
                          hotelTicketsDetails.ticketAllData.CheckInDate
                        ).format(dateFormat)}
                      </p>

                      <p className="title">
                        Check-Out :{" "}
                        {moment(
                          hotelTicketsDetails.ticketAllData.CheckOutDate
                        ).format(dateFormat)}
                      </p>

                      {/* <p className="title">
                        PNR :{" "}
                        {hotelTicketsDetails.ticketAllData.ConfirmationNumber}
                      </p>

                      <p className="title">
                        Star Rating :{" "}
                        {hotelTicketsDetails.ticketAllData.StarRating}
                      </p> */}
                    </div>
                  </div>
                </Col>
                <Col lg={12} md={8} sm={8} xs={24}>
                  <div className="paymet-booking-tic">
                    <h4>Hotel Ticket Booking Payment Successful</h4>
                    <p>
                      A confirmation email has been sent your provided email
                      address
                    </p>
                    <p>
                      Ref-No : {hotelTicketsDetails.ticketAllData.RefNumber}
                    </p>
                    <p>
                      Status : <span className="succes-pay-value">Success</span>
                    </p>
                  </div>

                  <Row>
                    <Col lg={12} md={18} xs={24}>
                      <Button className="tic-retrive-book" htmlType="submit">
                        <Link
                          to={`hotel/ticket?ref=${hotelTicketsDetails.ticketAllData.RefNumber}`}
                        >
                          Retrieve Booking
                        </Link>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </>
            ) : null
          ) : (
            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="hotel-tickets-data">
                <div className="hotel-info ">
                  <p className="name">{hotelTicketsDetails.otherMessage}</p>
                  {hotelTicketsDetails.errorMessage && (
                    <p className="name">
                      <strong>
                      Message :  Booking is on hold please don't try to book the ticket as our backend team is working on this ticket, please allow us 30 minutes to confirm the same, if you don't receive any confirmation within 30 minutes please call us on this number
                      {/* {hotelTicketsDetails.errorMessage} */}
                      <table style={{'color':'red'}}>
                        <tr>
                          <td> 011 4951 13 13</td>
                          <td> ( 10 am - 7:00 pm ) </td>
                        </tr>
                        <tr>
                          <td> + 91 9999442804</td>
                          <td>  ( 07:00 pm - 12:00 am )  </td>
                        </tr>
                        <tr>
                          <td> +91 916686 883 7 </td>
                          <td>( 07:00 pm - 12:00 am )  </td>
                        </tr>
                        <tr>
                          <td> +91 9958690805</td>
                          <td>  ( 07:00 pm - 12:00 am )  </td>
                        </tr>
                        </table>
                        Email us at - hotels@shopyourtrip.com
                      {/* {flightTic
                        ketsDetails.otherMessage} */}
                      </strong>
                    </p>
                  )}
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Card>
    </div>
  );
};

export default HotelTicketSuccess;
