import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Select,
  DatePicker,
  message,
  Modal,
  Checkbox,
} from "antd";

import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import APIClient from "../../helpers/ApiClient";
import CountryList from "../../common/CountryList";
// import Thankyou from "../Thankyou/Thankyou";
// import {
//   CheckCircleOutlined,
//   FacebookOutlined,
//   TwitterOutlined,
//   LinkedinOutlined,
//   MailOutlined,
// } from "@ant-design/icons";
import "./ContactUsFormStyle.scss";
import { AppConstants } from "../../helpers/constants";
import { useHistory } from "react-router-dom";
import SeoMetaData from "../../common/Seo/SeoMetaData";

const ContactUsForm = () => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [activeCategory, setActiveCategory] = useState([]);
  const [visible, setVisible] = useState(false);
  const categoryChange = (key) => {
    setActiveCategory(categories[key].options);
  };
  const [form] = Form.useForm();
  const validateMessages = {
    required: "",
  };
  const history = useHistory();
  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }
  const categories = {
    MyBooking: {
      title: "My Bookings",
      options: [
        "Adding Luggage Priority Boarding",
        " Seat Selection ON the plane ",
        "Flight date change Correcting",
        "changing passenger data",
        "Checkin and Boarding Passes",
        " Help Needed",
      ],
    },
    PaymentsAndInvoice: {
      title: "Payments & Invoice",
      options: ["Changing Buyers Details", " Items on Invoice"],
    },
    Covid: {
      title: "Covid 19",
      options: ["Ticket Refund", "Booking Change"],
    },
    Complaints: {
      title: "Complaints",
      options: ["Flight", "Hotel", "Other Serives", "Customer Service"],
    },
    RateUs: {
      title: "Rate Us",
      options: [
        "General Impression of website",
        "Searching for offers",
        " Making a Booking",
        "Customer Service",
      ],
    },
    Other: {
      title: "Other",
      options: ["Other"],
    },
  };

  const submitForm = (value) => {
    submitContactUsDetails(value);
  };

  const submitContactUsDetails = (formMapData) => {
    APIClient.post("admin/contacts", formMapData)
      .then((response) => {
        if (response.status == 200) {
          // setVisible(true);
          // form.resetFields();
          history.push("/Thankyou");
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const handleOk = () => {
  //   setVisible(false);
  // };

  // const handleCancel = () => {
  //   setVisible(false);
  // };

  return (
    <div className="contact_wrapper from-contact-24">
      <SeoMetaData path={window.location.pathname} />
      {/* {visible ? (
        <Thankyou />
      ) : ( */}
      <Row justify="center">
        <Col lg={14} md={20} xs={24}>
          <div className="inner_wrapper">
            <div className="contact_header">
              <h2>Contact Form</h2>
            </div>
            <Form
              layout="vertical"
              name="ContactUsForm"
              form={form}
              validateMessages={validateMessages}
              style={{ background: "white", padding: "16px" }}
              onFinish={submitForm}
            >
              <Divider orientation="left" className="d-table">
                How Can we Help You?
              </Divider>
              <Row gutter={16}>
                <Col md={12} sm={12} xs={24}>
                  <Form.Item
                    label="Category "
                    name="CategoryName"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select onChange={categoryChange}>
                      {Object.keys(categories).map((key) => {
                        return (
                          <Option value={key}>{categories[key].title}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={12} sm={12} xs={24}>
                  <Form.Item
                    label="Message Topic "
                    name="CategoryMessage"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select>
                      {activeCategory.map((key) => {
                        return <Option value={key}>{key}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Divider orientation="left" className="d-table">
                Write to Us
              </Divider>

              <Row gutter={16}>
                <Col md={8} sm={8} xs={24}>
                  <Form.Item
                    label="First Name "
                    name="FirstName"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={8} sm={8} xs={24}>
                  <Form.Item
                    label="Last Name "
                    name="LastName"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={8} sm={8} xs={24}>
                  <Form.Item
                    label="Contact Number"
                    name="MobileNumber"
                    className="phno"
                    rules={[
                      {
                        required: true,
                        message: "Phone Number  Required",
                      },
                      {
                        minLength: 10,
                        maxLength: 10,
                        pattern: "^[0-9]{10}$",
                        message: "Must be 10 digits",
                      },
                    ]}
                  >
                    <Input
                      addonBefore={
                        <Form.Item
                          style={{ width: "35%" }}
                          name="ISDCode"
                          className="phno"
                          rules={[
                            {
                              required: true,
                              message: "Phone Number Code Required",
                            },
                          ]}
                          noStyle
                        >
                          <Select
                            showSearch
                            // placeholder="Select Country"
                            style={{ width: "80px" }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {CountryList.map((item) => (
                              <Option
                                key={item.dial_code}
                                value={item.dial_code}
                              >
                                {item.dial_code}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      }
                      className="inputbg"
                      placeholder="Enter Mobile No."
                    />
                  </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label="Email"
                    name="EmailAddress"
                    rules={[
                      { required: true, message: "Required" },
                      {
                        type: "email",
                        message: "Email is not a valid email",
                      },
                    ]}
                  >
                    <Input type="email" />
                  </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item label="Order Number" name="OrderNumber">
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item label="Departure Date" name="DepartureDate">
                    <DatePicker className="dateStyle" />
                  </Form.Item>
                </Col>
                <Col md={12} sm={12} xs={24}>
                  <Form.Item
                    label="Your Message"
                    name="YourMessage"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center" className="my-3">
                <Form.Item
                  name="remember"
                  rules={[
                    {
                      validator: (rule, value) => {
                        return value
                          ? Promise.resolve()
                          : Promise.reject("Plase Agree before Submitting");
                      },
                    },
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox>
                    I agree to receive promotional and marketing material{" "}
                  </Checkbox>
                </Form.Item>
              </Row>
              <Row justify="center" className="my-3">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleCaptcha}
                />
              </Row>

              <Form.Item className="center">
                <Button
                  disabled={buttonDisabled}
                  type="primary"
                  onClick={() => form.submit()}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      {/* )} */}
    </div>
  );
};

export default ContactUsForm;
