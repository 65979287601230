import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Radio,
  Layout,
  message,
  TimePicker,
  Popover,
  Modal,
} from "antd";
import { InfoCircleTwoTone, ExclamationCircleFilled } from "@ant-design/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";

import AirportAutoComplete from "../../common/AutoCompleteSelect/AirportAutoCompleteSelect";

import {
  OnlyFutureDateValidator,
  PaxAgeValidator,
} from "../../helpers/CustomValidators";
import queryString from "query-string";
// import { SeprateRequestCreator } from "./RequestCreatorHelper";
import ApiClient from "../../helpers/ApiClient";
import { MinusCircle } from "react-feather";
// import { useAuthContext } from "../../common/providers/AuthProvider";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

const CreateTicket = () => {
  const [form] = Form.useForm();
  // const { user } = useAuthContext();
  const [tripype, setTriptype] = useState("1");
  const [bookigFromType, setBookigFromType] = useState(1);

  const [agentList, setAgentList] = useState([]);
  let history = useHistory();

  const [isAdult, setIsAdult] = useState(true);
  const [isChild, setIsChild] = useState(false);
  const [isinfant, setIsInfant] = useState(false);
  const [getTicketDetails, setgetTicketDetails] = useState([]); // get all tickets --12-December-2023
  // const [loadingTicket, setLoadingTicket] = useState(true);

  const initialState = {
    id: "",
    pax: {},
    passengers: [],
    oneWaySegment: [],
    returnSegment: [],
    oneWayflightFare: {},
    oneWayBaggageInfo: [],
    returnBaggageInfo: [],
  };
  const [ticketData, setTicketData] = useState(initialState);

  //update get RefNo from Query string

  const params = queryString.parse(window.location.search);

  useEffect(() => {
    if (params.ref && params.ref.indexOf("SYT-F") != -1) {
      // getTicketDetails(params.ref);
    }
  }, []);
  const getAllUsers = () => {
    ApiClient.get(`admin/user/`)
      .then((res) => {
        if (res.status == 200) {
          let agents = res.data
            .filter((item, index) => item.Role == 5)
            .map((agent) => agent.userDetails);
          setAgentList(agents);
        }
      })
      .catch(() => { });
  };
  useEffect(() => {
 
    getAllUsers();
  }, []);


  const validationMessage = {
    required: " ",
  };

  const updateIsADult = (val) => {
    // val=val.target.value

    if (val > 0) {
      setIsAdult(true);
    } else {
      setIsAdult(false);
    }
  };


  const updateIsChild = (val) => {
    if (val > 0) {
      setIsChild(true);
    } else {
      setIsChild(false);
    }
  };



  const TicketDetails = ({ isminus, field, add, index, remove, tour }) => {
    const origin = useRef();
    const destination = useRef();
    const dateBox = useRef();
    const fieldsData = [];
    const [showDate, setShowDate] = useState(false);
    const [isloading, setIsloading] = useState(false);
    const handleOnSubmit = (ref) => {
      ref.current.focus();
      if (ref === dateBox) {
        setShowDate(true);
      }
    };

    {/* -------- Add Code For On Request Ticket Logic -------- */}
    const showDeleteConfirm = () => {
      confirm({
        title: 'Are you sure Cancel this Tour?',
        icon: <ExclamationCircleFilled />,
        // content: 'Some descriptions',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          handleCancelTicket(tour)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    };

    // Cancel Ticket Function 
    const handleCancelTicket = async (req) => {
      setIsloading(true);
      if(req) {
        await ApiClient.post('activities/cancel/tickets/', req)
        .then((res) => {
          if (res.status === 200) {
            message.success('Ticket Cancelled Successfully');
          } else {
            message.error('Failed to cancel ticket');
          }
        }).catch((e) => {
          message.error('Failed to cancel ticket');
        })
      }
      setIsloading(false);
    }

    return (
      <div>
        <Row gutter={16}>
          <Col className="gutter-class" md={5} xs={5}>
            <Form.Item label="Bar Code Number" 
              {...field}
              name={[field.name, "BarCode"]}
              fieldKey={[field.fieldKey, "BarCode"]}
             
            rules={[{ required: false }]}>
              <Input placeholder="Bar Code Number" />
            </Form.Item>
          </Col>
          {/* {console.log(field)} */}

          <Col className="gutter-class" md={3} xs={3}>
            <Form.Item
              label="Packeges Types"
              {...field}
              name={[field.name, "paxType"]}
              fieldKey={[field.fieldKey, "paxType"]}
              rules={[{ required: true }]}
            >
              <Select onChange={updateIsADult}>
                <Option value="Adult">Adult</Option>
                <Option value="Child">Child</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col className="gutter-class" md={4} xs={4}>
            <Form.Item
              label="Adults"
             {...field}
              name={[field.name, "NoOfAdult"]}
              fieldKey={[field.fieldKey, "NoOfAdult"]}
              rules={[{ required: true }]}
            >
              <Select onChange={updateIsADult}>
                <Option value="0">0</Option>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col className="gutter-class" md={4} xs={4}>
            <Form.Item
              label="Children"
              
              {...field}
              name={[field.name, "NoOfChild"]}
              fieldKey={[field.fieldKey, "NoOfChild"]}
              rules={[{ required: true }]}
            >
              <Select onChange={updateIsChild}>
                <Option value="0">0</Option>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* <Col className="gutter-class" md={3} xs={3}>
            <Form.Item
              label="Infant"
              
              {...field}
              name={[field.name, "NoOfInfant"]}
              fieldKey={[field.fieldKey, "NoOfInfant"]}
              rules={[{ required: true }]}
            >
              <Select>
               {[...Array(11)].map((_,i) => (
                <Option value={i}>{i}</Option>
               ))}
              </Select>
            </Form.Item>
          </Col> */}

          <Col className="gutter-class" md={3} xs={3}>
            <Form.Item
              label="Emergency Numbers"
              
              {...field}
              name={[field.name, "phone"]}
              fieldKey={[field.fieldKey, "phone"]}
              rules={[{ required: false }]}
            >
              <Input placeholder="Emergency Numbers"></Input>
            </Form.Item>
          </Col>

          <Col className="gutter-class" md={3} xs={3}>
            <Form.Item
              label="Select Tour"
              
              {...field}
              name={[field.name, "TourId"]}
              fieldKey={[field.fieldKey, "TourId"]}
              rules={[{ required: true }]}
            >
              <Select>
              <Option value={tour.tourId}>{tour.tourName}</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={1} style={{ alignSelf: "center" }}>
            { isminus > 1 ?  (
              <MinusCircle
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            ):null }
          </Col>
        </Row>
        {/* -------- Add Code For On Request Ticket Logic -------- */}
        <Row gutter={16}>
          <Col className="gutter-class" md={3} xs={3}>
            <Button loading={isloading} onClick={showDeleteConfirm}>Cancel Tour</Button>
          </Col>
        </Row>
        {/* -------- Add Code For On Request Ticket Logic -------- */}
      </div>
    );
  };

  const onFormSubmit = (formData) => {
    // get all qrcode in one array by karthik 13-December-2023
    const allTicketDetails = [];
    Object.keys(formData).forEach(key => {
      if (key.startsWith('ticketDetails') && formData[key] !== undefined) {
        allTicketDetails.push(...formData[key]);
      }
    });

    // create qrcode requestdata
    let requestData = {
      BookingRef: formData.BookingRef,
      PNRNo: formData.PNRNo,
      SYS_RefNo: formData.SYS_RefNo,
      slot: formData.slot,
      ticketDetails: allTicketDetails
    };
    ApiClient.post("admin/booking/activityoffline", requestData).then((res) => {
      if (res.statusCode == 200) {
        UpdateTicketVourcherStatus(formData.SYS_RefNo)
        form.resetFields();
      } else {
        message.error("Something went wrong");
      }
    });

    const UpdateTicketVourcherStatus = (refNo) => {
      ApiClient.post("activities/tours/updatevourcherstatus", {refNo:refNo})
        .then((result) => result)
        .then((resp) => {
          if (resp.status == 200) {
            message.success("Successfully updated")
            voucherNotification(formData.SYS_RefNo)
          } else {
            message.error("Error updating status: " + resp.status);
          }
        })
        .catch((error) => { });
    };

    const voucherNotification = (refNo) => {
      ApiClient.post(`CommonUtility/voucherNotification/${refNo}`)
        .then((result) => result)
        .then((resp) => {
          if (resp.status == 200) {
            message.success("Voucher Notification Mail Sent Successfully");
            history.push("/");
          } else {
            message.error("Voucher Notification Mail Sent Error " + resp.message);
          }
        })
        .catch((error) => { });
    };
    // HERE f is form data

    // let requestData = SeprateRequestCreator(formData, tripype);

    // if (params.mode != "edit") {
    //   ApiClient.post("admin/booking/offline", requestData).then((res) => {
    //     if (res.statusCode == 200) {
    //       message.success("Added SuccessFully");
    //     } else {
    //       message.error("Error addding Data");
    //     }
    //   });
    // } else {
    //   ApiClient.put(
    //     "admin/booking/offline/" + params.ref,
    //     {},
    //     requestData
    //   ).then((res) => {
    //     if (res.statusCode == 200) {
    //       message.success("Flight Details Updated Successfully", 3);
    //     } else if (res.statusCode == 400) {
    //       message.error(" Unable to update the Flight Details", 3);
    //     } else {
    //       message.error(" Error Uploading Data", 3);
    //     }
    //   });
    // }
  };
  const valdationFailed = () => {
    message.error("Please Check All Fields");
  };
  const onBookingTypeChange = (e) => {
    setBookigFromType(e);
  };

  const BookingLabelTitle = () => (
    <>
      Booking From {"  "}
      {/* <HelpInfoHelper screenName={"/admin/bookoffline"} /> */}
    </>
  );

  const fetchTicketDetails = (e) => {
    let refno = e.target.value;
    if(refno != ''){
      ApiClient.get(`activities/tours/booking/${refno}`)
      .then((result) => result)
      .then((resp) => {
        if (resp.statusCode == 200) {
          let confirmTicket = resp.data?.TourDetails.filter(t => t.bookingStatus === 3 ||t.bookingStatus === 7 || t.bookingStatus === 11);
          setgetTicketDetails(confirmTicket);
          // console.log(confirmTicket);
        } else {
          message.error(resp.message);
          setgetTicketDetails(resp.data?.TourDetails)
        }
      })
      .catch((error) => { });
    }
    setgetTicketDetails([])
  };
  
  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <Form
                initialValues={{ ...ticketData }}
                layout="vertical"
                form={form}
                onFinish={onFormSubmit}
                validateMessages={validationMessage}
                onFinishFailed={valdationFailed}
              >
           
                <div className="card-gap">
                  <div className="flight-depart-details">
                    <h4>Ticket Details</h4>
                    <Card title="Ticket Details" bordered={false}>
                      <div className="">
                        <Row gutter="16">

                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item label="SYT-Refrence No" name="SYS_RefNo"
                              rules={[{ required: true }]}>
                              <Input placeholder="SYT-Refrence No" onChange={fetchTicketDetails} />
                            </Form.Item>
                          </Col>

                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item label="Api-Reference No" name="BookingRef" rules={[{ required: true }]}>
                              <Input placeholder="Api-Reference No" />
                            </Form.Item>
                          </Col>

                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item label="Slot" name="slot" rules={[{ required: false }]}>
                              <Input placeholder="Slot" />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item label="PNR Number" name="PNRNo" rules={[{ required: false }]}>
                              <Input placeholder="PNR Number" />
                            </Form.Item>
                          </Col>
                         
                        </Row>
                      </div>

                      {/* Add And Remove Return */}
                      {getTicketDetails?.length > 0 && getTicketDetails.map((tour, i) => {
                        return(
                        <div className="flight-details" key={`tour-${i}`}>
                          <h5>{`Tour-${i + 1} :`} {tour.tourName}</h5>
                          <Form.List name={`ticketDetails${i}`}>
                            {(fields, { add, remove }) => {
                              return (
                                <>
                                  {fields.map((field, index) => {
                                    const uniqueKey = `${i}-${index}`;
                                    return (
                                      <TicketDetails
                                        isminus={fields.length}
                                        field={field}
                                        index={index}
                                        /* key={field.key} comment code by karthik 13-dec-2023 */
                                        key={uniqueKey}
                                        /* remove={remove} comment code by karthik 13-dec-2023 */
                                        remove={() => remove(index)}
                                        tour={tour}
                                      />
                                    );
                                  })}
                                  {fields.length < 50 ? (
                                    <Button
                                      type="primary"
                                      /* id={`add_onward_flight_details`} comment code by karthik 13-dec-2023 */
                                      id={`add_onward_flight_details_${i}`}
                                      onClick={() => add()}
                                    >
                                      Add Ticket Details
                                    </Button>
                                  ) : null}
                                </>
                              );
                            }}
                          </Form.List>
                        </div>
                        )
                      })}
                    </Card>
                   
                    <Card>
                      <Row gutter={16}>
                        <Col className="gutter-class" md={24} xs={24}>
                          <Form.Item className="bookoffline-submit">
                            <Button htmlType="submit">Submit</Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Content>
      </div>
    </div>
  );
};
export default CreateTicket;
