import React from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import SeoMetaData from "../../common/Seo/SeoMetaData";
const AboutUs = () => {
  return ( 
    <>
  <SeoMetaData path={window.location.pathname} />
  <CmsPages cmsId={2} pageTitle="About Us" />
  </>
  );
};

export default AboutUs;
