import React from "react";
import { Helmet } from "react-helmet";
import CmsPages from "../../common/CMSPages/CmsPages";
import { AppConstants } from "../../helpers/constants";
import SeoMetaData from "../../common/Seo/SeoMetaData";
const Faqs = () => {
  return (
    <>
      <SeoMetaData path={window.location.pathname} />
      <CmsPages cmsId={4} pageTitle="FAQS" />
    </>
  );
};

export default Faqs;
