import React, { useEffect, useState, useRef } from "react";
import { MinusCircle } from "react-feather";
import { Button,Tag, Card, Col, Input, InputNumber, Row, Form, Select, Table, Layout, message, Modal, Popconfirm, Popover } from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import { PlusOutlined, EditOutlined, DeleteOutlined, CloseOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;
const { Content } = Layout;

const Transferslab = (props) => {
  const [addMarkupModal, setAddMarkupModal] = useState(false);
  const [roleType, setRoleType] = useState(1);
  const [categoryList, setCategoryList] = useState([]);
  const [hotelCommissions, setHotelCommissions] = useState([]);
  const [actCommisionForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [toBeEditable, setToBeEditable] = useState(-1);
  const [TransfareSlabList, setTransfareSlabList] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const handleRoleType = (val) => setRoleType(val);
  const [loding, setLoding] = useState(true);
  const [fields, setFields] = useState([{ InitialRange: null,EndRange:null,BaseAmount:null,categoryid:null }]);
let transfercategoryList
let TransfareSlab
let count;
 // const [submitcount, setCount] = useState(0);
  //const [GroupId, setGroupid] = useState(1);
  let GroupId=1;
    function handleAdd() {
   let form= actCommisionForm.getFieldsValue();
   let validate=   actCommisionForm.validateFields(["BaseAmount","NoOfGroup","TransferCategory","initialRange","endRange"]).then(value=>{
    alert(value)
    const values = [...fields];
    if(count==1){
    values.push({ InitialRange: null,EndRange:null,BaseAmount:null,categoryid:null });
    }
    setFields(values);
    GroupId=GroupId+1;
   }).catch(err=>{
    message.error("Required Fields Missing");
   });
 
  }
  
  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }
  const GetAllSlab = async()=> {
    await getTransferCategory()
    await getTransfareSlab()
  }
  const SetTableData= ()=>{
  
    let testss=   transfercategoryList
    // TransfareSlab

          let mapData =TransfareSlab.map((item, index) => {
           let category= transfercategoryList.filter(x=>x.categoryId==item.tranferCategoryid);
           let categoryName= category.length>=1?category[0].categoryName:""
            return {
              ...item,
              Sno: index + 1,
               Category:categoryName ,
              initialRange: item?.initialRange ?? "",
              endRange: item?.endRange?? "",
              baseAmount: item?.baseAmount?? "",
             
            };
          });

          setHotelCommissions(mapData);
        }

  useEffect(async () => {
    
   await getTransferCategory()
   await getTransfareSlab()

  }, []);

  const getTransferCategory = () => {
    ApiClient.get(`extranet/transfercategories`)
      .then((resp) => {
        if (resp.status == 200) {
          setCategoryList(resp.data);
          transfercategoryList= resp.data;
          setLoding(false);
        }
      })
      .catch(() => {setLoding(false);});
  };

  const getTransfareSlab = () => {
    ApiClient.get("extranet/transferslabs")
      .then((res) => {
        if (res.status == 200) {
          setTransfareSlabList(res.data);
          TransfareSlab   = res.data;
          SetTableData()
          setLoding(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoding(false);
      });
  };

  // const getProviders = () => {
  //   ApiClient.get("admin/providers")
  //     .then((res) => {
  //       if (res.statusCode == 200) {
  //         let filterProvider = res.data.filter((item) => item.ServiceType === 4);
  //         setProvidersList(filterProvider);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const EditRecord = (rec) => {
    handleRoleType(rec.RoleType);
    actCommisionForm.setFieldsValue({
      ...rec,
    });
    setIsEdit(true);
    setAddMarkupModal(true);
  };

  const tableColumns = [
    {
      title: "S.No",
      dataIndex: "Sno",
    },

    {
      title: "Category",
      dataIndex: "Category",
      sorter: (a, b) => a.Category.localeCompare(b.Category),
    },

    {
      title: "Initial Range",
      dataIndex: "initialRange",
      sorter: (a, b) => a.initialRange.localeCompare(b.initialRange),
    },
    {
      title: "End Range",
      dataIndex: "endRange",
      sorter: (a, b) => a.endRange.localeCompare(b.endRange),
    },
    {
      title: "Base Amount",
      dataIndex: "baseAmount",
      sorter: (a, b) => a.baseAmount.localeCompare(b.baseAmount),
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const tableActions = (currentRec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              setToBeEditable(currentRec.Id);
              setIsEdit(true);
              EditRecord(currentRec);
            }}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        &nbsp;
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm title="Are you sure to delete?" onConfirm={() => DeleteHotelCommission({initialRange: currentRec.initialRange, endRange: currentRec.endRange, id: currentRec.tranferCategoryid})}>
            <DeleteOutlined style={{ color: "red", fontSize: "20px", textAlign: "center" }} />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const onFormSubmit = (val) => {
    let data = {
      createdBy: 1,
      modifiedBy: 1,
      ...val,
    };
 
    // if(fields==submitcount){
    //   alert("Add");
    // }
  isEdit ? UpdateHotelCommission(data) : AddTransferSlab(data);
  };

  const AddTransferSlab = (data) => {
    ApiClient.post("extranet/transferslab", data)
      .then((response) => {
        if (response.status == 200) {
          GetAllSlab();
          message.success("Added successfully", 3);
          setAddMarkupModal(false);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const closeSearch = () => {
    setShowSearchBox(false);
    GetAllSlab();
  };

  const UpdateHotelCommission = (data) => {
    ApiClient.put("extranet/transferslabs/" + toBeEditable, {}, data)
      .then((response) => {
        if (response.status == 200) {
          GetAllSlab();
          message.success("Updated successfully");
          setAddMarkupModal(false);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeleteHotelCommission = (req) => {
    ApiClient.post("extranet/sd/transferslabs/", req)
      .then((res) => {
        if (res.status == 200) {
          GetAllSlab();
          message.success("Deleted Successfully");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = hotelCommissions.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item).toLowerCase().includes(searchVal.toLowerCase());
        }
      });
      setHotelCommissions(searchResults);
    } else {
      // getActivitiesCommissions();
      GetAllSlab();
    }
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const handleExcel = () => {
    const excelData = hotelCommissions.map((item) => {
      return {
        S_No: item.Sno,
        Role: item.RoleTypeName,
        Membership: item.MembershipName,
        Commission: item.Commission,
        Markup: item.Markup,
        Provider_Name: item.ProviderName,
        Category_Name: item.CategoryName,
        Star_Rating: item.Rating,
      };
    });

    props.exportExcel(excelData, "Activities CommissionDetails");
  };

  return (
    <>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>
                      View Transfer Slab <HelpInfoHelper screenName={"/admin/activitiescommissions"} />
                    </h5>
                    <p>{hotelCommissions.length} rows found !</p>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && <Input placeholder="Search" onChange={(e) => searchTableData(e)} suffix={closeSearchInput} style={{ padding: "0px 12px" }} />}
                  &nbsp;&nbsp;
                  <img src={search} alt="search" onClick={(e) => searchData()} />
                  <img src={excel} alt="excel" onClick={handleExcel} />
                  {/* <img src={exchange} alt="exchange" onClick={() => getActivitiesCommissions()} /> */}
                  <div
                    onClick={(e) => {
                      if (isEdit) {
                        setIsEdit(false);
                      }
                      handleRoleType(1);
                      setAddMarkupModal(true);
                      actCommisionForm.resetFields();
                    }}
                    className="add-icon cursor-pointer"
                  >
                    <PlusOutlined />
                  </div>
                </div>
              </div>
              <div>
                <Table scroll={{ x: true }} bordered dataSource={hotelCommissions} columns={tableColumns} />
              </div>
            </Card>
          </div>
        </Content>
      </div>

   
      <Modal
        width={"800px"}
        className="markup-modal"
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>{isEdit ? "Update" : "Add"} Transfer Slab</strong>
            </h6>
          </div>,
        ]}
        visible={addMarkupModal}
        onCancel={(e) => setAddMarkupModal(false)}
        footer={[
          <div>
            <Button key="add" type="primary" htmlType="submit" onClick={actCommisionForm.submit}>
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button key="submit" type="danger" onClick={() => actCommisionForm.resetFields()}>
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form layout={"vertical"} form={actCommisionForm} onFinish={onFormSubmit}>
          <div>
            <Row gutter={16}>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Transfer Category"
                  name="tranferCategoryid"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  
                  <Select 
                  showSearch
                  placeholder="Please Select"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  >
                    {categoryList.map((item) => (
                      <Option key={item.categoryId} value={item.categoryId}>
                        {item.categoryName}
                      </Option>

                    ))}
                  </Select>
                </Form.Item>
              </Col>
          
            </Row>
          </div>
          <div className="seperation-line">
     
          <>
        
          
          <Row gutter={16}>
              
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Starting Pax Range"
                  name="initialRange"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={0} type="number"  
              />
                </Form.Item>
              </Col>

           
             
                <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Ending Pax Range"
                  name="endRange"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={0} type="number" />
                  
                </Form.Item>
                </Col>
                {/* this column hide by karthik refences by nitin --01/05/24 */}
                <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Base Amount"
                  name="baseAmount"
                  rules={[
                    {
                      required: false,
                      message: " ",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={0} type="number" />
                  
                </Form.Item>
             
              </Col>

              <Col md={24} xs={24}>
                <div className="price-item">
                  <Form.List name="transferPrice">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields.map((field, index) => {
                            return (
                              <AddTransfersPrices
                                isminus={fields.length}
                                field={field}
                                index={index}
                                key={field.key}
                                remove={remove}
                              />
                            );
                          })}
                          {fields.length < 50 ? (
                            <Button
                              type="primary"
                              id="add_onward_flight_details"
                              onClick={() => add()}
                            >
                              Add Price Details
                            </Button>
                          ) : null}
                        </>
                      );
                    }}
                  </Form.List>
                </div>
              </Col>
          
            </Row></>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const AddTransfersPrices = ({ isminus, field, add, index, remove }) => {
  const MonthOption = [
    {
      value: '1',
      label: 'January',
    },
    {
      value: '2',
      label: 'February',
    },
    {
      value: '3',
      label: 'March',
    },
    {
      value: '4',
      label: 'April',
    },
    {
      value: '5',
      label: 'May',
    },
    {
      value: '6',
      label: 'June',
    },
    {
      value: '7',
      label: 'July',
    },
    {
      value: '8',
      label: 'August',
    },
    {
      value: '9',
      label: 'September',
    },
    {
      value: '10',
      label: 'October',
    },
    {
      value: '11',
      label: 'November',
    },
    {
      value: '12',
      label: 'December',
    },
  ]
  const origin = useRef();
  const destination = useRef();
  const dateBox = useRef();
  const [showDate, setShowDate] = useState(false);
  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === dateBox) {
      setShowDate(true);
    }
  };
  return (
    <div>
      <div className="flight-title">
        <h6>Prices Details :- {index + 1}</h6>
      </div>
        <Row gutter={16}>
          <Col className="gutter-class" md={6} xs={8}>
            <Form.Item label="Price"
              {...field}
              name={[field.name, "adultprice"]}
              fieldKey={[field.fieldKey, "adultprice"]}
            
            rules={[{ required: true }]}>
              <Input placeholder="Price" />
            </Form.Item>
          </Col>

          {/* <Col className="gutter-class" md={6} xs={8}>
            <Form.Item label="Child Price"
              {...field}
              name={[field.name, "childprice"]}
              fieldKey={[field.fieldKey, "childtprice"]}
            
            rules={[{ required: false }]}>
              <Input placeholder="Child Price" />
            </Form.Item>
          </Col> */}
          <Col className="gutter-class" md={6} xs={8}>
            <Form.Item label="Month Pricing"
              {...field}
              name={[field.name, "monthprice"]}
              fieldKey={[field.fieldKey, "monthprice"]}
            
            rules={[{ required: true }]}>
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={MonthOption}
              />
            </Form.Item>
          </Col>

          <Col span={1} style={{ alignSelf: "center" }}>
            { isminus > 1 ?  (
              <MinusCircle
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            ):null }
          </Col>
      </Row>
    </div>
  );
};

export default UpdatedComponent(Transferslab);
