import { createSlice } from "@reduxjs/toolkit";

const seoDetailSlice = createSlice({
    name: "seoDetail",
    initialState: [],
    reducers: {
        setSeoDetails: (state, action) => {
            return action.payload;
        },
        clearSeoDetails: () => {
            return [];
        }
    }
})

export const { setSeoDetails, clearSeoDetails } = seoDetailSlice.actions;

export default seoDetailSlice.reducer