import React, { useContext, useEffect, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { Card, Col, message, Row } from "antd";

import GlobalStatesContext from "../../../common/providers/GlobalStatesContext";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useHotelContext } from "../../../common/providers/Hotels/HotelProvider";
import HotelDetailsBox from "../../../components/HotelDetailsBox/HotelDetailsBox";
import VerifyOtpModal from "../../../common/OtpModal/VerifyOtpModal";
import ApiClient from "../../../helpers/ApiClient";
import HotelBookPayCard from "../HotelCheckout/HotelBookPayCard";

import HotelFairBox, {
  getHotelGrandTotal,
} from "../HotelCheckout/HotelFairBox";
import "../HotelPreview/HotelPreview.scss";
import HotelPassengers from "./HotelPassengers/HotelPassengers";
import moment from "moment";

const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";

const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}
const HotelPreview = () => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: { hotelCheckOutData },
  } = useHotelContext();

  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  // const [pgDisplay, setPgDisplay] = useState(false);
  // const [pgData, setPgData] = useState({});
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
  const [pgIsLoading, setPgIsLoading] = useState({
    direct: false,
    hold: false,
  });
  const {
    state: {
      otherData: { promoData, ConvFee, selectedInsuranceData, redeemAmount, transactionFee, sele },
      SeletedPayOption
    },
  } = useContext(GlobalStatesContext);
  let history = useHistory();
  useEffect(() => {
    if (Object.keys(hotelCheckOutData).length <= 0) {
      history.goBack();
    }
  }, [hotelCheckOutData]);

  const userReg = (name, email, mobile) => {
    let regObj = {
      Name: name,
      Email: email,
      DialingCode: "+91",
      Mobile: mobile,
      Password: "",
      Role: 4,
      DeviceToken: "string",
      DeviceType: "Web",
      FirBaseToken: "string",
    };

    ApiClient.post("admin/user/register", regObj)
      .then(() => {})
      .catch();
  };

  // const processPayment = (oid, paymentPaxInfo, allAmount) => {
  //   let {
  //     totalAmount,
  //     convamount,
  //     totalCommission,
  //     adminCommission,
  //     agentMarkup,
  //     markup,
  //   } = allAmount;

  //   let commissionAmount = 0;
  //   let agentMarkupAmount = 0;
  //   let adminCommissionAmount = 0;
  //   if (user?.Role?.RoleId === 5) {
  //     commissionAmount = Number(totalCommission);
  //     agentMarkupAmount = Number(agentMarkup);
  //     adminCommissionAmount = Number(adminCommission);
  //   }

  //   let data = {
  //     OrderId: oid,
  //     OrderAmount: Number(totalAmount),
  //     OrderCurrency: "INR",
  //     OrderNote: "Static Note",
  //     ...paymentPaxInfo,
  //     TDS: 0,
  //     GST: 0,
  //     Commission: commissionAmount, // for agent only
  //     AdminMarkup: markup,
  //     AgentMarkup: agentMarkupAmount, // for agent only
  //     Conveniencefee: Number(convamount),
  //     AdminCommission: adminCommissionAmount, // for agent only
  //   };
  //   console.log("datapay", data);
  //   // set PgData and Display
  //   setPgData(data);
  //   setPgDisplay(true);
  // };

  // blockType enum
  // instantBlock = 1
  // Hold = 2

  const navigateToTicket = ({ pgType = null, blockType = 1 }) => {
    // setPgIsLoading({
    //   direct: blockType === 1 ? true : false,
    //   hold: blockType === 2 ? true : false,
    // });

    let guestsList = hotelCheckOutData.guests.map((pax) => {
      return pax.paxInfoList.map((item) => {
        return item;
      });
    });

    let lastCancellationDate = "";
    let lastVoucherDate = "";
    let cancellationDates = [];
    let voucherDate = [];

    if (hotelCheckOutData?.hotelPriceData?.rooms?.length > 0) {
      cancellationDates = hotelCheckOutData?.hotelPriceData?.rooms?.map(
        (roomDates) => {
          return roomDates.ratePlans[0]?.lastCancellationDate;
        }
      );

      voucherDate = hotelCheckOutData?.hotelPriceData?.rooms?.map(
        (roomDates) => {
          return roomDates.ratePlans[0]?.lastVoucherDate;
        }
      );
    }

    if (cancellationDates?.length > 1) {
      lastCancellationDate = cancellationDates.reduce((prev, cur, i) => {
        if (prev && cur) {
          return moment(prev).isSameOrBefore(cur) ? prev : cur;
        } else if (prev) {
          return prev;
        } else {
          return cur;
        }
      });
    } else if (cancellationDates?.length === 1) {
      lastCancellationDate = cancellationDates[0] ? cancellationDates[0] : "";
    }

    if (voucherDate?.length > 1) {
      lastVoucherDate = voucherDate.reduce((prev, cur) => {
        if (prev && cur) {
          return moment(prev).isSameOrBefore(cur) ? prev : cur;
        } else if (prev) {
          return prev;
        } else return cur;
      });
    } else if (voucherDate?.length === 1) {
      lastVoucherDate = voucherDate[0] ? voucherDate[0] : "";
    }

    let allAmount = getHotelGrandTotal(
      hotelCheckOutData.hotelPriceData,
      hotelCheckOutData.hotelSearchData,
      ConvFee,
      promoData,
      selectedInsuranceData,
      redeemAmount,
      transactionFee,
      SeletedPayOption
    );
debugger
    let guestsDetails = [];

    if (guestsList.length > 0) {
      guestsList.forEach((item) => {
        if (item && item?.length > 0) {
          item.forEach((guestInfo) => {
            guestsDetails.push(guestInfo);
          });
        }
      });
    }

    let commissionAmount = 0;
    let agentMarkupAmount = 0;
    let adminCommissionAmount = 0;
    if (user?.Role?.RoleId === 5) {
      commissionAmount = Number(allAmount.totalCommission);
      agentMarkupAmount = Number(allAmount.agentMarkup);
      adminCommissionAmount = Number(allAmount.adminCommission);
    }

    let data = {
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
      traceId: hotelCheckOutData.hotelPriceData.traceId,
      guests: guestsDetails,
      addressInfo: hotelCheckOutData.addressInfo,
      flightInfo: {
        arrivingByFlight: "",
        flightName: "",
        flightNumber: "",
        hour: "",
        minutes: "",
        remarks: "",
        airlineName: "",
        airlinePnr: "",
      },
      docIdentification: {
        type: "",
        value: "",
      },
      creditCardInfo: {
        securityId: "",
        cardNumber: "",
        expirationMonth: "",
        expirationYear: "",
        firstName: "",
        lastName: "",
        billingAmount: "",
        billingCurrency: "",
        cardHolderAddress: {
          addressLine1: "",
          addressLine2: "strng",
          city: "",
          zipcode: "",
          countryCode: "",
        },
      },
      paymentModeType: transactionFee?.methodType,
      promoData: promoData.Code ?? "",
      convienenceId: ConvFee.id ?? 0,
      insuranceRequired: hotelCheckOutData?.insuranceRequired,
      // insuranceData: hotelCheckOutData.insuranceData,
      totalPrice: Math.ceil(allAmount.grandTotal),
      // Commission: commissionAmount,
      AdminMarkup: allAmount.markup,
      AgentMarkup: agentMarkupAmount,
      // Conveniencefee: Number(allAmount.convamount),
      // AdminCommission: adminCommissionAmount,
      LastCancellationDate: lastCancellationDate ? lastCancellationDate : "",
      LastVoucherDate: lastVoucherDate ? lastVoucherDate : "",
      pgType: pgType ? pgType : pgDetails.pgType,
      bookingType: blockType,
      isCouponReedem: redeemAmount.status ?? false,
    };

    let paymentPaxInfo = {
      CustomerName: guestsList[0][0].firstName,
      CustomerEmail: hotelCheckOutData.addressInfo.email,
      CustomerPhone: hotelCheckOutData.addressInfo.phoneNo,
    };
    if (!user) {
      userReg(
        guestsList[0][0].firstName,
        hotelCheckOutData.addressInfo.email,
        hotelCheckOutData.addressInfo.phoneNo
      );
    }

    setLoadingSpin(true);
    console.log(allAmount);
    debugger
    ApiClient.post("hotels-v2/hotelblock", data)
      .then((result) => {
        return result;
      })
      .then((res) => {
        setPgIsLoading({
          direct: false,
          hold: false,
        });
        setLoadingSpin(false);
        if (res.status === 200) {
          if (blockType === 2) {
            // processPayment(res.data.RefNumber, paymentPaxInfo, allAmount);
            history.push(`/hotel/ticket?ref=${res.data.RefNumber}`);
          } else {
            window.location.href = res.data.payment_link;
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else if (res?.data[0]?.errorDetail)
            message.error(res?.data[0]?.errorDetail, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((err) => {
        setPgIsLoading({
          direct: false,
          hold: false,
        });
        setLoadingSpin(false);
      });
  };

  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyserviceOtp", {
      Mobile: hotelCheckOutData?.addressInfo?.phoneNo,
      DialingCode: hotelCheckOutData?.addressInfo?.areaCode,
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          navigateToTicket({ pgType: pgDetails.pgType });
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = () => {
    ApiClient.post("admin/sendserviceOtp", {
      Mobile: !user ? hotelCheckOutData?.addressInfo?.phoneNo : user.Mobile,
      DialingCode: hotelCheckOutData?.addressInfo?.areaCode,
      refUrl: '/hotel/preview',
      UserId: user && user.UserID
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const ValidateBookingLimit = () => {
    let { totalAmount,grandTotal } = getHotelGrandTotal(
      hotelCheckOutData.hotelPriceData,
      hotelCheckOutData.hotelSearchData,
      ConvFee,
      promoData,
      selectedInsuranceData,
      redeemAmount,
      transactionFee,
      SeletedPayOption
    );
    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 2,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: grandTotal ? grandTotal : totalAmount,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          handleSendOTP();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const blockApiReq = (pgType) => {
    dispatchPgDetails({ type: PGTYPE, payload: pgType });
    if (pgType) {
      if (agent) {
        navigateToTicket({ pgType: pgType });
      } else {
        ValidateBookingLimit();
      }
    }
  };

  const processPayGateway = (blockType) => {
    setPgIsLoading({
      direct: blockType === 1 ? true : false,
      hold: blockType === 2 ? true : false,
    });
    if (blockType === 2) {
      navigateToTicket({ blockType: blockType, pgType: 1 });
    }
  };

  return (
    <div className="hotel-preview-container">
      <div className="form-body ">
        <div className="form-body-container">
          {Object.keys(hotelCheckOutData).length > 0 ? (
            <Row gutter={[16, 16]}>
              <Col md={16} sm={24} xs={24}>
                <p className="booking-summary-div">Hotel Details</p>

                <Card bordered={false} className="hotel-card-wrapper">
                  <HotelDetailsBox
                    hotelDetailsObj={hotelCheckOutData.hotelPriceData}
                    hotelSearchData={hotelCheckOutData.hotelSearchData}
                  />
                </Card>
                <div className="contact-header">
                  <div className="contact-title">
                    <p className="booking-summary-div">Guests Details</p>
                  </div>
                </div>

                <Card bordered={false} className="hotel-card-wrapper">
                  <HotelPassengers passengersInfo={hotelCheckOutData.guests} />
                </Card>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <div className="hotel-price-wrapper">
                  <HotelFairBox
                    hotelDetailsObj={hotelCheckOutData.hotelPriceData}
                    hotelSearchData={hotelCheckOutData.hotelSearchData}
                    isPromoVisible={false}
                  />

                  <HotelBookPayCard
                    isLoading={false}
                    pgIsLoading={pgIsLoading.direct}
                    holdLoading={pgIsLoading.hold}
                    purchaseType={
                      hotelCheckOutData?.hotelPriceData?.purchaseType
                    }
                    bookpaycardinfo={"hotel-review"}
                    // navigateToTicket={
                    //   agent ? navigateToTicket : ValidateBookingLimit
                    // }
                    // pgDisplay={pgDetails.pgDisplay}
                    // pgData={pgData}
                    agent={agent}
                    blockApiReq={blockApiReq}
                    processPayGateway={processPayGateway}
                    loadingSpin={loadingSpin}
                  />
                </div>
              </Col>
            </Row>
          ) : (
            "No Hotel Selected "
          )}
        </div>
      </div>
      {verifyModalVisible && (
        <VerifyOtpModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyOtp={handleVerifyOtp}
          handleResendOtp={handleSendOTP}
          mobile={hotelCheckOutData?.addressInfo?.phoneNo}
        />
      )}
    </div>
  );
};

export default HotelPreview;
