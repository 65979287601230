import React from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import { useSelector } from "react-redux";
import SeoMetaData from "../../common/Seo/SeoMetaData";

const Termsofconditions = () => {
  const SeoDetailsContainser = useSelector(state => state.seoDetails);
  return (
    <React.Fragment>
      <SeoMetaData path={window.location.pathname} />
      <CmsPages cmsId={5} pageTitle="Terms and Conditions" />;
    </React.Fragment>
  )
};

export default Termsofconditions;
