import { Col, Row, Form } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import ApiClient from "../../helpers/ApiClient";
import Banner from "../../components/banner/Banner";
import Homesearch from "../../components/search-box/SearchBox";
import HomeCarousel from "../HomeCarousel/HomeCarousel";
import "./Home.scss";
import TopFlights from "./TopFlights/TopFlights";
import TopCities from "./TopCities/TopCities";
import { useSytContext } from "../../common/providers/SytProvider";
import * as ANTD from "antd";
import { Carousel } from "antd";
// import Blog from "../Blog/Blog";
import BlogGrid from "../Blog/BlogGrid";
import AgentHomePage from "../../components/Agenthomepage/Agenthomepage";
import { useAuthContext } from "../../common/providers/AuthProvider";
import Subscribe from "../subscribe/Subscribe";
import { Link } from "react-router-dom";
import Reviews from "../../common/Reviews/Reviews";
import FooterBanner from "./FooterBanner/FooterBanner";
import HomeSlider from "./HomeSlider/HomeSlider";
import SeoMetaData from "../../common/Seo/SeoMetaData";
// import { useSelector, useDispatch } from 'react-redux'
// import { setSeoDetails, clearSeoDetails } from "../../ReduxContainer/SeoSlice"

const Home = () => {
  const {
    blog,
    flightBanners,
    footerBanner,
    bigHomeSliderBanners,
    smallHomeSliderBanners,
  } = useSytContext();
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();

  const [dealsList, setDealsList] = useState([]);
  // const ReduxContainer = useSelector(state => state);
  // const dispatch = useDispatch()

  const getDeals = () => {
    ApiClient.get("admin/deals")
      .then((res) => {
        if (res.status === 200) {
          setDealsList(res.data);
        }
      })
      .catch((e) => {
        setDealsList([]);
      });
  };

  // const getSeoMataData = () => {
  //   ApiClient.get('admin/seodata').then((res) => {
  //     if(res.statusCode == 200) {
  //       if(res.data.length > 0) {
  //         dispatch(setSeoDetails(res?.data));
  //       }else{
  //         dispatch(setSeoDetails([]));
  //       }
  //     }
  //   }).catch((e) => {
  //     console.log(e);
  //   })
  // }

  useEffect(() => {
    getDeals();
  }, []);

  // useLayoutEffect(() => {
  //   getSeoMataData();
  // }, [])

  return (
    <React.Fragment>
      <SeoMetaData path={window.location.pathname} />
    <div id="home-page">
      {(agent && user?.Role?.RoleId === 5) || user?.Role?.RoleId === 7 ? (
        <AgentHomePage />
      ) : (
        <section className="hero-banner">
          <Banner banner={flightBanners} />
          <Homesearch />
        </section>
      )}
      <section className="home-best-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  {/* <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p> */}
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts1.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  {/* <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="container">
        {footerBanner.length > 0 && (
          <div className="domestic-airsearch faqs-flight-sec">
            <FooterBanner footerBanner={footerBanner} />
          </div>
        )}
      </section>
      <section className="img-ft-img-sec">
        {bigHomeSliderBanners.length > 0 ? (
          <div className="container">
            <Row>
              <Col md={14} xs={24}>
                <Carousel effect="fade" autoplay={true}>
                  {bigHomeSliderBanners.map((banner, i) => (
                    <div key={i}>
                      <img
                        className="step-img-value"
                        src={
                          process.env.REACT_APP_BASE_URL +
                          banner.path.substring(1)
                        }
                        alt=""
                      />
                    </div>
                  ))}
                </Carousel>
              </Col>
              <Col md={10} xs={24}>
                {smallHomeSliderBanners.length > 0 && (
                  <HomeSlider images={smallHomeSliderBanners} />
                )}
              </Col>
              {/* <img
                  className="step-img-tai"
                  src={require("../../assets/images/tai-1.jpg").default}
                  alt=""
                /> */}

              {/* <Col md={5} xs={24}>
                <img
                  className="step-img-tai"
                  src={require("../../assets/images/tai-2.jpg").default}
                  alt=""
                />

                <div className="rate-btn-from">
                  <div>
                    <ANTD.Button className="book-sg-country">
                      Book Now
                    </ANTD.Button>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        ) : null}
      </section>

      <section>
        <HomeCarousel dealsList={dealsList} />
      </section>
      <section>
        <BlogGrid blog={blog} />
      </section>
      <section className="top-route-bb">
        <TopCities length={3} />
      </section>
      <section className="top-route-bb">
        <TopFlights dealsList={dealsList} />
      </section>

      <section className="home-best-trip text-justify">
        <div className="container">
          <Row>
            <Col md={24} xs={24}>
              <h2 className="top-fl-route route-trip-3">Why ShopYourTrip?</h2>
              <p>
                Our competitive rates and exclusive offers are what gives us a
                top notch over our competitors. We promise 'Unbeatable' services
                both in pricing and quality. Here is the one stop destination
                for your Dream Destination. Shop Your Trip provide you the best
                travel packages at the lowest possible pricing that gives the
                best value for your each penny. We are your Travel Companion and
                works for you so that can get the best travel experience and
                live some memorable moments.
              </p>
              <p>
                We give you the pros and cons for all the different travel
                products and allow you to decide what works best for you and
                your family. We combine first-hand knowledge with exceptional
                special offers, and we take care of every detail to create a
                holiday as unique as you are. You will no more need to worry
                about coordinating flight bookings, hotel reservations, visa
                stamps or tours as all your needs are taken care of under one
                roof.
              </p>
              <p>
                ShopYourTrip can satisfy all your travel needs. Here, You can
                book flight tickets, hotels, bus tickets, activities and holiday
                packages at a cost-effective price. So, why go anywhere else?
                Visit us for a memorable travel experience in your budget.
              </p>
            </Col>
          </Row>
        </div>
      </section>

      <section className="top-route-bb">
        <Reviews serviceType={1} />
      </section>

      <section>{/* <FlightDeal /> */}</section>
      <Subscribe />

      {/* <section className="banner-links">
        <div className="banner-links-wrapper">
          <ul>
            <li>
              <p className="banner-icon">
                <i className="fa fa-info" aria-hidden="true"></i>
              </p>
              <div className="banner-box">
                <label>Before you contact us</label>
                <a href="/checkInfo">Check information&gt;&gt;</a>
              </div>
            </li>
            <li>
              <p className="icon-info">
                BLOG
                 <i className="fa fa-info" aria-hidden="true"></i> 
              </p>
              <div className="banner-box">
                <label>BLOG</label>
                <a href="/blog">Articles and inspirations&gt;&gt;</a>
              </div>
            </li>
          </ul>
        </div>
      </section> */}
    </div>
    </React.Fragment>
  );
};

export default Home;
